import React, { useEffect, useState } from "react";
import "../../EmployeeScreens/EMPleave/EMPLeaves.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import { Modal } from "react-bootstrap";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import { Apiurl, GetToken, RemoveUserSession } from "../../../Utils/utils";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/loader/loader";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
const EMPLeaves = () => {
  const LoginToken = GetToken();
  const [errorLeaveType, setErrorLeaveType] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [leaveRequest, setLeaveRequest] = useState();
  const [leaveRequestDuplicate, setLeaveRequestDuplicate] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [deleteLeave, setDeleteLeave] = useState({
    IdDelete: "",
  });
  const handleAttendanceSearch = () => {
    setLoading(true);
    const LoginToken = GetToken();
    const data = {};
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/TotalLeaves`,
    };
    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.data?.data);
        setLeaveRequest(data);
        setLeaveRequestDuplicate(data);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        if (
          error?.message === "Network Error" &&
          error?.code === "ERR_NETWORK"
        ) {
          toast.error("Your Session has been expired");
          RemoveUserSession();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    handleAttendanceSearch();
  }, []);

  const handleDataSearch = (e) => {
    const searchTerm = e?.target?.value?.toLowerCase();
    if (searchTerm) {
      const filteredData = leaveRequestDuplicate.filter(item =>
        item?.Status?.toLowerCase()?.includes(searchTerm)
      );
  setLeaveRequest(filteredData);
    }
    else {
        setLeaveRequest(leaveRequestDuplicate);
    }
  };

  const [deleteClickable, setDeleteClickable] = useState("");
  const handleLeaveDelete = (item) => {
    setDeleteClickable(item);
    setShowModalNew(true);
  };
  const handleCloseModal = () => {
    setShowModalNew(false);
    setDeleteLeave({
      IdDelete: "",
    });
    setErrorLeaveType(false);
  };
  const modalValidate = () => {
    if (deleteLeave?.IdDelete?.trim() === "") {
      toast.error("Please enter Reason");
      return false;
    }
    return true;
  };
  const handleDeleteLeave = (e) => {
    if (!modalValidate()) return;
    setLoading(true);
    const data = {};
    data.Id = deleteClickable?.Id;
    data.CancelReason = deleteLeave?.IdDelete;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/CancelLeave`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          handleCloseModal();
          handleAttendanceSearch();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <>
          <div
            className={
              showSidebar
                ? "EMP-main-container-of-all-employees-page"
                : "EMP-main-container-of-all-employees-page-new"
            }
          >
            <div className="EMp-inner-container-of-all-employees-page ">
              <div className="EMP-header-of-employees-page row">
                <div className="EMP-all-employee-text col">
                  <h4>Leave Requests</h4>
                </div>
                <div className="EMP-all-employees-directory col">
                  <div className="EMP-home-icon-header">
                    {" "}
                    <BiHomeAlt className="EMP-home-icon-header-inn" />{" "}
                  </div>
                  <div className="EMP-arrow-icon-header">
                    <MdKeyboardArrowRight />
                  </div>
                  <div className="EMp-leave-employee-text-header">
                    <p>Leave Management</p>
                  </div>
                  <div className="arrow-icon-header">
                    <MdKeyboardArrowRight />
                  </div>
                  <div className="EMP-all-employee-text-header">
                    <p>Leave Requests</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="EMP-body-part-outer-container">
              <div className="EMP-inner-body-container-of-employee-table row-12">
                <div className=" first-side-in-body-header col-6">
                  <div className="EMP-employee-text-in-body">
                    <h2>Leave Requests</h2>
                  </div>
                  <div className="EMP-input-for-search-in-employee-body">
                    <MdSearch className="EMP-search-icon-in-input" />
                    <input
                      type="text"
                      placeholder="Search by status"
                      onChange={(e) => handleDataSearch(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="main-class-for-table-in-leavemanagement">
                <table className="table-of-employee-leaves table-of-employee-approval">
                  <thead className="thead-of-emp-table-leaves">
                    <tr className="">
                      <th className="approve_Sr">Sr. No.</th>
                      {/* <th className="">Name</th> */}
                      <th className="approve_Date"> Date</th>
                      <th className="approve_Type">Leave Type</th>
                      <th className="approve_From">Leave From</th>
                      <th className="approve_To">Leave To</th>
                      <th className="approve_Days">No Of Days</th>
                      <th className="approve_Reason">Employee Reason</th>
                      <th className="approve_Status">Status</th>
                      <th className="approve_Reason">Approval Reason</th>
                      <th className="approve_Date">Action</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-ofemp-table-leaves">
                    {leaveRequest?.length ? (
                      <>
                        {leaveRequest?.map((item, index) => (
                          <tr className="" key={index}>
                            <>
                              <td>{index + 1}</td>
                              {/* <td>{item?.User?.UserName}</td> */}
                              <td>
                                {/* {item?.AppliedDate} */}
                                {moment(item?.AppliedDate).format(
                                  "DD MMM YYYY"
                                )}
                              </td>
                              <td>
                                {item?.LeaveType === 0
                                  ? "Casual Leave"
                                  : item?.LeaveType === 1
                                  ? "Sick Leave"
                                  : ""}
                              </td>
                              {/* <td>{item?.StartDate.split("T")[0]}</td> */}
                              <td>
                                {moment(item?.StartDate).format("DD MMM YYYY")}
                              </td>
                              <td>
                                {moment(item?.EndDate).format("DD MMM YYYY")}
                              </td>
                              <td>{item?.NumberofDays}</td>
                              <td>{item?.Reason}</td>
                              <td>
                                <button
                                  className={
                                    item?.Status === "Cancelled"
                                      ? "EMP-Leaves-table-Pending-button"
                                      : item?.Status === "RejectedByTeamLead" ||
                                        item?.Status === "RejectedByHR" ||
                                        item?.Status === "RejectedByAdmin"
                                      ? "EMP-Leaves-table-Rejected-button"
                                      : item?.Status === "ApprovedByHR" ||
                                        item?.Status === "ApprovedByTeamLead"
                                      ? "EMP-YetToBeApprovedByTeamLead-button"
                                      : item?.Status ===
                                        "YetToBeApprovedByTeamLead"
                                      ? "EMP-YetToBeApprovedByTeamLead-button"
                                      : "EMP-Leaves-table-Approved-button"
                                  }
                                >
                                  {item?.Status === "YetToBeApprovedByTeamLead"
                                    ? "Pending at Team Lead"
                                    : item?.Status === "RejectedByTeamLead"
                                    ? "Rejected by Team Lead"
                                    : item?.Status === "RejectedByHR"
                                    ? "Rejected by HR"
                                    : item?.Status === "ApprovedByTeamLead"
                                    ? "Pending at HR"
                                    : item?.Status === "ApprovedByHR"
                                    ? "Pending at Admin"
                                    : item?.Status === "ApprovedByAdmin"
                                    ? "Approved by Admin"
                                    : item?.Status === "RejectedByAdmin"
                                    ? "Rejected by Admin"
                                    : item?.Status}
                                </button>
                              </td>
                              <td>
                                {item?.RejectionReason
                                  ? item?.RejectionReason
                                  : "-"}
                              </td>
                              <td className="">
                                {item?.Status ===
                                "YetToBeApprovedByTeamLead" ? (
                                  <>
                                    <MdOutlineModeEdit
                                      className="handel-Edit-button"
                                      onClick={() =>
                                        navigate("/Emp/ApplyLeaves", {
                                          state: { leaveRequestData: item },
                                        })
                                      }
                                    />
                                    <MdDelete
                                      className="handel-delete-button"
                                      onClick={() => handleLeaveDelete(item)}
                                    />
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr className="no-record-found-tr">
                          {" "}
                          <td colSpan="10">No record found</td>{" "}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <div></div>
              </div>
            </div>
          </div>
        </>
      </div>
      {showModalNew && (
        <div
          className="modal show edit-leaveModal"
          style={{ display: "block" }}
        >
          <Modal.Dialog>
            <Modal.Header
              className="delete-modal-header"
              closeButton
              onClick={handleCloseModal}
            >
              <Modal.Title>Cancel Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="textarea-validate">
                <label htmlFor="">Reason</label>
                <textarea
                  type="text"
                  className="textarea-for-delete-leave-api"
                  value={deleteLeave?.IdDelete}
                  onChange={(e) =>
                    setDeleteLeave({
                      ...deleteLeave,
                      IdDelete: e?.target?.value,
                    })
                  }
                />
                {errorLeaveType === "userLeaveType" &&
                !deleteLeave?.IdDelete ? (
                  <span className="reason-delete-leave">
                    Reason is required.
                  </span>
                ) : null}
              </div>
            </Modal.Body>
            <Modal.Footer className="Delete-fotter">
              <button
                className="Delete-modal-close-button"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                className="Delete-modal-delete-button"
                onClick={(e) => handleDeleteLeave(e)}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </>
  );
};
export default EMPLeaves;
