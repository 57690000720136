import React, { useEffect, useState } from "react";
import "../../Dashboard/Sidebar/Sidebar.css";
import { MdLaptopMac } from "react-icons/md";
import SidebarImg from "../../../Assets/Images/profileimg.png";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { HiOutlineLogout } from "react-icons/hi";
import { IoMdPaper } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { PiProjectorScreenChartBold } from "react-icons/pi";
import { LuCoffee } from "react-icons/lu";
import {
  Apiurl,
  GetLoginRole,
  GetToken,
  RemoveUserSession,
} from "../../../Utils/utils";
import { PiNotePencilBold } from "react-icons/pi";
import { PiNotebookBold } from "react-icons/pi";
import { CgCoffee } from "react-icons/cg";
import { AiOutlineProject } from "react-icons/ai";
import axios from "axios";
// import { CgProfile } from "react-icons/cg";

const Sidebar = ({ showSidebar }) => {
  const [profileData, setProfileData] = useState([]);
  const getUserRole = GetLoginRole();
  const navigate = useNavigate();
  const url = window?.location?.pathname;
  const LoginToken = GetToken();

  // Admin sidebar
  const adminDashboardData = [
    {
      name: "Dashboard",
      icon: <MdLaptopMac className="sidebar-icon" />,
      route: "/Adm/Dashboard",
      subpages: [
        {
          name: "Add Announcement",
          route: "/Adm/Dashboard/AddAnnouncement",
        },
      ],
    },
    {
      name: "Employees",
      icon: <PiProjectorScreenChartBold className="sidebar-icon" />,
      route: "/Adm/Employees/AllEmployees",
      subpages: [
        {
          name: "All Employees",
          route: "/Adm/Employees/AllEmployees",
        },
        {
          name: "Add Employees",
          route: "/Adm/Employees/AddEmployee",
        },
        {
          name: "Employees Profile",
          route: "/Adm/Employees/EmployeeProfile",
        },
      ],
    },
    {
      name: "Attendance",
      icon: <CgProfile className="sidebar-icon" />,
      route: "/Adm/Attendance/TodaysAttendance",
      subpages: [
        {
          name: "Today’s Attendance",
          route: "/Adm/Attendance/TodaysAttendance",
        },
        {
          name: "Attendance Sheet",
          route: "/Adm/Attendance/AttendanceSheet",
        },
        {
          name: "Employee Attendance",
          route: "/Adm/Attendance/EmployeeAttendance",
        },
      ],
    },
    {
      name: "Leave Management",
      icon: <PiNotebookBold className="sidebar-icon" />,
      route: "/Adm/LeaveManagement/AllLeaveRequests",
      subpages: [
        {
          name: "All Leave Requests",
          route: "/Adm/LeaveManagement/AllLeaveRequests",
        },
        {
          name: "Leave Balance",
          route: "/Adm/LeaveManagement/LeaveBalance",
        },
      ],
    },
    {
      name: "Holidays",
      icon: <LuCoffee className="sidebar-icon" />,
      route: "/Adm/Holidays/AllHolidays",
      subpages: [
        {
          name: "All Holidays",
          route: "/Adm/Holidays/AllHolidays",
        },
        {
          name: "Add Holidays",
          route: "/Adm/Holidays/AddHolidays",
        },
      ],
    },
  ];

  // Employees Sidebar
  const employeeDashboardData = [
    {
      name: "Dashboard",
      icon: <MdLaptopMac className="sidebar-icon" />,
      route: "/Emp/Dashboard",
    },
    {
      name: "Attendance",
      icon: <PiNotePencilBold className="sidebar-icon" />,
      route: "/Emp/attendance",
    },
    {
      name: "Leave Management",
      icon: <AiOutlineProject className="sidebar-icon" />,
      route: "/Emp/ApplyLeaves",
      subpages: [
        {
          name: "Apply Leaves",
          route: "/Emp/ApplyLeaves",
        },
        {
          name: "Leave Request",
          route: "/Emp/EMPLeaves",
        },
      ],
    },
    {
      name: "Holidays",
      icon: <LuCoffee className="sidebar-icon" />,
      route: "/Emp/Holidays",
    },
    {
      name: "Profile",
      icon: <CgProfile className="sidebar-icon" />,
      route: "/Emp/Profile",
    },
  ];

  // Team Lead Sidebar
  const teamLeadDashboardData = [
    {
      name: "Dashboard",
      icon: <MdLaptopMac className="sidebar-icon" />,
      route: "/Emp/Dashboard",
    },
    {
      name: "Attendance",
      icon: <PiNotePencilBold className="sidebar-icon" />,
      route: "/Emp/attendance",
    },
    {
      name: "Leave Management",
      icon: <AiOutlineProject className="sidebar-icon" />,
      route: "/Emp/ApplyLeaves",
      subpages: [
        {
          name: "Apply Leaves",
          route: "/Emp/ApplyLeaves",
        },
        {
          name: "Leave Request",
          route: "/Emp/EMPLeaves",
        },
        {
          name: "Leave Approval",
          route: "/Emp/EMPLeavesApproval",
        },
      ],
    },
    {
      name: "Holidays",
      icon: <LuCoffee className="sidebar-icon" />,
      route: "/Emp/Holidays",
    },
    {
      name: "Profile",
      icon: <CgProfile className="sidebar-icon" />,
      route: "/Emp/Profile",
    },
    
  ];
  // HR Sidebar
  const hrDashboardData = [
    {
      name: "Dashboard",
      icon: <MdLaptopMac className="sidebar-icon" />,
      route: "/Emp/Dashboard",
    },
    {
      name: "Attendance",
      icon: <PiNotePencilBold className="sidebar-icon" />,
      route: "/Emp/attendance",
    },
    {
      name: "Leave Management",
      icon: <AiOutlineProject className="sidebar-icon" />,
      route: "/Emp/ApplyLeaves",
      subpages: [
        {
          name: "Apply Leaves",
          route: "/Emp/ApplyLeaves",
        },
        {
          name: "Leave Request",
          route: "/Emp/EMPLeaves",
        },
        {
          name: "Leave Approval",
          route: "/Emp/EMPLeavesApproval",
        },
      ],
    },
    {
      name: "Holidays",
      icon: <LuCoffee className="sidebar-icon" />,
      route: "/Emp/Holidays",
    },
    {
      name: "Profile",
      icon: <CgProfile className="sidebar-icon" />,
      route: "/Emp/Profile",
    },
    
  ];

  const [showDashboards, setShowDashboards] = useState(
    adminDashboardData?.map(() => false)
  );
  const [showToggleSidebar, setShowToggleSidebar] = useState(
    adminDashboardData?.map(() => false)
  );

  const toggleDashboard = (index) => {
    const updatedDashboards = showDashboards?.map((value, i) =>
      i === index ? !value : value
    );
    setShowDashboards(updatedDashboards);
  };

  const toggelSidebar = (index) => {
    const updatedSidebar = showToggleSidebar.map((value, i) =>
      i === index ? !value : value
    );
    setShowToggleSidebar(updatedSidebar);
  };

  const handleLogout = () => {
    RemoveUserSession();
    navigate("/");
  };

  const renderSubpages = (subpages) => (
    <ul>
      {subpages?.map((subpage, subIndex) => (
        <li key={subIndex}>
          <Link to={subpage?.route}>{subpage?.name}</Link>
        </li>
      ))}
    </ul>
  );

  const renderDashboardData =
    getUserRole === "Admin" ? adminDashboardData : getUserRole=== "TeamLead" ? teamLeadDashboardData: getUserRole==="HR" ?hrDashboardData: employeeDashboardData;

  const GetProfile = () => {
    // setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Profile/GetProfile`,
    };
    axios(option)
      .then((e) => {
        // setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setProfileData(data);
      })
      .catch(() => {
        // setLoading(false);
      });
  };
  useEffect(() => {
    GetProfile();
  }, []);
  return (
    <>
      {showSidebar ? (
        <>
          <div className="sidebar-main-container">
            <div className="siderbar-overflow-data-handle">
              <div className="siderbar-image-container">
              <div className="image-of-sidebar-in-dashboard">
                  {profileData?.UrlLink ? (
profileData?.UrlLink && <img className="image-of-sidebar" src={profileData.UrlLink} alt="Profile Image" />
) : (
                    <CgProfile className="profile-icon-sidebar" />
                  )}
                </div>
                {/* <p className="sidebar-Sarah">Sarah Smith</p> */}
                <p className="sidebar-Sarah">{profileData?.FirstName}</p>

                <p className="sider-bar-admin"> -- </p>
              </div>
              <section className="sidebar-outter-container">
                <div className="Siderbar-inner-container">
                  {renderDashboardData?.map((dashboard, index) => (
                    <ul
                      key={index}
                      onClick={() => toggleDashboard(index)}
                      className="container-of-single-compomnent"
                    >
                      <li>
                        <Link
                          to={dashboard?.route}
                          className="links-main-pages"
                        >
                          <div
                            className={`siderbar-all-data ${
                              dashboard?.route === url ? "active" : ""
                            }`}
                          >
                            <div>
                              {dashboard.icon}
                              <span className="dashboard-name">
                                {dashboard?.name}
                              </span>
                            </div>
                            <div>
                              <span className="Dashboard-icons">
                                {dashboard?.subpages &&
                                dashboard?.subpages?.length > 0 ? (
                                  showDashboards[index] ? (
                                    <IoIosArrowDown />
                                  ) : (
                                    <IoIosArrowForward />
                                  )
                                ) : null}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="siderbar-alldropdown"
                        onClick={() => toggelSidebar(index)}
                      >
                        {showDashboards[index] &&
                          renderSubpages(dashboard.subpages)}
                      </li>
                    </ul>
                  ))}
                  <div
                    className="logout-container-in-sidebar"
                    onClick={handleLogout}
                  >
                    <HiOutlineLogout className="sidebar-icon" />
                    <NavLink className="dashboard-name">Logout</NavLink>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="sidebar-main-container-new">
            <div className="siderbar-overflow-data-handle">
              <section className="sidebar-outter-container-new">
                <div className="Siderbar-inner-container">
                  {renderDashboardData.map((dashboard, index) => (
                    <ul key={index} onClick={() => toggleDashboard(index)}>
                      <li>
                        <div
                          className={`sidebar-all-data-new ${
                            dashboard.route === url ? "active" : ""
                          }`}
                        >
                          <div>{dashboard.icon}</div>
                        </div>
                      </li>
                    </ul>
                  ))}
                  <div className="logout-toggle-function">
                    <HiOutlineLogout onClick={handleLogout} />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Sidebar;