import React, { useEffect, useState } from "react";
import "../../../Components/Dashboard/EmpDashboard/Dashboard.css";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { BiHomeAlt } from "react-icons/bi";
import { FaArrowUp } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaBriefcase } from "react-icons/fa";
import { HiUsers } from "react-icons/hi2";
import { FaGlobe } from "react-icons/fa";
import {
  Apiurl,
  GetToken,
  RemoveUserSession,
  Token,
  userId,
} from "../../../Utils/utils";
import axios from "axios";
import userimg from "../../../Assets/Images/user6.png";
import { toast } from "react-toastify";
import Loader from "../../loader/loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { LiaUserClockSolid } from "react-icons/lia";
import { TiCalendarOutline } from "react-icons/ti";
import { LuFileAxis3D } from "react-icons/lu";
import { MdAvTimer } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import { Modal } from "react-bootstrap";
import NewEmployeesModal from "../../../Components/Modals/NewEmployeesModal";

const EmpDashboard = () => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const [yesterdayAttendanceData, setYesterdayAttendanceData] = useState([]);
  const [monthlyLateLeaves, setMonthlyLateLeaves] = useState([]);
  const [Totalleaves, setTotalleaves] = useState([]);
  const [Thisyeardata, setThisyeardata] = useState("");
  const [loading, setLoading] = useState(false);
  const [lateleaves, setlateleaves] = useState("");

const [monthlyShortLeaves , setMonthlyShortLeaves] = useState([]);
  const [Announceentdata, setAnnounceentdata] = useState([]);
  const [LeaveRequestdata, setLeaveRequestdata] = useState([]);
  const [errorLeaveType, setErrorLeaveType] = useState(false);

  const [leaveRequest, setLeaveRequest] = useState();
  const [leaveRequestDuplicate, setLeaveRequestDuplicate] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [deleteLeave, setDeleteLeave] = useState({
    IdDelete: "",
  });

  const LoginToken = GetToken();
  const addAMPM = (timeString) => {
    const time = new Date(`1970-01-01T${timeString}`);
    if (isNaN(time.getTime())) {
      return "";
    }
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return time.toLocaleTimeString("en-US", options);
  };
  const timeString = yesterdayAttendanceData?.OutTime || "";
  const formattedTime = addAMPM(timeString);
  const timeINString = yesterdayAttendanceData?.InTime || "";
  const formattedINTime = addAMPM(timeINString);
  const userTime = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Attendence/GetAttendanceByUser`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
      })
      .catch(() => { });
  };
  useEffect(() => {
    userTime();
  }, []);
  // GetYesterdayAttendance
  const YesterdayAttendance = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Attendence/GetYesterdayAttendance`,
    };
    axios(option)
      .then((response) => {
        const data = JSON.parse(response?.data?.data);
        setYesterdayAttendanceData(data);
      })
      .catch(() => { });
  };
  useEffect(() => {
    YesterdayAttendance();
  }, []);

  // GetMonthlyShortLeave Api 
  const GetMonthlyShortLeave = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Attendence/GetMonthlyShortLeaves`,
    };
    axios(option)
      .then((response) => {
        const data = JSON.parse(response?.data?.data);
        setMonthlyShortLeaves(data);
      })
      .catch(() => { });
  };
  useEffect(() => {
    GetMonthlyShortLeave();
  }, []);

  const [deleteClickable, setDeleteClickable] = useState("");
  const handleLeaveDelete = (item) => {
    setDeleteClickable(item);
    setShowModalNew(true);
  };
  const handleCloseModal = () => {
    setShowModalNew(false);
    setDeleteLeave({
      IdDelete: "",
    });
    setErrorLeaveType(false);
  };
  const handleAttendanceSearch = () => {
    setLoading(true);
    const LoginToken = GetToken();
    const data = {};
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/TotalLeaves`,
      // data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.data?.data);
        setLeaveRequest(data);
        setLeaveRequestDuplicate(data);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        if (
          error?.message === "Network Error" &&
          error?.code === "ERR_NETWORK"
        ) {
          toast.error("Your Session has been expired");
          RemoveUserSession();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    handleAttendanceSearch();
  }, []);

  const modalValidate = () => {

    if (deleteLeave?.IdDelete?.trim() === "") {
      toast.error("Please enter Reason")
      return false;
    }
    return true;

    // if (!deleteLeave?.IdDelete) {
    //   setErrorLeaveType("userLeaveType");
    //   return false;
    // }
  };
  const handleDeleteLeave = (e) => {
    if (!modalValidate()) return;

    setLoading(true);
    const data = {};
    data.Id = deleteClickable?.Id;
    data.CancelReason = deleteLeave?.IdDelete;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/CancelLeave`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          handleCloseModal();
          handleAttendanceSearch();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const EMPLeaveRequests = () => {
    const LoginToken = GetToken();
    const data = {};
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/TotalLeaves`,
      // data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.data?.data);
        setLeaveRequestdata(data);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    EMPLeaveRequests();
  }, []);

  const CurrentYearLeaves = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Attendence/CurrentYearLeaves`,
    };
    axios(option)
      .then((response) => {
        const data = response?.data?.data;
        setThisyeardata(data);
      })
      .catch((error) => {
        if (
          error?.message === "Network Error" &&
          error?.code === "ERR_NETWORK"
        ) {
          toast.error("Your Session has been expired");
          RemoveUserSession();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    CurrentYearLeaves();
  }, []);
  const Lateleaves = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Attendence/GetMonthlyLateLeaves`,
    };
    axios(option)
      .then((response) => {
        const data = response?.data?.data;
        setlateleaves(data);
      })
      .catch(() => { });
  };
  useEffect(() => {
    Lateleaves();
  }, []);
  const Getannouncement = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Announcement/GetAnnouncements`,
    };
    axios(option)
      .then((response) => {
        const data = JSON.parse(response?.data?.data);
        setAnnounceentdata(data);
      })
      .catch(() => { });
  };
  useEffect(() => {
    Getannouncement();
  }, []);
  return (
    <>
      {loading ? <Loader /> : null}
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <>
          <section
            className={
              showSidebar
                ? "dashboard-main-container"
                : "dashboard-main-container-new"
            }
          >
            <div className="all-icons">
              <div>
                <p className="DASHBOARD-MAIN-HEADING">Dashboard </p>
              </div>
              <div className="dashboiard-inner-header">
                <BiHomeAlt className="home-icon-header-inn" />
                <MdKeyboardArrowRight />
                <p>Home </p>
                <MdKeyboardArrowRight />
                <p>Dashboard </p>
              </div>
            </div>
            <div>
              <div className="dashboard-allcards-flex-container">
                {/*FIRST-CARD/ */}
                <div className="dashboard-card-first-one">
                  <div className="dashboard-img-icon">
                    <LiaUserClockSolid />
                  </div>
                  <p className="dashboard-1st-card-heading">
                    Today's Log in time
                  </p>
                  <p className="dashboard-number-heading">
                    {formattedINTime ? formattedINTime.toLocaleString() : "-"}
                  </p>
                  {/* <div className="first-card-main-content">
                      <div className="progress-bar-outter">
                        <div className="inner-progressbar"></div>
                      </div>
                    </div>
                    <div className="dashboard-card-bottem-content-1st">
                      <FaArrowUp />
                      <p className="yesterday-show-time">
                       <span> {formattedTime ? formattedTime.toLocaleString() : "-"}</span>
                       <span> Yesterday’s Log out time,</span>
                      </p>
                    </div> */}
                </div>
                <div className="dashboard-card">
                  <div className="dashboard-img-icon">
                    <MdAvTimer />
                  </div>
                  <p className="dashboard-1st-card-heading">
                    Yesterday’s Log out time
                  </p>
                  <p className="dashboard-number-heading">
                    {formattedTime ? formattedTime.toLocaleString() : "-"}
                  </p>
                  {/* <div className="first-card-main-content">
                      <div className="progress-bar-outter">
                        <div className="inner-progressbar"></div>
                      </div>
                    </div>
                    <div className="dashboard-card-bottem-content-1st">
                      <FaArrowUp />
                      <p className="yesterday-show-time">
                       <span> {formattedTime ? formattedTime.toLocaleString() : "-"}</span>
                       <span> Yesterday’s Log out time,</span>
                      </p>
                    </div> */}
                </div>
                {/*SECOND--CARD/ */}
                <div className="dashboard-SECOND-card">
                  <div className="dashboard-img-icon">
                    <LuFileAxis3D />
                  </div>
                  <p className="dashboard-1st-card-heading">
                    Total no. of leaves from 1st Jan till current date
                  </p>
                  <p className="dashboard-number-heading">
                    {Thisyeardata || "-"}
                  </p>
                  {/* <div className="second-card-main-content">
                      <div className="progress-bar-outter">
                        <div className="inner-progressbar-second"></div>
                      </div>
                    </div>
                    <div className="dashboard-card-bottem-content">
                      <FaArrowUp />
                      <p className="">
                        {" "}
                        {Thisyeardata} Total no.of leaves from 1st Jan till
                        current date,
                      </p>
                    </div> */}
                </div>
                {/*SECOND- LAST-CARD/ */}
                <div className="dashboard-card-second-last">
                  <div className="dashboard-img-icon">
                    {" "}
                    <TiCalendarOutline />{" "}
                  </div>
                  <p className="dashboard-1st-card-heading">
                    Short leaves for the current month{" "}
                  </p>
                  <p className="dashboard-number-heading">
                    {monthlyShortLeaves ? monthlyShortLeaves?.Result : "-"}
                  </p>
                
                  <div className="dashboard-card-img-second-last"></div>
                </div>
              </div>
            </div>
            <div className="emp-dashboard-cards">
              {/* Leave Requests table  */}
              <div className="emp-dashboard-Leave-Requests-main-container">
                <div className="emp-dashboard-Leave-outter">
                  <div className="emp-dashboard-card-header">
                    <div>
                      <p className="Leave-heading">Leave Requests</p>
                    </div>
                  </div>
                  <div className="emp-dashboard-table-container">
                    <table className="table-of-employee-leaves">
                      <thead className="thead-of-emp-table-leaves">
                        <tr className="">
                          <th className="">Sr. No.</th>
                          {/* <th className="">Name</th> */}
                          <th className=""> Date</th>
                          <th className="">Leave Type</th>
                          <th className="">Leave From</th>
                          <th className="">Leave To</th>
                          <th className="">No Of Days</th>
                          <th className="">Status</th>
                          <th className="">Action</th>
                        </tr>
                      </thead>
                      <tbody className="tbody-ofemp-table-leaves">
                        {leaveRequest?.length ? (
                          <>
                             {leaveRequest?.map((item, index) => (
                          <tr key={index}>
                            <>
                              <td>{index + 1}</td>
                              {/* <td>{item?.User?.UserName}</td> */}
                              <td>
                                {/* {item?.AppliedDate} */}
                                {moment(item?.AppliedDate).format(
                                  "DD MMM YYYY"
                                )}
                              </td>
                              <td>
                                {item?.LeaveType === 0
                                  ? "Casual Leave"
                                  : item?.LeaveType === 1
                                    ? "Sick Leave"
                                    : ""}
                              </td>
                              {/* <td>{item?.StartDate.split("T")[0]}</td> */}
                              <td>
                                {moment(item?.StartDate).format("DD MMM YYYY")}
                              </td>
                              <td>
                                {moment(item?.EndDate).format("DD MMM YYYY")}
                              </td>
                              <td>{item?.NumberofDays}</td>
                              <td>
                                <button
                                  className={
                                    item?.Status === "Cancelled"
                                      ? "EMP-Leaves-table-Rejected-button"
                                      : item?.Status === "RejectedByTeamLead" || item?.Status === "RejectedByHR" || item?.Status==="RejectedByAdmin"
                                        ? "EMP-Leaves-table-Rejected-button"
                                        : item?.Status === "ApprovedByHR" || item?.Status === "ApprovedByTeamLead"
                                          ? "EMP-YetToBeApprovedByTeamLead-button"
                                          : item?.Status ==="YetToBeApprovedByTeamLead"
                                            ? "EMP-YetToBeApprovedByTeamLead-button"
                                            : "EMP-Leaves-table-Approved-button"
                                  }
                                >
                                  {item?.Status === "YetToBeApprovedByTeamLead" ? "Pending at Team Lead"
                                                                        : item?.Status === "RejectedByTeamLead" ? "Rejected by Team Lead"
                                                                        : item?.Status === "RejectedByHR" ? "Rejected by HR"
                                                                            : item?.Status === "ApprovedByTeamLead" ? "Pending at HR"
                                                                            :  item?.Status === "ApprovedByHR" ? "Pending at Admin"
                                                                                : item?.Status ==="ApprovedByAdmin" ? "Approved by Admin":item?.Status ==="RejectedByAdmin" ?"Rejected by Admin" :item?.Status }
                                </button>
                              </td>
                              {item?.Status === "Cancelled" ? null : (
                                <td className="">
                                    <button onClick={() =>
                                      navigate("/Emp/EMPLeaves")
                                    }
                                      className="EMP-Approve-table-Approved-button-dashboard">
                                      View
                                    </button>
                                 
                                </td>
                              )}
                            </>
                          </tr>
                        ))}
                          </>
                        ) : (
                          <>
                          <tr className="no-record-found-tr-dashboard" > <td colSpan="10" >No record found</td> </tr>
                        </>
                        ) }
                     
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Notice Board */}
              <div className="emp-dashboard-notice-board-main-container">
                <div>
                  <p className="Notice-heading">Notice Board</p>
                </div>
                <div className="emp-dashboard-scroll">
                  <div>
                    <div>
                      {Announceentdata?.map((item) => (
                        <div key={item?.id}>
                          {item?.AnnouncementTitle ||
                            item?.Description ||
                            item?.FlashTillDate ? (
                            <div className="emp-dashboard-notice-board-inner">
                              <img
                                className="emp-dashboard-user-img"
                                src={userimg}
                                alt=""
                              />
                              <div>
                                <p className="emp-dashboard-notice-board-heading">
                                  {item?.AnnouncementTitle}
                                </p>
                                <p className="emp-dashboard-lorem">
                                  {item?.Description}
                                </p>
                                {item?.FlashTillDate ? (
                                  <p className="emp-dashboard-time">
                                    {moment(item?.FlashTillDate).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-in-employee-table"
      >
        <NewEmployeesModal className="modal-main-inner-container" />
      </Modal>
      {showModalNew && (
        <div className="modal show edit-leaveModal" style={{ display: "block" }}>
          <Modal.Dialog>
            <Modal.Header
              className="delete-modal-header"
              closeButton
              onClick={handleCloseModal}
            >
              <Modal.Title>Cancel Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="textarea-validate">
                <label htmlFor="">Reason</label>
                <textarea
                  type="text"
                  className="textarea-for-delete-leave-api"
                  value={deleteLeave?.IdDelete}
                  onChange={(e) =>
                    setDeleteLeave({
                      ...deleteLeave,
                      IdDelete: e?.target?.value,
                    })
                  }
                />
                {errorLeaveType === "userLeaveType" &&
                  !deleteLeave?.IdDelete ? (
                  <span className="reason-delete-leave">
                    Reason is required.
                  </span>
                ) : null}
              </div>
            </Modal.Body>
            <Modal.Footer className="Delete-fotter">
              <button
                className="Delete-modal-close-button"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                className="Delete-modal-delete-button"
                onClick={(e) => handleDeleteLeave(e)}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </>
  );
};
export default EmpDashboard;