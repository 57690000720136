import React, { useState } from "react";
import "../../../Pages/AdminScreens/LeaveManagement/AllLeaveRequests.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import tableimg from "../../../Assets/Images/tableimage.jpg";
import { MdModeEditOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";

const AllLeaveRequests = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />

        {showSidebar ? (
          <>
            <div className="main-container-of-all-employees-Attendance-management">
              <div className="inner-container-of-all-employees-page-management ">
                <div className="header-of-employees-page-management row">
                  <div className="all-employee-Attendance-management col">
                    <h4>Leave Requests</h4>
                  </div>
                  <div className="all-employees-Attendance-management col">
                    <div className="home-icon-Attendance-management">
                      {" "}
                      <BiHomeAlt className="home-icon-header-Attendance-management" />{" "}
                    </div>
                    <div className="arrow-icon-Attendance-management">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-Attendance-management">
                      <p>Leave </p>
                    </div>
                    <div className="arrow-icon-Attendance-management">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-Attendance-management">
                      <p>Leave Requests</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body-part-outer-Attendance-management">
                <div className="inner-body-container-of-employee-Attendance-management row-12">
                  <div className=" first-side-in-body-Attendance-management col-6">
                    <div className="employee-text-in-Attendance-management">
                      <h2>Leave Requests</h2>
                    </div>
                    <div className="input-for-search-in-employee-Attendance-management">
                      <MdSearch className="search-icon-in-Attendance-management" />
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-Attendance-management">
                  <table className="table-main-Attendance-management">
                    <thead className="thead-main-Attendance-management">
                      <tr className="row-12">
                        <th className="image-class-management col ">
                          <input type="checkbox" />
                        </th>
                        <th className="image-class-management col-1 ">Image</th>
                        <th className="image-class-management col-1 ">Name</th>
                        <th className="image-class-management col-1 ">
                          Leave Type
                        </th>
                        <th className="image-class-management col-1 ">
                          Leave From
                        </th>
                        <th className="image-class-management col-1 ">
                          Leave To
                        </th>
                        <th className="image-class-management col-1 ">
                          No Of Days
                        </th>
                        <th className="image-class-management col-1 ">
                          Status
                        </th>
                        <th className="image-class-management col-1 ">
                          Reason
                        </th>
                        <th className="image-class-management col-2 ">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-Attendance-management">
                      <tr>
                        <td>
                          {" "}
                          <input type="checkbox" />{" "}
                        </td>
                        <td>
                          {" "}
                          <img
                            src={tableimg}
                            alt=""
                            className="image-in-table-Attendance-management"
                          />
                        </td>
                        <td> Sarah Smith</td>
                        <td>Medical Leave</td>
                        <td>04/10/1985</td>
                        <td> 02/25/2018</td>
                        <td> 5</td>
                        <td>
                          {" "}
                          <span className="present-spna-in-todaysattendence-table-management">
                            {" "}
                            present
                          </span>
                        </td>
                        <td>God creature</td>
                        <td className="edit-delete-icons">
                          <MdModeEditOutline className="edit-icon-all-leaves-requested" />
                          <MdDelete className="delete-icon-all-leaves-requested" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <input type="checkbox" />{" "}
                        </td>
                        <td>
                          {" "}
                          <img
                            src={tableimg}
                            alt=""
                            className="image-in-table-Attendance-management"
                          />
                        </td>
                        <td> Sarah Smith</td>
                        <td>Medical Leave</td>
                        <td>04/10/1985</td>
                        <td> 02/25/2018</td>
                        <td> 5</td>
                        <td>
                          {" "}
                          <span className="absent-spna-in-todaysattendence-table-management">
                            {" "}
                            present
                          </span>
                        </td>
                        <td>God creature</td>
                        <td className="edit-delete-icons">
                          {" "}
                          <MdModeEditOutline className="edit-icon-all-leaves-requested" />
                          <MdDelete className="delete-icon-all-leaves-requested" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="main-container-of-all-employees-page-new-Attendance-management">
            <div className="inner-container-of-all-employees-page-management ">
                <div className="header-of-employees-page-management row">
                  <div className="all-employee-Attendance-management col">
                    <h4>Leave Requests</h4>
                  </div>
                  <div className="all-employees-Attendance-management col">
                    <div className="home-icon-Attendance-management">
                      {" "}
                      <BiHomeAlt className="home-icon-header-Attendance-management" />{" "}
                    </div>
                    <div className="arrow-icon-Attendance-management">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-Attendance-management">
                      <p>Leave </p>
                    </div>
                    <div className="arrow-icon-Attendance-management">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-Attendance-management">
                      <p>Leave Requests</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body-part-outer-Attendance-management">
                <div className="inner-body-container-of-employee-Attendance-management row-12">
                  <div className=" first-side-in-body-Attendance-management col-6">
                    <div className="employee-text-in-Attendance-management">
                      <h2>Leave Requests</h2>
                    </div>
                    <div className="input-for-search-in-employee-Attendance-management">
                      <MdSearch className="search-icon-in-Attendance-management" />
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-Attendance-management">
                  <table className="table-main-Attendance-management">
                    <thead className="thead-main-Attendance-management">
                      <tr className="row-12">
                        <th className="image-class-management col ">
                          <input type="checkbox" />
                        </th>
                        <th className="image-class-management col-1 ">Image</th>
                        <th className="image-class-management col-1 ">Name</th>
                        <th className="image-class-management col-1 ">
                          Leave Type
                        </th>
                        <th className="image-class-management col-1 ">
                          Leave From
                        </th>
                        <th className="image-class-management col-1 ">
                          Leave To
                        </th>
                        <th className="image-class-management col-1 ">
                          No Of Days
                        </th>
                        <th className="image-class-management col-1 ">
                          Status
                        </th>
                        <th className="image-class-management col-1 ">
                          Reason
                        </th>
                        <th className="image-class-management col-2 ">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-Attendance-management">
                      <tr>
                        <td>
                          {" "}
                          <input type="checkbox" />{" "}
                        </td>
                        <td>
                          {" "}
                          <img
                            src={tableimg}
                            alt=""
                            className="image-in-table-Attendance-management"
                          />
                        </td>
                        <td> Sarah Smith</td>
                        <td>Medical Leave</td>
                        <td>04/10/1985</td>
                        <td> 02/25/2018</td>
                        <td> 5</td>
                        <td>
                          {" "}
                          <span className="present-spna-in-todaysattendence-table-management">
                            {" "}
                            present
                          </span>
                        </td>
                        <td>God creature</td>
                        <td className="edit-delete-icons">
                          <MdModeEditOutline className="edit-icon-all-leaves-requested" />
                          <MdDelete className="delete-icon-all-leaves-requested" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <input type="checkbox" />{" "}
                        </td>
                        <td>
                          {" "}
                          <img
                            src={tableimg}
                            alt=""
                            className="image-in-table-Attendance-management"
                          />
                        </td>
                        <td> Sarah Smith</td>
                        <td>Medical Leave</td>
                        <td>04/10/1985</td>
                        <td> 02/25/2018</td>
                        <td> 5</td>
                        <td>
                          {" "}
                          <span className="absent-spna-in-todaysattendence-table-management">
                            {" "}
                            present
                          </span>
                        </td>
                        <td>God creature</td>
                        <td className="edit-delete-icons">
                          {" "}
                          <MdModeEditOutline className="edit-icon-all-leaves-requested" />
                          <MdDelete className="delete-icon-all-leaves-requested" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AllLeaveRequests;
