import React, { useState } from "react";
import "../ConfirmPassword/ConfirmPassword.css";
import "../ForgotPassword/ForgotPassword.css";
import kuberimgpassword from "../../../Assets/Images/forgotpass.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import { Apiurl, GetForgotemail } from "../../../Utils/utils";
import axios from "axios";

const ConfirmPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newPasswordEye, setNewPasswordEye] = useState(false);
  const [changePassword, setChangePassword] = useState({
    newPassword: "",
    conPassword: "",
  });
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [errorConfirmPassWord, setErrorConfirmPassWord] = useState(false);
  const [errorNewPassWord, setErrorNewPassWord] = useState(false);
  const resetEmail = GetForgotemail();

  const handleClickNewPassword = () => {
    setNewPasswordEye(!newPasswordEye);
  };

  const handleClickConfirmPassword = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const validate = () => {
    if (!changePassword?.newPassword) {
      setErrorConfirmPassWord("confirmpassdata")
      // setError(true);
      return false;
    }
    if (changePassword?.newPassword?.length < 8) {
      toast.error("Please Enter Password More Than 8 Characters");
      return false;
    }

    if (
      !changePassword?.newPassword?.match(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      toast.error(
        "The password must include lowercase letters, uppercase letters, numbers and special characters @$!%*#.?&^"
      );
      return false;
    }
    if (!changePassword?.conPassword) {
      setErrorNewPassWord("newPassworddata")
      // setError(true);
      return false;
    }
    if (changePassword?.newPassword != changePassword?.conPassword) {
      toast.error("Password Don't Match");
      return false;
    }
    return true;
  };

  const handleConfirmPassSubmit = (e) => {
    if (!validate()) return;

    const data = {};
    data.Email = resetEmail;
    data.NewPassword = changePassword?.newPassword;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/Account/ResetPassword`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          navigate('/')
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      <div className="full-page-main-confirm">
        <div className="main-container-of-login-page-confirm row-12">
          <div className="login-page-image-confirm col-6">
            <img src={kuberimgpassword} alt="" />
          </div>
          <div className="login-page-form-forgot col-6">
            <div className="inner-container-of-login-form-confirm">
              <h2 className="resetpass-confirm">Reset Password</h2>
              <div className="need-a-accoount-confirm">
                <p>Let Us Help You</p>{" "}
              </div>
              <span className="enter-your-registered-email-confirm">
                {" "}
                Enter your new password.{" "}
              </span>
              <div>
                <div className="email-and-password-inputs-confirm">
                  <div>
                    <div className="username-content-confirm">
                      <div className="password-floating-confirm">
                        <FloatingLabelInput
                          label="Password*"
                          type={newPasswordEye ? "text" : "password"}
                          className="login-password-input"
                          value={changePassword?.newPassword}
                          onChange={(e) =>
                            setChangePassword({
                              ...changePassword,
                              newPassword: e.target.value,
                            })
                          }
                          onBlur={(e) => {
                            if (!e.target.value) {
                              setErrorConfirmPassWord("confirmpassdata")
                            } else {
                              setErrorConfirmPassWord("false")
                            }
                          }}

                        />
                        {errorConfirmPassWord === "confirmpassdata" && !changePassword?.newPassword ? (
                          <span className="contact_error-confirm">
                            Password is required.
                          </span>
                        ) : null}
                        {newPasswordEye ? (
                          <AiOutlineEye
                            className="eye-password-confirm"
                            onClick={() => handleClickNewPassword()}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="eye-password-confirm"
                            onClick={() => handleClickNewPassword()}
                          />
                        )}
                      </div>

                      <div className="password-floating-confirm">
                        <FloatingLabelInput
                          label="Confirm Password*"
                          type={confirmPasswordEye ? "text" : "password"}
                          className="login-password-input"
                          value={changePassword?.conPassword}
                          onChange={(e) =>
                            setChangePassword({
                              ...changePassword,
                              conPassword: e.target.value,
                            })
                          }
                          onBlur={(e) => {
                            if (!e.target.value) {
                              setErrorNewPassWord("newPassworddata")
                            } else {
                              setErrorNewPassWord("false")
                            }
                          }}
                        />
                        {errorNewPassWord === "newPassworddata" && !changePassword?.conPassword ? (
                          <span className="contact_error-confirm">
                            Confirm Password is required.
                          </span>
                        ) : null}
                        {confirmPasswordEye ? (
                          <AiOutlineEye
                            className="eye-password-confirm"
                            onClick={() => handleClickConfirmPassword()}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="eye-password-confirm"
                            onClick={() => handleClickConfirmPassword()}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="login-button-confirm">
                  <button
                    style={{
                      backgroundColor:
                        changePassword?.newPassword &&
                          changePassword?.conPassword
                          ? "#3f51b5"
                          : "rgba(0, 0, 0, .12)",
                      color:
                        changePassword?.newPassword &&
                          changePassword?.conPassword
                          ? "#fff"
                          : "rgba(0, 0, 0, .38)",
                    }}
                    onClick={handleConfirmPassSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;