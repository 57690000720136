import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BiHomeAlt } from "react-icons/bi";
import "../../EmployeeScreens/EMPLeavesRequest/EMPLeavesRequest.css";
import { MdSearch } from "react-icons/md";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import {
  Apiurl,
  GetLoginRole,
  GetToken,
  RemoveUserSession,
} from "../../../Utils/utils";
import axios from "axios";
import Loader from "../../../Components/loader/loader";
import moment from "moment";
import "./EMPLeavesApproval.css";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
const EMPLeavesApproval = () => {
  const [loading, setLoading] = useState(false);
  const getUserRole = GetLoginRole();
  const LoginToken = GetToken();
  const [showSidebar, setShowSidebar] = useState(true);
  const [leavesApprovalData, setLeavesApprovalData] = useState([]);
  const [leaveApprovalDuplicate, setLeaveApprovalDuplicate] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState("");
  const [showRejectModal, setShowRejectModal] = useState("");
  const [rejectLeave, setRejectLeave] = useState("");
  const [rejectReasonLength, setRejectReasonLength] = useState(100);

  const GetLeavesApproval = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/GetApprovalLeaves`,
    };
    axios(option)
      .then((response) => {
        setLoading(false);

        const data = JSON.parse(response?.data?.data);
        setLeavesApprovalData(data);
        setLeaveApprovalDuplicate(data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    GetLeavesApproval();
  }, []);

  const handleDataSearch = (e) => {
    const searchData = e?.target?.value;
    if (searchData) {
      const filterData = leavesApprovalData.filter((item) =>
        item?.User?.UserName?.toLowerCase()?.includes(searchData)
      );
      setLeavesApprovalData(filterData);
    } else {
      setLeavesApprovalData(leaveApprovalDuplicate);
    }
  };

  const handleRejectLeave = () => {
    if (!rejectLeave || rejectLeave.trim() === "") {
      toast.error("Please enter Reason");
      return;
    }
    setLoading(true);
    const data = {};
    data.Id = showRejectModal;
    data.RejectionReason = rejectLeave;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/RejectLeave`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          setShowRejectModal("");
          GetLeavesApproval();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleApproveLeave = () => {
    setLoading(true);
    const data = {};
    data.Id = showApproveModal;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/ApproveLeave`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          setShowApproveModal("");
          GetLeavesApproval();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <>
          <div
            className={
              showSidebar
                ? "EMP-main-container-of-all-employees-page"
                : "EMP-main-container-of-all-employees-page-new"
            }
          >
            <div className="EMp-inner-container-of-all-employees-page ">
              <div className="EMP-header-of-employees-page row">
                <div className="EMP-all-employee-text col">
                  <h4>Leave Approval</h4>
                </div>
                <div className="EMP-all-employees-directory col">
                  <div className="EMP-home-icon-header">
                    {" "}
                    <BiHomeAlt className="EMP-home-icon-header-inn" />{" "}
                  </div>
                  <div className="EMP-arrow-icon-header">
                    <MdKeyboardArrowRight />
                  </div>
                  <div className="EMp-leave-employee-text-header">
                    <p>Leave Management</p>
                  </div>
                  <div className="arrow-icon-header">
                    <MdKeyboardArrowRight />
                  </div>
                  <div className="EMP-all-employee-text-header">
                    <p>Leave Approval</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="EMP-body-part-outer-container">
              <div className="EMP-inner-body-container-of-employee-table row-12">
                <div className=" first-side-in-body-header col-6">
                  <div className="EMP-employee-text-in-body">
                    <h2>Leave Requests</h2>
                  </div>
                  <div className="EMP-input-for-search-in-employee-body">
                    <MdSearch className="EMP-search-icon-in-input" />
                    <input
                      type="text"
                      placeholder="Search by name"
                      onChange={(e) => handleDataSearch(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="main-class-for-table-in-leavemanagement">
                <table className="table-of-employee-leaves table-of-employee-approval">
                  <thead className="thead-of-emp-table-leaves">
                    <tr className="">
                      <th className="approve_Sr">Sr. No.</th>
                      <th className="approve_Name"> Employee Name</th>
                      <th className="approve_Date"> Date</th>
                      <th className="approve_Type">Leave Type</th>
                      <th className="approve_From">Leave From</th>
                      <th className="approve_To">Leave To</th>
                      <th className="approve_Days">No Of Days</th>
                      <th className="approve_Reason">Reason</th>
                      <th className="approve_Status">Status</th>
                      {getUserRole === "HR" && (
                        <th className="approve_Reason">
                          Rejected Team Lead Reason
                        </th>
                      )}
                      <th className="approve_Action">Action</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-ofemp-table-leaves">
                    {leavesApprovalData?.map((item, index) => (
                      <tr className="tbody-approval-leaves" key={index}>
                        <>
                          <td>{index + 1}</td>
                          <td>{item?.User?.UserName}</td>
                          <td>
                            {moment(item?.AppliedDate).format("DD MMM YYYY")}
                          </td>
                          <td>
                            {item?.LeaveType === 0
                              ? "Casual Leave"
                              : item?.LeaveType === 1
                              ? "Sick Leave"
                              : ""}
                          </td>
                          <td>
                            {moment(item?.StartDate).format("DD MMM YYYY")}
                          </td>
                          <td>{moment(item?.EndDate).format("DD MMM YYYY")}</td>
                          <td>
                            {item?.NumberofDays ? item?.NumberofDays : "-"}
                          </td>
                          <td>{item?.Reason ? item?.Reason : "-"}</td>
                          {getUserRole === "HR" ? (
                            <td>
                              <button
                                className={
                                  item?.Status === "Cancelled" ||
                                  item?.Status === "RejectedByTeamLead" ||
                                  item?.Status === "RejectedByHR"
                                    ? "EMP-Leaves-approval-table-Rejected-button"
                                    : item?.Status === "ApprovedByTeamLead"
                                    ? "EMP-YetToBeApprovedByTeamLead-button"
                                    : item?.Status ===
                                        "YetToBeApprovedByTeamLead" ||
                                      item?.Status === "ApprovedByHR"
                                    ? "EMP-YetToBeApprovedByTeamLead-button-Approval"
                                    : "EMP-Leaves-table-Approved-button"
                                }
                              >
                                {item?.Status === "YetToBeApprovedByTeamLead"
                                  ? "Pending at Team Lead"
                                  : item?.Status === "ApprovedByTeamLead"
                                  ? "Pending"
                                  : item?.Status === "RejectedByTeamLead"
                                  ? "Rejected by Team Lead"
                                  : item?.Status === "ApprovedByHR"
                                  ? "Pending at Admin"
                                  : item?.Status === "ApprovedByAdmin"
                                  ? "Approved by Admin"
                                  : item?.Status === "RejectedByAdmin"
                                  ? "Rejected by Admin"
                                  : item?.Status}
                              </button>
                            </td>
                          ) : (
                            <td>
                              <button
                                className={
                                  item?.Status === "RejectedByTeamLead" ||
                                  item?.Status === "RejectedByHR" ||
                                  item?.Status === "RejectedByAdmin"
                                    ? "EMP-Leaves-approval-table-Rejected-button"
                                    : item?.Status === "ApprovedByTeamLead" ||
                                      item?.Status === "ApprovedByHR"
                                    ? "EMP-YetToBeApprovedByTeamLead-button-Approval"
                                    : item?.Status ===
                                        "YetToBeApprovedByTeamLead" ||
                                      item?.Status === "YetToBeApprovedByHR" ||
                                      item?.Status === "YetToBeApprovedByAdmin"
                                    ? "EMP-YetToBeApprovedByTeamLead-button-Approval"
                                    : "EMP-Leaves-table-Approved-button"
                                }
                              >
                                {item?.Status === "YetToBeApprovedByTeamLead"
                                  ? "Pending"
                                  : item?.Status === "RejectedByTeamLead"
                                  ? "Rejected"
                                  : item?.Status === "RejectedByHR"
                                  ? "Rejected by HR"
                                  : item?.Status === "ApprovedByTeamLead"
                                  ? "Pending at HR"
                                  : item?.Status === "ApprovedByHR"
                                  ? "Pending at Admin"
                                  : item?.Status === "ApprovedByAdmin"
                                  ? "Approved by Admin"
                                  : item?.Status === "RejectedByAdmin"
                                  ? "Rejected by Admin"
                                  : item?.Status}
                              </button>
                            </td>
                          )}
                          {getUserRole === "HR" && (
                            <td>
                              {item?.RejectionReason
                                ? item?.RejectionReason
                                : "-"}
                            </td>
                          )}
                          {getUserRole === "HR" ? (
                            <td>
                              <div className="button_approve_leave">
                                {item?.Status === "ApprovedByTeamLead" ? (
                                  <>
                                    <button
                                      className="EMP-Approve-table-Approved-button"
                                      onClick={() =>
                                        setShowApproveModal(item?.Id)
                                      }
                                    >
                                      Approve
                                    </button>
                                    <button
                                      className="EMP-Approve-table-Rejected-button"
                                      onClick={() =>
                                        setShowRejectModal(item?.Id)
                                      }
                                    >
                                      Reject
                                    </button>
                                  </>
                                ) : (
                                  <div className="empty_leave_approval">-</div>
                                )}
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="button_approve_leave">
                                {item?.Status ===
                                "YetToBeApprovedByTeamLead" ? (
                                  <>
                                    <button
                                      className="EMP-Approve-table-Approved-button"
                                      onClick={() =>
                                        setShowApproveModal(item?.Id)
                                      }
                                    >
                                      Approve
                                    </button>
                                    <button
                                      className="EMP-Approve-table-Rejected-button"
                                      onClick={() =>
                                        setShowRejectModal(item?.Id)
                                      }
                                    >
                                      Reject
                                    </button>
                                  </>
                                ) : (
                                  <div className="empty_leave_approval"></div>
                                )}
                              </div>
                            </td>
                          )}
                        </>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div></div>
              </div>
            </div>
          </div>
        </>
      </div>
      {showRejectModal && (
        <div
          className="modal show edit-leaveModal"
          style={{ display: "block" }}
        >
          <Modal.Dialog>
            <Modal.Header
              className="delete-modal-header"
              closeButton
              onClick={() => setShowRejectModal("")}
            >
              <Modal.Title>Reject Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="textarea-validate">
                <label htmlFor="">Reason</label>
                <textarea
                  type="text"
                  className="textarea-for-delete-leave-api"
                  maxLength={100}
                  value={rejectLeave}
                  onChange={(e) => setRejectLeave(e?.target?.value)}
                />
                {rejectLeave ? (
                  <div className="character_left_leave_approval">
                    {rejectReasonLength - rejectLeave.length} characters left
                  </div>
                ) : (
                  <div className="character_left_leave_approval">
                    100 characters left
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="Delete-fotter">
              <button
                className="Delete-modal-close-button"
                onClick={() => setShowRejectModal("")}
              >
                Close
              </button>
              <button
                className="Delete-modal-delete-button"
                onClick={() => handleRejectLeave()}
              >
                Reject
              </button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
      {showApproveModal && (
        <div
          className="modal show edit-leaveModal"
          style={{ display: "block" }}
        >
          <Modal.Dialog>
            <Modal.Header
              className="delete-modal-header"
              closeButton
              onClick={() => setShowApproveModal("")}
            >
              <Modal.Title>Approve Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Footer className="Delete-fotter">
              <button
                className="Delete-modal-close-button"
                onClick={() => setShowApproveModal("")}
              >
                Close
              </button>
              <button
                className="Delete-modal-delete-button"
                onClick={() => handleApproveLeave()}
              >
                Approve
              </button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </>
  );
};
export default EMPLeavesApproval;
