import React, { useEffect, useState } from "react";

import "../../AdminScreens/AllEmployees/AllEmployees.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { GrRefresh } from "react-icons/gr";
import tableimg from "../../../Assets/Images/tableimage.jpg";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Modal } from "react-bootstrap";
import { RiEyeCloseFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import NewEmployeesModal from "../../../Components/Modals/NewEmployeesModal";
import { Apiurl, GetToken } from "../../../Utils/utils";
import Loader from "../../../Components/loader/loader";

const AllEmployees = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const PlusHandler = () => {
    navigate('/Adm/Employees/AddEmployee');
  }
  const GetAllEmployee = () => {
    setLoading(true);
    const token = GetToken();
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${Apiurl()}/Admin/GetAllEmployees`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setAllEmployeeData(data);
        //  setAttendanceData(data)
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    GetAllEmployee();
  }, [])

  const DeleteHandler = (event) => {
    const LoginToken = GetToken();
    const data = {};
    data.Id = event?.Id;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/DeleteEmployee`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          GetAllEmployee();
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }
  const handleEyeGetEmployee = (event) => {
    const LoginToken = GetToken();
    const data = {};
    data.Id = event?.Id;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/GetEmployee`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.data?.data);

        if (e?.status === 200) {
          toast.success(e?.data?.message);
          GetAllEmployee();
        }
        navigate("/Adm/Employees/EmployeeProfile" , {state:data})
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  const EditHandler = (e) => {
    navigate('/Adm/Employees/AddEmployee', {state:e})
  }

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        {showSidebar ? (
          <>
            <div className="main-container-of-all-employees-page">
              <div className="inner-container-of-all-employees-page ">
                <div className="header-of-employees-page row">
                  <div className="all-employee-text col">
                    <h4>All Employee</h4>
                  </div>
                  <div className="all-employees-directory col">
                    <div className="home-icon-header">
                      {" "}
                      <BiHomeAlt className="home-icon-header-inn" />{" "}
                    </div>
                    <div className="arrow-icon-header">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-header">
                      <p>Employees</p>
                    </div>
                    <div className="arrow-icon-header">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-header">
                      <p>All Employee</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="body-part-outer-container">
                <div className="inner-body-container-of-employee-table row-12">
                  <div className=" first-side-in-body-header col-6">
                    <div className="employee-text-in-body">
                      <h2>Employees</h2>
                    </div>
                    <div className="input-for-search-in-employee-body">
                      <MdSearch className="search-icon-in-input" />
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                  <div className=" images-section-on-body col-6 ">
                    <div className="plus-button-in-body">
                      <button
                        onClick={() => PlusHandler()}
                      >
                        <FaPlus className="plusbutton-icon" />
                      </button>
                    </div>
                    <div className="reloard-button-in-body">
                      <button>
                        <GrRefresh className="refreshbutton-icon" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-body">
                  <table className="table-main-class">
                    <thead className="thead-main-class">
                      <tr className="row-12">
                        <th className="col-1 input-class-in-td">
                          <input type="checkbox" />
                        </th>
                        <th className="col-1">Image</th>
                        <th className="col-1">Name</th>
                        <th className="col-2">Email</th>
                        <th className="col-3">Department</th>
                        <th className="col-3">Designation</th>
                        <th className="col-1">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-class">
                      {
                        allEmployeeData?.map((item, index) => {
                          return (
                            <tr key={index} className="tr-all-employee-tbl">
                              <td className="input-class-in-td">
                                <input type="checkbox" />
                              </td>
                              <td>
                                <img
                                  src={tableimg}
                                  alt=""
                                  className="image-in-table-td"
                                />
                              </td>
                              <td>{item?.FirstName ? item?.FirstName : '---'}</td>
                              <td>{item?.Email ? item?.Email : '---'}</td>
                              <td>{item?.Departments?.Name ? item?.Departments?.Name : '---'}</td>
                              <td>{item?.Designations?.DesignationName ? item?.Designations?.DesignationName : '---'}</td>
                              <td className="edit-icon-in-td">
                                <FaEdit className="edit-icon-main" onClick={() => EditHandler(item)} />
                                <RiDeleteBinLine className="delete-icon-main" onClick={() => DeleteHandler(item)} />
                                <RiEyeCloseFill className="eye-icon-main" onClick={() => handleEyeGetEmployee(item)} />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="main-container-of-all-employees-page-new">
            <div className="inner-container-of-all-employees-page ">
                <div className="header-of-employees-page row">
                  <div className="all-employee-text col">
                    <h4>All Employee</h4>
                  </div>
                  <div className="all-employees-directory col">
                    <div className="home-icon-header">
                      {" "}
                      <BiHomeAlt className="home-icon-header-inn" />{" "}
                    </div>
                    <div className="arrow-icon-header">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-header">
                      <p>Employees</p>
                    </div>
                    <div className="arrow-icon-header">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-header">
                      <p>All Employee</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="body-part-outer-container">
                <div className="inner-body-container-of-employee-table row-12">
                  <div className=" first-side-in-body-header col-6">
                    <div className="employee-text-in-body">
                      <h2>Employees</h2>
                    </div>
                    <div className="input-for-search-in-employee-body">
                      <MdSearch className="search-icon-in-input" />
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                  <div className=" images-section-on-body col-6 ">
                    <div className="plus-button-in-body">
                      <button
                        onClick={() => PlusHandler()}
                      >
                        <FaPlus className="plusbutton-icon" />
                      </button>
                    </div>
                    <div className="reloard-button-in-body">
                      <button>
                        <GrRefresh className="refreshbutton-icon" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-body">
                  <table className="table-main-class">
                    <thead className="thead-main-class">
                      <tr className="row-12">
                        <th className="col-1 input-class-in-td">
                          <input type="checkbox" />
                        </th>
                        <th className="col-1">Image</th>
                        <th className="col-1">Name</th>
                        <th className="col-2">Email</th>
                        <th className="col-3">Department</th>
                        <th className="col-3">Designation</th>
                        <th className="col-1">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-class">
                      {
                        allEmployeeData?.map((item, index) => {
                          return (
                            <tr key={index} className="tr-all-employee-tbl">
                              <td className="input-class-in-td">
                                <input type="checkbox" />
                              </td>
                              <td>
                                <img
                                  src={tableimg}
                                  alt=""
                                  className="image-in-table-td"
                                />
                              </td>
                              <td>{item?.FirstName ? item?.FirstName : '---'}</td>
                              <td>{item?.Email ? item?.Email : '---'}</td>
                              <td>{item?.Departments?.Name ? item?.Departments?.Name : '---'}</td>
                              <td>{item?.Designations?.DesignationName ? item?.Designations?.DesignationName : '---'}</td>
                              <td className="edit-icon-in-td">
                                <FaEdit className="edit-icon-main" onClick={() => EditHandler(item)} />
                                <RiDeleteBinLine className="delete-icon-main" onClick={() => DeleteHandler(item)} />
                                <RiEyeCloseFill className="eye-icon-main" onClick={() => handleEyeGetEmployee(item)} />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-in-employee-table"
      >
        <NewEmployeesModal className="modal-main-inner-container" />
      </Modal>
    </>
  );
};
export default AllEmployees;