import React, { useEffect, useState } from "react";
import "../../../Pages/AdminScreens/AllHolidays/AllHolidays.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { GrRefresh } from "react-icons/gr";
import excelimg from "../../../Assets/Images/excelsheetimg.png";
import tableimg from "../../../Assets/Images/tableimage.jpg";
import { MdModeEditOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import { Apiurl, GetToken } from "../../../Utils/utils";
import axios from "axios";
import { toast } from "react-toastify";

const AllHolidays = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [allHolidays, setAllHolidays] = useState([]);
  const LoginToken = GetToken();
  // GetAllHoliday API
  const GetAllHoliday = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Holiday/GetAllHoliday`,
    };
    axios(option)
      .then((e) => {
        setAllHolidays(JSON.parse(e?.data?.data));

      })
      .catch(() => {});
  };
  useEffect(() => {
    GetAllHoliday();
  }, []);


  const handleDeleteHoliday =(event)=>{
    const LoginToken = GetToken();
    const data = {};
    data.Id = event?.Id;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Holiday/DeleteHoliday`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          GetAllHoliday();
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }
  return (
    <>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />

        {showSidebar ? (
          <>
            <div className="main-container-of-all-employees-Attendance-Holiday">
              <div className="inner-container-of-all-employees-page-Holiday ">
                <div className="header-of-employees-page-Holiday row">
                  <div className="all-employee-Attendance-Holiday col">
                    <h4>All Holiday</h4>
                  </div>
                  <div className="all-employees-Attendance-Holiday col">
                    <div className="home-icon-Attendance-Holiday">
                      {" "}
                      <BiHomeAlt className="home-icon-header-Attendance-Holiday" />{" "}
                    </div>
                    <div className="arrow-icon-Attendance-Holiday">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-Attendance-Holiday">
                      <p>Holiday </p>
                    </div>
                    <div className="arrow-icon-Attendance-Holiday">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-Attendance-Holiday">
                      <p>All Holiday</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body-part-outer-Attendance-Holiday">
                <div className="inner-body-container-of-employee-Attendance-Holiday row-12">
                  <div className=" first-side-in-body-Attendance-Holiday col-6">
                    <div className="employee-text-in-Attendance-Holiday">
                      <h2>Holidays</h2>
                    </div>
                    <div className="input-for-search-in-employee-Attendance-Holiday">
                      <MdSearch className="search-icon-in-Attendance-Holiday" />
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-Attendance-Holiday">
                  <table className="table-main-Attendance-Holiday">
                    <thead className="thead-main-Attendance-Holiday">
                      <tr className="row-12">
                        <th className="image-class-Holiday col ">
                          <input type="checkbox" />
                        </th>

                        <th className="image-class-Holiday col-1 ">
                          Holiday Name
                        </th>
                        <th className="image-class-Holiday col-1 ">
                          Date From
                        </th>
                        <th className="image-class-Holiday col-1 ">Date To</th>
                        <th className="image-class-Holiday col-1 ">Details</th>
                        <th className="image-class-Holiday col-1 ">Action</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-Attendance-Holiday">
                      {allHolidays?.map((item, index) => (
                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>{item?.HolidayName}</td>
                          <td>{item?.FromDate?.split("T")[0]}</td>
                          <td>{item?.ToDate?.split("T")[0]}</td>
                          <td> {item?.Details}</td>

                          <td className="edit-delete-Holiday">
                            <MdModeEditOutline className="edit-icon-all-leaves-Holiday" />
                            <MdDelete className="delete-icon-all-leaves-Holiday" 
                            onClick={()=>handleDeleteHoliday(item)}/>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="main-container-of-all-employees-page-new-Attendance-management">
           
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AllHolidays;
