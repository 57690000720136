import "./App.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/LoginScreens/LoginPage/Login";
import SignUp from "./Pages/LoginScreens/SignupPage/SignUp";
import ForgotPassword from "./Pages/LoginScreens/ForgotPassword/ForgotPassword";
import ConfirmPassword from "./Pages/LoginScreens/ConfirmPassword/ConfirmPassword";
import Sidebar from "./Components/Dashboard/Sidebar/Sidebar";
import VerifyPassword from "./Pages/LoginScreens/VerifyPassword/VerifyPassword";
import AllEmployees from "./Pages/AdminScreens/AllEmployees/AllEmployees";
import NewEmployeesModal from "./Components/Modals/NewEmployeesModal";
import EmployeeProfile from "./Pages/AdminScreens/EmployeeProfile/EmployeeProfile";
import AddAnnouncement from "./Pages/AdminScreens/AddAnnouncement/AddAnnouncement";
import TodaysAttendance from "./Pages/AdminScreens/TodaysAttendance/TodaysAttendance";
import AttendanceSheet from "./Pages/AdminScreens/AttendanceSheet/AttendanceSheet";
import AddEmployee from "./Pages/AdminScreens/AddEmployee/AddEmployee";
import AdmDashboard from "./Components/Dashboard/AdmDashboard/Dashboard";
import EmpDashboard from "./Components/Dashboard/EmpDashboard/Dashboard";
import AllLeaveRequests from "./Pages/AdminScreens/LeaveManagement/AllLeaveRequests";
import LeaveBalance from "./Pages/AdminScreens/LeaveBalance/LeaveBalance";
import AllHolidays from "./Pages/AdminScreens/AllHolidays/AllHolidays";
import AddHolidays from "./Pages/AdminScreens/AddHolidays/AddHolidays";
import EMPattendance from "./Pages/EmployeeScreens/EMPAttendance/Empattendance";
import EMPHolidays from "./Pages/EmployeeScreens/EMPHolidays/EMPHolidays";
import EMPLeaves from "./Pages/EmployeeScreens/EMPleave/EMPLeaves";
import EMPLeavesRequest from "./Pages/EmployeeScreens/EMPLeavesRequest/EMPLeavesRequest";
import Header from "./Components/Dashboard/Header/Header";
import EMPProfile from "./Pages/EmployeeScreens/EmployeeProfile/EMPProfile";
import EMPLeavesApproval from "./Pages/EmployeeScreens/EMPLeavesApproval/EMPLeavesApproval";
function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/SignUp" element={<SignUp />}></Route>
          <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
          <Route path="/ConfirmPassword" element={<ConfirmPassword />}></Route>
          <Route path="/Sidebar" element={<Sidebar />}></Route>
          <Route path="/Header" element={<Header />}></Route>
          <Route path="/VerifyPassword" element={<VerifyPassword />}></Route>
          <Route path="/NewEmployeesModal" element={<NewEmployeesModal />}></Route>
                           {/* ########## Add  Admin Routes here ######## */}
          {/* Admin dashboard---->  */}
          <Route path="/Adm/Dashboard" element={<AdmDashboard />}></Route>
          <Route path="/Adm/Dashboard/AddAnnouncement" element={<AddAnnouncement />}></Route>/
          {/* Admin Employe Screens -->  */}
          <Route path="/Adm/Employees/AllEmployees" element={<AllEmployees />}></Route>
          <Route path="/Adm/Employees/AddEmployee" element={<AddEmployee />}></Route>
          <Route path="/Adm/Employees/EmployeeProfile" element={<EmployeeProfile />}></Route>
          {/* Admin Attendance Screens-->  */}
          <Route path="/Adm/Attendance/TodaysAttendance" element={<TodaysAttendance />}></Route>
          <Route path="/Adm/Attendance/AttendanceSheet" element={<AttendanceSheet />}></Route>
          {/* Admin Leave Management screens  */}
          <Route path="/Adm/LeaveManagement/AllLeaveRequests" element={<AllLeaveRequests />}></Route>
          <Route path="/Adm/LeaveManagement/LeaveBalance" element={<LeaveBalance />}></Route>
          {/* Admin Holiays screens   */}
          <Route path="/Adm/Holidays/AllHolidays" element={<AllHolidays />}></Route>
          <Route path="/Adm/Holidays/AddHolidays" element={<AddHolidays />}></Route>
    
                               {/* Add Employee Raute here  */}
          <Route path="/Emp/Dashboard" element={<EmpDashboard />}></Route>
          <Route path="/Emp/attendance" element={<EMPattendance />}></Route>
          <Route path="/Emp/EMPLeaves" element={<EMPLeaves/>}></Route>
          <Route path="/Emp/EMPLeavesApproval" element={<EMPLeavesApproval/>}></Route>
          <Route path="/Emp/ApplyLeaves" element={<EMPLeavesRequest/>}></Route>
          <Route path="/Emp/Holidays" element={<EMPHolidays/>}></Route>
          <Route path="/Emp/Profile" element={<EMPProfile/>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;