import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import '../../Components/loader/loader.css';

const Loader = () => {
    return (
        <div className="loader-main-div">
            <div className="loader-second-div">
               
                <ClipLoader
                    color={'#fff'}
                    loading={true}
                    size={90}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    )

}

export default Loader;