import React, { useEffect, useState } from "react";
import "../../EmployeeScreens/EMPHolidays/EMPHoliday.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import { Modal } from "react-bootstrap";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import NewEmployeesModal from "../../../Components/Modals/NewEmployeesModal";
import { Apiurl, GetToken } from "../../../Utils/utils";
import axios from "axios";
import Loader from "../../../Components/loader/loader";
import moment from "moment";
const EMPHolidays = () => {
  const [leaveRequestDuplicate, setLeaveRequestDuplicate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [holidayData, setHolidayData] = useState([]);
  const loginToken = GetToken();
  
  const GetAllHolidays = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${loginToken}`,
      },
      url: `${Apiurl()}/Holiday/GetAllHoliday`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setHolidayData(data);
        setLeaveRequestDuplicate(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    GetAllHolidays();
  }, []);
  const handleDataSearch = (e) => {
    const searchData = e?.target?.value;
    if (searchData) {
      const filterData = holidayData.filter(
        (item) =>
          item?.Days?.toLowerCase()?.includes(searchData) ||
          item?.HolidayName?.toLowerCase()?.includes(searchData)
      );
      setHolidayData(filterData);
    } else {
      setHolidayData(leaveRequestDuplicate);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <>
          <div
            className={
              showSidebar
                ? "EMP-Holiday-main-container-of-all-employees-page"
                : "EMP-Holiday-main-container-of-all-employees-page-new"
            }
          >
            <div className="vdjhbhf">
              <div className="EMp-Holiday-inner-container-of-all-employees-page ">
                <div className="EMP-header-of-employees-page row">
                  <div className="EMP-all-employee-text col">
                    <h4>All Holiday</h4>
                  </div>
                  <div className="EMP-all-employees-directory col">
                    <div className="EMP-home-icon-header">
                      {" "}
                      <BiHomeAlt className="EMP-home-icon-header-inn" />{" "}
                    </div>
                    <div className="EMP-arrow-icon-header">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="EMP-Holidays-employee-text-header">
                      <p>Holidays</p>
                    </div>
                    <div className="arrow-icon-header">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="EMP-all-employee-text-header">
                      <p>All Holidays</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="EMP-body-part-outer-container">
                <div className="EMP-inner-body-container-of-employee-table row-12">
                  <div className=" first-side-in-body-header col-6">
                    <div className="EMP-employee-text-in-body">
                      <h2>Holidays</h2>
                    </div>
                    <div className="EMP-input-for-search-in-employee-body">
                      <MdSearch className="EMP-search-icon-in-input" />
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => handleDataSearch(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="main-class-for-table-in-empholiday">
                  <table className="table-of-employee-empholiday">
                    <thead className="thead-of-emp-table-empholiday">
                      <tr className="row">
                        <th className="col-2">Sr. No.</th>
                        <th className="col-4">Holiday Name</th>
                        <th className="col-4">Date</th>
                        <th className="col-2">Day</th>
                        {/* <th className="">Date From</th>
                        <th className="">Date To</th>
                        <th className="">No. Of Days</th>
                        <th className="">Details</th> */}
                      </tr>
                    </thead>
                    <tbody className="tbody-ofemp-table-empholiday">
                      {holidayData?.map((item, index) => {
                        const Date = moment(item?.FromDate).format(
                          "DD MMM YYYY"
                        );
                
                        const startDate = moment(item?.FromDate);
                        const endDate = moment(item?.ToDate);
                        const alldata = endDate.diff(startDate, "days");
                        return (  
                          <tr className="row" key={item?.id}>
                            <td className="col-2">{index + 1}</td>
                            <td className="col-4">{item?.HolidayName}</td>
                            <td className="col-4">{Date}</td>
                            <td className="col-2">{item?.Days}</td>
                            {/* <td>{Datefrom}</td> */}
                            {/* <td>{DateTo}</td> */}
                            {/* <td className="">{moment.duration(moment(item?.ToDate).diff(moment(item?.FromDate))).asDays() + 1}</td> */}
                            {/* <td className="">{item?.Details ? item?.Details : "-"}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-in-employee-table"
      >
        <NewEmployeesModal className="modal-main-inner-container" />
      </Modal>
    </>
  );
};
export default EMPHolidays;