import React, { useState } from "react";
import "../../../Pages/AdminScreens/EmployeeProfile/EmployeeProfile.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Modal } from "react-bootstrap";
import profileimg from "../../../Assets/Images/profileimg.png";
import { FaPhoneAlt } from "react-icons/fa";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import NewEmployeesModal from "../../../Components/Modals/NewEmployeesModal";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import { useLocation } from "react-router-dom";

const EmployeeProfile = () => {
  const location = useLocation();
  const comingData = location.state;
  const [changePassword, setChangePassword] = useState("");
  const [username, setUsername] = useState("");
  const [errorusername, setErrorusername] = useState(false);
  const [newPasswordEye, setNewPasswordEye] = useState(false);
  const [errorpassword, setErrorpassword] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />

        {showSidebar ? (
          <>
            <div className="main-container-of-all-employees-page-profile">
              <div className="inner-container-of-all-employees-page ">
                <div className="header-of-employees-page row">
                  <div className="all-employee-text-profile col">
                    <h4>Profile</h4>
                  </div>
                  <div className="all-employees-directory-profile col">
                    <div className="home-icon-header-profile">
                      {" "}
                      <BiHomeAlt className="home-icon-header-inn" />{" "}
                    </div>
                    <div className="arrow-icon-header-profile">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-header-profile">
                      <p>Employees</p>
                    </div>
                    <div className="arrow-icon-header-profile">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-header-profile">
                      <p>Profile</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body-part-outer-container-profile">
                <div className="table-in-all-emplyee-body-profile">
                  <div className="row">
                    <div className="first-card-main-container  col-4">
                      <div className="inner-container-of-first-card">
                        <div className="first-card-of-body-in-employee-table">
                          {comingData?.FirstName ? (
                            <p className="dr-john-smith">
                              {comingData?.FirstName}
                            </p>
                          ) : (
                            <p className="dr-john-smith">-</p>
                          )}
                          {comingData?.Designations?.DesignationName ? (
                            <p className="senior-empolyee">
                              {comingData?.Designations?.DesignationName}
                            </p>
                          ) : (
                            <p className="senior-empolyee">-</p>
                          )}
                        </div>
                        <div className="porfile-image-main-div">
                          <img
                            src={profileimg}
                            alt=""
                            className="profileimage-class"
                          />
                        </div>
                        <div>
                          {comingData?.Address ? (
                            <p className="senior-empolyee-details">
                              {comingData?.Address}{" "}
                            </p>
                          ) : (
                            <p className="senior-empolyee-details">
                              -
                            </p>
                          )}
                          <span className="phone-icon-and-number">
                            <FaPhoneAlt />
                            {comingData?.Mobile ? (
                              <p>{comingData?.Mobile} </p>
                            ) : (
                              <p>-</p>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="about-and-skill-card-main">
                        <Tabs
                          defaultActiveKey="home"
                          transition={false}
                          id="noanim-tab-example"
                          className="about-and-skills-tab-in-employee-table"
                        >
                          <Tab eventKey="home" title="About">
                            <div className="inner-container-for-about-and-skill-pera">
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book. It has survived not only five centuries,
                                but also the leap into electronic typesetting,
                                remaining essentially unchanged.
                              </p>
                              <div>
                                <small>Email</small>
                                { comingData?.Email ? <p>{comingData?.Email}</p> : <p>-</p>}
                              </div>
                              <hr />
                              {/* <div>
                                <small>Email</small>
                                <p>{comingData?.Email}</p>
                              </div> */}
                              {/* <hr /> */}
                            </div>
                          </Tab>
                          <Tab
                            eventKey="profile"
                            title="Skills
"
                            className="tab-skill-employee"
                          >
                            <div className="opd-with-progress-lines">
                              <div className="four-progress-bars-in-porfile-employee">
                                <p>RND</p>
                                <div className="progress-bar-outter-profile">
                                  <div className="inner-progressbar-profile"></div>
                                </div>
                              </div>
                              <div className="four-progress-bars-in-porfile-employee">
                                <p>OPD</p>
                                <div className="progress-bar-outter-profile-second">
                                  <div className="inner-progressbar-profile-second"></div>
                                </div>
                              </div>

                              <div className="four-progress-bars-in-porfile-employee">
                                <p>Operations</p>
                                <div className="progress-bar-outter-profile-third">
                                  <div className="inner-progressbar-profile-third"></div>
                                </div>
                              </div>

                              <div className="four-progress-bars-in-porfile-employee">
                                <p>Client Visit</p>
                                <div className="progress-bar-outter-profile-fourth">
                                  <div className="inner-progressbar-profile-fourth"></div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>

                    <div className="second-card-main-container-withcol col-8">
                      <div>
                        <Tabs
                          defaultActiveKey="home"
                          id="uncontrolled-tab-example"
                          className="tabs-main-container-class"
                        >
                          <Tab
                            eventKey="home"
                            title="About Me"
                            className="about-me-main-container"
                          >
                            <div>
                              <div>
                                <div className="inner-container-of-about-tab-insecond-card">
                                  <h2 className="about-heading-in-tabs">
                                    About
                                  </h2>
                                </div>
                                <div className="all-details-of-userlogin row">
                                  <div className=" full-name-detail  col-3">
                                    <strong>Full Name </strong>
                                    {comingData?.FirstName ? (
                                      <p>{comingData?.FirstName}</p>
                                    ) : (
                                      <p>-</p>
                                    )}
                                  </div>
                                  <div className="mobile-number-detail col-3">
                                    <strong>Mobile </strong>
                                    {comingData?.Mobile ?  <p>{comingData?.Mobile}</p> : <p>-</p> }
                                  </div>
                                  <div className="email-detail col-3">
                                    <strong>Email</strong>
                                    { comingData?.Email ?  <p>{comingData?.Email}</p> : <p>-</p>}
                                  </div>
                                  <div className="location-details col-3">
                                    <strong>Location </strong>
                                    { comingData?.Address ? <p>{comingData?.Address} </p> : <p>-</p> }
                                  </div>

                                  <div className="personal-details-of-user">
                                    <p>
                                      Completed my graduation in Arts from the
                                      well known and renowned institution of
                                      India – SARDAR PATEL ARTS COLLEGE, BARODA
                                      in 2000-01, which was affiliated to M.S.
                                      University. I ranker in University exams
                                      from the same university from 1996-01.
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                    <p>
                                      Worked as Professor and Head of the
                                      department at Sarda Collage, Rajkot,
                                      Gujarat from 2003-2015{" "}
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem
                                      Ipsum has been the industry's standard
                                      dummy text ever since the 1500s, when an
                                      unknown printer took a galley of type and
                                      scrambled it to make a type specimen book.
                                      It has survived not only five centuries,
                                      but also the leap into electronic
                                      typesetting, remaining essentially
                                      unchanged.
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                  </div>
                                </div>

                                <div className="education-details-of-employee-new">
                                  <div className="education-heading-main-new">
                                    <h2>Education</h2>
                                  </div>
                                  <div className="education-details-pera-new">
                                    <p>
                                      M.B.B.S.,Gujarat University,
                                      Ahmedabad,India.
                                    </p>{" "}
                                    <p>
                                      M.S.,Gujarat University, Ahmedabad, India.
                                    </p>{" "}
                                    <p>
                                      SPINAL FELLOWSHIP Dr. John Adam,
                                      Allegimeines Krakenhaus, Schwerin,
                                      Germany.
                                    </p>{" "}
                                    <p>
                                      Fellowship in Endoscopic Spine Surgery
                                      Phoenix, USA.
                                    </p>
                                  </div><p className="red-pendin">(P)</p>
                                </div>

                                <div className="education-details-of-employee">
                                  <div className="education-heading-main">
                                    <h2>Experience</h2>
                                  </div>
                                  <div className="education-details-pera">
                                    <p>
                                      One year rotatory internship from
                                      April-2009 to march-2010 at B. J. Medical
                                      College, Ahmedabad. Three year residency
                                      at V.S. General Hospital as a resident in
                                      orthopedics from April - 2008 to April -
                                      2011. I have worked as a part time
                                      physiotherapist in Apang manav mandal from
                                      1st june 2004 to 31st jan 2005. Clinical
                                      and Research fellowship in Scoliosis at
                                      Shaurashtra University and Medical Centre
                                      (KUMC) , Krishna Hospital , Rajkot from
                                      April 2013 to June 2013. 2.5 Years Worked
                                      at Mahatma Gandhi General Hospital,
                                      Surendranagar. Consultant Orthopedics
                                      Surgeon Jalna 2 years.
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                  </div>
                                </div>

                                <div className="Conferences-details-of-employee">
                                  <div className="Conferences-heading-main">
                                    <h2>
                                      Conferences, Cources & Workshop Attended
                                    </h2>
                                  </div>
                                  <div className="Conferences-details-pera">
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem
                                      Ipsum is simply dummy text of the printing
                                      and typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry.
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="profile" title="Settings">
                            <div className="main-container-for-security-setting">
                              <div className="security-strong">
                                <h2>
                                  <strong>Security </strong>
                                  Settings
                                </h2>
                              </div>

                              <div>
                                <div className="username-password-fields">
                                  <div className="username-content-login">
                                    <FloatingLabelInput
                                      label="Username"
                                      type="text"
                                      onChange={(e) =>
                                        setUsername(e?.target?.value)
                                      }
                                      value={username}
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          setErrorusername("userdata");
                                        } else {
                                          setErrorusername("false");
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="password-floating-login">
                                    <FloatingLabelInput
                                      label="Current Password "
                                      type={
                                        newPasswordEye ? "text" : "password"
                                      }
                                      className="login-password-input"
                                      onChange={(e) =>
                                        setChangePassword(e?.target?.value)
                                      }
                                      value={changePassword}
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          setErrorpassword("passworddata");
                                        } else {
                                          setErrorpassword("false");
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="password-floating-login">
                                    <FloatingLabelInput
                                      label="New Password "
                                      type={
                                        newPasswordEye ? "text" : "password"
                                      }
                                      className="login-password-input"
                                      onChange={(e) =>
                                        setChangePassword(e?.target?.value)
                                      }
                                      value={changePassword}
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          setErrorpassword("passworddata");
                                        } else {
                                          setErrorpassword("false");
                                        }
                                      }}
                                    />
                                    <div className="save-button-in-setting-tab">
                                      <button>Save</button>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="main-container-of-all-employees-page-profile-new">
            <div className="inner-container-of-all-employees-page ">
                <div className="header-of-employees-page row">
                  <div className="all-employee-text-profile col">
                    <h4>Profile</h4>
                  </div>
                  <div className="all-employees-directory-profile col">
                    <div className="home-icon-header-profile">
                      {" "}
                      <BiHomeAlt className="home-icon-header-inn" />{" "}
                    </div>
                    <div className="arrow-icon-header-profile">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-header-profile">
                      <p>Employees</p>
                    </div>
                    <div className="arrow-icon-header-profile">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-header-profile">
                      <p>Profile</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body-part-outer-container-profile">
                <div className="table-in-all-emplyee-body-profile">
                  <div className="row">
                    <div className="first-card-main-container  col-4">
                      <div className="inner-container-of-first-card">
                        <div className="first-card-of-body-in-employee-table">
                          {comingData?.FirstName ? (
                            <p className="dr-john-smith">
                              {comingData?.FirstName}
                            </p>
                          ) : (
                            <p className="dr-john-smith">-</p>
                          )}
                          {comingData?.Designations?.DesignationName ? (
                            <p className="senior-empolyee">
                              {comingData?.Designations?.DesignationName}
                            </p>
                          ) : (
                            <p className="senior-empolyee">-</p>
                          )}
                        </div>
                        <div className="porfile-image-main-div">
                          <img
                            src={profileimg}
                            alt=""
                            className="profileimage-class"
                          />
                        </div>
                        <div>
                          {comingData?.Address ? (
                            <p className="senior-empolyee-details">
                              {comingData?.Address}{" "}
                            </p>
                          ) : (
                            <p className="senior-empolyee-details">
                              -
                            </p>
                          )}
                          <span className="phone-icon-and-number">
                            <FaPhoneAlt />
                            {comingData?.Mobile ? (
                              <p>{comingData?.Mobile} </p>
                            ) : (
                              <p>-</p>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="about-and-skill-card-main">
                        <Tabs
                          defaultActiveKey="home"
                          transition={false}
                          id="noanim-tab-example"
                          className="about-and-skills-tab-in-employee-table"
                        >
                          <Tab eventKey="home" title="About">
                            <div className="inner-container-for-about-and-skill-pera">
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book. It has survived not only five centuries,
                                but also the leap into electronic typesetting,
                                remaining essentially unchanged.
                              </p>
                              <div>
                                <small>Email</small>
                                { comingData?.Email ? <p>{comingData?.Email}</p> : <p>-</p>}
                              </div>
                              <hr />
                              {/* <div>
                                <small>Email</small>
                                <p>{comingData?.Email}</p>
                              </div> */}
                              {/* <hr /> */}
                            </div>
                          </Tab>
                          <Tab
                            eventKey="profile"
                            title="Skills
"
                            className="tab-skill-employee"
                          >
                            <div className="opd-with-progress-lines">
                              <div className="four-progress-bars-in-porfile-employee">
                                <p>RND</p>
                                <div className="progress-bar-outter-profile">
                                  <div className="inner-progressbar-profile"></div>
                                </div>
                              </div>
                              <div className="four-progress-bars-in-porfile-employee">
                                <p>OPD</p>
                                <div className="progress-bar-outter-profile-second">
                                  <div className="inner-progressbar-profile-second"></div>
                                </div>
                              </div>

                              <div className="four-progress-bars-in-porfile-employee">
                                <p>Operations</p>
                                <div className="progress-bar-outter-profile-third">
                                  <div className="inner-progressbar-profile-third"></div>
                                </div>
                              </div>

                              <div className="four-progress-bars-in-porfile-employee">
                                <p>Client Visit</p>
                                <div className="progress-bar-outter-profile-fourth">
                                  <div className="inner-progressbar-profile-fourth"></div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>

                    <div className="second-card-main-container-withcol col-8">
                      <div>
                        <Tabs
                          defaultActiveKey="home"
                          id="uncontrolled-tab-example"
                          className="tabs-main-container-class"
                        >
                          <Tab
                            eventKey="home"
                            title="About Me"
                            className="about-me-main-container"
                          >
                            <div>
                              <div>
                                <div className="inner-container-of-about-tab-insecond-card">
                                  <h2 className="about-heading-in-tabs">
                                    About
                                  </h2>
                                </div>
                                <div className="all-details-of-userlogin row">
                                  <div className=" full-name-detail  col-3">
                                    <strong>Full Name </strong>
                                    {comingData?.FirstName ? (
                                      <p>{comingData?.FirstName}</p>
                                    ) : (
                                      <p>-</p>
                                    )}
                                  </div>
                                  <div className="mobile-number-detail col-3">
                                    <strong>Mobile </strong>
                                    {comingData?.Mobile ?  <p>{comingData?.Mobile}</p> : <p>-</p> }
                                  </div>
                                  <div className="email-detail col-3">
                                    <strong>Email</strong>
                                    { comingData?.Email ?  <p>{comingData?.Email}</p> : <p>-</p>}
                                  </div>
                                  <div className="location-details col-3">
                                    <strong>Location </strong>
                                    { comingData?.Address ? <p>{comingData?.Address} </p> : <p>-</p> }
                                  </div>

                                  <div className="personal-details-of-user">
                                    <p>
                                      Completed my graduation in Arts from the
                                      well known and renowned institution of
                                      India – SARDAR PATEL ARTS COLLEGE, BARODA
                                      in 2000-01, which was affiliated to M.S.
                                      University. I ranker in University exams
                                      from the same university from 1996-01.
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                    <p>
                                      Worked as Professor and Head of the
                                      department at Sarda Collage, Rajkot,
                                      Gujarat from 2003-2015{" "}
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem
                                      Ipsum has been the industry's standard
                                      dummy text ever since the 1500s, when an
                                      unknown printer took a galley of type and
                                      scrambled it to make a type specimen book.
                                      It has survived not only five centuries,
                                      but also the leap into electronic
                                      typesetting, remaining essentially
                                      unchanged.
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                  </div>
                                </div>

                                <div className="education-details-of-employee-new">
                                  <div className="education-heading-main-new">
                                    <h2>Education</h2>
                                  </div>
                                  <div className="education-details-pera-new">
                                    <p>
                                      M.B.B.S.,Gujarat University,
                                      Ahmedabad,India.
                                    </p>{" "}
                                    <p>
                                      M.S.,Gujarat University, Ahmedabad, India.
                                    </p>{" "}
                                    <p>
                                      SPINAL FELLOWSHIP Dr. John Adam,
                                      Allegimeines Krakenhaus, Schwerin,
                                      Germany.
                                    </p>{" "}
                                    <p>
                                      Fellowship in Endoscopic Spine Surgery
                                      Phoenix, USA.
                                    </p>
                                  </div><p className="red-pendin">(P)</p>
                                </div>

                                <div className="education-details-of-employee">
                                  <div className="education-heading-main">
                                    <h2>Experience</h2>
                                  </div>
                                  <div className="education-details-pera">
                                    <p>
                                      One year rotatory internship from
                                      April-2009 to march-2010 at B. J. Medical
                                      College, Ahmedabad. Three year residency
                                      at V.S. General Hospital as a resident in
                                      orthopedics from April - 2008 to April -
                                      2011. I have worked as a part time
                                      physiotherapist in Apang manav mandal from
                                      1st june 2004 to 31st jan 2005. Clinical
                                      and Research fellowship in Scoliosis at
                                      Shaurashtra University and Medical Centre
                                      (KUMC) , Krishna Hospital , Rajkot from
                                      April 2013 to June 2013. 2.5 Years Worked
                                      at Mahatma Gandhi General Hospital,
                                      Surendranagar. Consultant Orthopedics
                                      Surgeon Jalna 2 years.
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                  </div>
                                </div>

                                <div className="Conferences-details-of-employee">
                                  <div className="Conferences-heading-main">
                                    <h2>
                                      Conferences, Cources & Workshop Attended
                                    </h2>
                                  </div>
                                  <div className="Conferences-details-pera">
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem
                                      Ipsum is simply dummy text of the printing
                                      and typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry. Lorem Ipsum is
                                      simply dummy text of the printing and
                                      typesetting industry.
                                    </p>
                                    <p className="red-pendin">(P)</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="profile" title="Settings">
                            <div className="main-container-for-security-setting">
                              <div className="security-strong">
                                <h2>
                                  <strong>Security </strong>
                                  Settings
                                </h2>
                              </div>

                              <div>
                                <div className="username-password-fields">
                                  <div className="username-content-login">
                                    <FloatingLabelInput
                                      label="Username"
                                      type="text"
                                      onChange={(e) =>
                                        setUsername(e?.target?.value)
                                      }
                                      value={username}
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          setErrorusername("userdata");
                                        } else {
                                          setErrorusername("false");
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="password-floating-login">
                                    <FloatingLabelInput
                                      label="Current Password "
                                      type={
                                        newPasswordEye ? "text" : "password"
                                      }
                                      className="login-password-input"
                                      onChange={(e) =>
                                        setChangePassword(e?.target?.value)
                                      }
                                      value={changePassword}
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          setErrorpassword("passworddata");
                                        } else {
                                          setErrorpassword("false");
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="password-floating-login">
                                    <FloatingLabelInput
                                      label="New Password "
                                      type={
                                        newPasswordEye ? "text" : "password"
                                      }
                                      className="login-password-input"
                                      onChange={(e) =>
                                        setChangePassword(e?.target?.value)
                                      }
                                      value={changePassword}
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          setErrorpassword("passworddata");
                                        } else {
                                          setErrorpassword("false");
                                        }
                                      }}
                                    />
                                    <div className="save-button-in-setting-tab">
                                      <button>Save</button>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-in-employee-table"
      >
        <NewEmployeesModal className="modal-main-inner-container" />
      </Modal>
    </>
  );
};

export default EmployeeProfile;
