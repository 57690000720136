import React, { useEffect, useState } from "react";
import "./FloatingLabelInput.css";
const FloatingLabelInput = ({ label, type, onChange , onBlur,value, maxLength , disabled , cursor }) => {
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const handleFocus = () => {
    setInputIsFocused(true);
  };
  const handleBlur = (e) => {
    if (!e?.target?.value) {
      setInputIsFocused(false);
      onBlur && onBlur(e);
    }
  };

  useEffect(() => {
    if (value) {
      setInputIsFocused(true);
    }
  },[value])
  return (
    <div className={`floating-label-input ${inputIsFocused ? "focused" : ""}`}>
      <input
      
        className={`input-floating-label ${cursor ? 'cursor-not-allowed' : ""}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type={type}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        // cursor={cursor}
      />
      <label className="label-floating">{label}</label>
    </div>
  );
};

export default FloatingLabelInput;
