import React, { useState } from "react";
import "../../../Pages/AdminScreens/LeaveBalance/LeaveBalance.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import tableimg from "../../../Assets/Images/tableimage.jpg";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";

const LeaveBalance = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />

        {showSidebar ? (
          <>
            <div className="main-container-of-all-employees-Attendance-Balance">
              <div className="inner-container-of-all-employees-page-Balance ">
                <div className="header-of-employees-page-Balance row">
                  <div className="all-employee-Attendance-Balance col">
                    <h4>Leave Balance</h4>
                  </div>
                  <div className="all-employees-Attendance-Balance col">
                    <div className="home-icon-Attendance-Balance">
                      {" "}
                      <BiHomeAlt className="home-icon-header-Attendance-Balance" />{" "}
                    </div>
                    <div className="arrow-icon-Attendance-Balance">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-Attendance-Balance">
                      <p>Leave </p>
                    </div>
                    <div className="arrow-icon-Attendance-Balance">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-Attendance-Balance">
                      <p>Leave Balance</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body-part-outer-Attendance-Balance">
                <div className="inner-body-container-of-employee-Attendance-Balance row-12">
                  <div className=" first-side-in-body-Attendance-Balance col-6">
                    <div className="employee-text-in-Attendance-Balance">
                      <h2>Leave Balance</h2>
                    </div>
                    <div className="input-for-search-in-employee-Attendance-Balance">
                      <MdSearch className="search-icon-in-Attendance-Balance" />
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-Attendance-Balance">
                  <table className="table-main-Attendance-Balance">
                    <thead className="thead-main-Attendance-Balance">
                      <tr className="row-12">
                        <th className="image-class-Balance col-1 ">Image</th>
                        <th className="image-class-Balance col-1 ">Name</th>
                        <th className="image-class-Balance col-1 ">
                          Previous Year
                        </th>
                        <th className="image-class-Balance col-1 ">
                          Current Year
                        </th>
                        <th className="image-class-Balance col-1 ">Total </th>
                        <th className="image-class-Balance col-1 ">Used</th>
                        <th className="image-class-Balance col-1 ">Accepted</th>
                        <th className="image-class-Balance col-1 ">Rejected</th>
                        <th className="image-class-Balance col-1 ">
                          Carry Over
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-Attendance-Balance">
                      <tr>
                        <td>
                          {" "}
                          <img
                            src={tableimg}
                            alt=""
                            className="image-in-table-Attendance-Balance"
                          />
                        </td>
                        <td> Sarah Smith</td>
                        <td>12</td>
                        <td>2</td>
                        <td> 5</td>
                        <td> 55</td>
                        <td>23</td>

                        <td>12</td>
                        <td>3</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
              <div className="main-container-of-all-employees-page-new-Attendance-management">
              <div className="inner-container-of-all-employees-page-Balance ">
                <div className="header-of-employees-page-Balance row">
                  <div className="all-employee-Attendance-Balance col">
                    <h4>Leave Balance</h4>
                  </div>
                  <div className="all-employees-Attendance-Balance col">
                    <div className="home-icon-Attendance-Balance">
                      {" "}
                      <BiHomeAlt className="home-icon-header-Attendance-Balance" />{" "}
                    </div>
                    <div className="arrow-icon-Attendance-Balance">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-Attendance-Balance">
                      <p>Leave </p>
                    </div>
                    <div className="arrow-icon-Attendance-Balance">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-Attendance-Balance">
                      <p>Leave Balance</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body-part-outer-Attendance-Balance">
                <div className="inner-body-container-of-employee-Attendance-Balance row-12">
                  <div className=" first-side-in-body-Attendance-Balance col-6">
                    <div className="employee-text-in-Attendance-Balance">
                      <h2>Leave Balance</h2>
                    </div>
                    <div className="input-for-search-in-employee-Attendance-Balance">
                      <MdSearch className="search-icon-in-Attendance-Balance" />
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-Attendance-Balance">
                  <table className="table-main-Attendance-Balance">
                    <thead className="thead-main-Attendance-Balance">
                      <tr className="row-12">
                        <th className="image-class-Balance col-1 ">Image</th>
                        <th className="image-class-Balance col-1 ">Name</th>
                        <th className="image-class-Balance col-1 ">
                          Previous Year
                        </th>
                        <th className="image-class-Balance col-1 ">
                          Current Year
                        </th>
                        <th className="image-class-Balance col-1 ">Total </th>
                        <th className="image-class-Balance col-1 ">Used</th>
                        <th className="image-class-Balance col-1 ">Accepted</th>
                        <th className="image-class-Balance col-1 ">Rejected</th>
                        <th className="image-class-Balance col-1 ">
                          Carry Over
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-Attendance-Balance">
                      <tr>
                        <td>
                          {" "}
                          <img
                            src={tableimg}
                            alt=""
                            className="image-in-table-Attendance-Balance"
                          />
                        </td>
                        <td> Sarah Smith</td>
                        <td>12</td>
                        <td>2</td>
                        <td> 5</td>
                        <td> 55</td>
                        <td>23</td>

                        <td>12</td>
                        <td>3</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LeaveBalance;
