import React, { useState } from "react";
import "../LoginPage/Login.css";
import kuberimglogin from "../../../Assets/Images/newloginpage.jpg";
import { FaGoogle } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import { Apiurl, SetLoginRole, Token } from "../../../Utils/utils";
import Loader from "../../../Components/loader/loader";
const Login = () => {
  const navigate = useNavigate();
  const [newPasswordEye, setNewPasswordEye] = useState(false);
  const [changePassword, setChangePassword] = useState('');
  const [username, setUsername] = useState('');
  const [errorusername, setErrorusername] = useState(false);
  const [errorpassword, setErrorpassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const
    handleClickNewPassword = () => {
      setNewPasswordEye(!newPasswordEye);
    };
  const validate = () => {
    if (!username) {
      setErrorusername(true);
      return false;
    }
    if (!changePassword) {
      setErrorpassword(true);
      return false;
    }
    return true;
  };
  const handleLoginNavigate = () => {
    navigate("/SignUp");
  };
  const handleForgotPassword = () => {
    navigate("/ForgotPassword");
  };
  const handleLoginSubmit = (e) => {
    if (!validate()) return;
    e?.preventDefault();
    setLoading(true);
    const data = {
      EmailAddress: username,
      Password: changePassword,
    };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Account/LoginUser`,
    };
    axios(option)
      .then((e) => {
        if (e?.data?.success) {
          toast.success(e?.data?.message);
          Token(e?.data?.data?.token);
          SetLoginRole(e?.data?.data?.roleName);
          setUsername('');
          setChangePassword('');
          setLoading(false);
          if (e?.data?.data?.roleName === 'Employee') {
            navigate("/Emp/Dashboard");
          } else {
            navigate("/Adm/Dashboard");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Login Error:", err);
        toast.error(
          err?.response?.data?.message || "An error occurred during login."
        );
      });
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="full-page-main">
        <div className="main-container-of-login-page row-12">
          <div className="login-page-image col-6">
            <img src={kuberimglogin} alt="" />
          </div>
          <div className="login-page-form col-6">
            <div className="inner-container-of-login-form">
              <h2>Welcome To Elite HRMS</h2>
              {/* <div className="need-a-accoount-signup">
                <p>Need an account?</p>{" "}
                <a href="" onClick={handleLoginNavigate}>
                  Sign Up
                </a>
              </div> */}
              {/* <div className="admin-employee-client">
                <div>
                  <button className="admin-button-login">Admin</button>
                </div>
                <div>
                  <button className="employee-button-login">Employee</button>
                </div>
                <div>
                  <button className="client-button-login">Client</button>
                </div>
              </div> */}
              <div>
                {/* <h2 className="signup-heading">Sign in</h2> */}
                <div className="email-and-password-inputs">
                  <div>
                    <div className="username-content-login">
                      <FloatingLabelInput
                        label="Username*"
                        type="text"
                        onChange={(e) => setUsername(e?.target?.value)}
                        value={username}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setErrorusername("userdata");
                          } else {
                            setErrorusername("false");
                          }
                        }}
                      />
                      {errorusername === "userdata" && !username ? (
                        <span className="contact_error-login">
                          Username is required.
                        </span>
                      ) : null}
                    </div>
                    <div className="password-floating-login">
                      <FloatingLabelInput
                        label="Password*"
                        type={newPasswordEye ? "text" : "password"}
                        className="login-password-input"
                        onChange={(e) => setChangePassword(e?.target?.value)}
                        value={changePassword}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setErrorpassword("passworddata");
                          } else {
                            setErrorpassword("false");
                          }
                        }}
                      />
                      {newPasswordEye ? (
                        <AiOutlineEye
                          className="eye-password-login"
                          onClick={() => handleClickNewPassword()}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="eye-password-login"
                          onClick={() => handleClickNewPassword()}
                        />
                      )}
                      {errorpassword === "passworddata" && !changePassword ? (
                        <span className="contact_error-login">
                          Password is required.
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="remember-me-and-forgot-password">
                  <div className="remember-me-input-label">
                    <input type="checkbox" />
                    <label htmlFor="">Remember me</label>
                  </div>
                  <div className="forgot-password">
                    <a href="" onClick={handleForgotPassword}>
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <div className="login-button-login">
                  {/* <button onClick={() => handleLoginSubmit()}>Login</button> */}
                  <button
                    style={{
                      backgroundColor:
                        username && changePassword
                          ? "#3f51b5"
                          : "rgba(0, 0, 0, .12)",
                      color:
                        username && changePassword
                          ? "#fff"
                          : "rgba(0, 0, 0, .38)",
                    }}
                    onClick={() => handleLoginSubmit()}
                  >
                    Login
                  </button>
                </div>
                {/* <div className="or-with-lines">
                  <div className="blank-div-one"></div>
                  <div className="or-align-center"> OR </div>
                  <div className="blank-div-two"></div>
                </div>
                <div className="four-button-footer-part">
                  <button>
                    <FaGoogle />
                  </button>{" "}
                  <button>
                    <FaFacebookF />
                  </button>{" "}
                  <button>
                    <FaTwitter />
                  </button>{" "}
                  <button>
                    <FaLinkedinIn />
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;