import React, { useEffect, useState } from "react";
import "../../AdminScreens/AttendanceSheet/AttendanceSheet.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import tableimg from "../../../Assets/Images/tableimage.jpg";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import Header from "../../../Components/Dashboard/Header/Header";
import { Apiurl, GetToken } from "../../../Utils/utils";
import moment from "moment";
import { toast } from "react-toastify";

const AttendanceSheet = () => {
  const [monthResultAdm, setMonthResultAdm] = useState([]);
  const [showSidebar, setShowSidebar] = useState(true);
  const [AttendanceData, setAttendanceData] = useState([]);
  const current = new Date();
  const [searchAttendance, setSearchAttendance] = useState({
    startDate: "",
    endDate: "",
  });
  const handleAttendanceSearch = () => {
    const LoginToken = GetToken();
    const data = {
      startDate: searchAttendance?.startDate,
      endDate: searchAttendance?.endDate,
      dayscount: searchAttendance?.dayscount,
    };

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/GetAttendanceByDateAdmin`,
      data: { data: JSON.stringify(data) },
    };

    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.data?.data);
  

        if (e?.status === 200) {
          toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    handleAttendanceSearch();
  }, []);

  const leftDays = Array.from(
    { length: 31 - monthResultAdm?.length },
    (_, index) => index + 1
  );

  return (
    <div>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />

        {showSidebar ? (
          <>
            <div className="main-container-of-all-employees-sheet">
            <div className="EMP-inner-container-of-all-employees-page ">
                <div className="header-of-employees-page row">
                  <div className="EMP-all-employee-sheet col">
                    <h4>Attendance Sheet</h4>
                  </div>
                  <div className="EMP-all-employees-sheet col">
                    <div className="EMP-home-icon-sheet">
                      {" "}
                      <BiHomeAlt className="EMP-home-icon-header-sheet" />{" "}
                    </div>
                    <div className="EMP-arrow-icon-sheet">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="EMP-employee-text-sheet">
                      <p>Attendance</p>
                    </div>
                    <div className="EMP-arrow-icon-sheet">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="EMP-all-employee-text-sheet">
                      <p>Attendance Sheet</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="EMP-body-part-outer-sheet">
                <div className="EMP-table-in-all-emplyee-sheet">
                  <div className="EMP-attandance-sheet-head">
                    <h2>Attendance Sheet</h2>
                  </div>

                  <div className="calender-in-header row ">
                    <div className="col-4">
                      {" "}
                      <div className="EMP-flash-floating-sheet col">
                        <label htmlFor=""> Choose start date </label>
                        <input
                          type="date"
                          name=""
                          id=""
                          onChange={(e) =>
                            setSearchAttendance({
                              ...searchAttendance,
                              startDate: e?.target?.value,
                            })
                          }
                        />
                      </div>{" "}
                    </div>
                    <div className="col-4">
                      {" "}
                      <div className="flash-floating-sheet col">
                        <label htmlFor=""> Choose end date </label>
                        <input
                          type="date"
                          name=""
                          id=""
                          onChange={(e) =>
                            setSearchAttendance({
                              ...searchAttendance,
                              endDate: e?.target?.value,
                            })
                          }
                        />
                      </div>{" "}
                    </div>
                    <div className=" EMP-search-button-sheet col-4">
                      {" "}
                      <button onClick={() => handleAttendanceSearch()}>
                        Search
                      </button>{" "}
                    </div>
                  </div>
                  <div className="EMP-table-all-content-sheet">
                    <table className="EMP-table-main-class">
                      <thead className="EMP-thead-main-class">
                        <tr>
                          <th>Employee Name</th>
                          {monthResultAdm?.map((item, index) => (
                            <td>{new Date(item?.Date)?.getDate()}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="EMP-tbody-main-class">
                        <tr>
                          <td className="input-class-in-td">
                            {" "}
                            <img
                              src={tableimg}
                              alt=""
                              className="image-in-table-td"
                            />
                            <h6>{monthResultAdm?.[0]?.User?.UserName}</h6>
                          </td>
                          {monthResultAdm?.map((item, index) => (
                            <td>
                              {item?.InTime && item?.OutTime ? (
                                <IoIosCheckmarkCircleOutline className="EMP-green-tik-sheet" />
                              ) : new Date(item?.Date)?.getDay() === 0 ||
                                new Date(item?.Date)?.getDay() === 6 ? (
                                <FaStar className="EMP-yellow-star-sheet" />
                              ) : (
                                <IoIosCloseCircleOutline className="EMP-cross-circle-sheet" />
                              )}
                            </td>
                          ))}
                          {/* {leftDays?.map((item , index)=>(
                            <td> - </td>
                          ))} */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="main-container-of-all-employees-page-new-sheet">
            <div className="EMP-inner-container-of-all-employees-page ">
                <div className="header-of-employees-page row">
                  <div className="EMP-all-employee-sheet col">
                    <h4>Attendance Sheet</h4>
                  </div>
                  <div className="EMP-all-employees-sheet col">
                    <div className="EMP-home-icon-sheet">
                      {" "}
                      <BiHomeAlt className="EMP-home-icon-header-sheet" />{" "}
                    </div>
                    <div className="EMP-arrow-icon-sheet">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="EMP-employee-text-sheet">
                      <p>Attendance</p>
                    </div>
                    <div className="EMP-arrow-icon-sheet">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="EMP-all-employee-text-sheet">
                      <p>Attendance Sheet</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="EMP-body-part-outer-sheet">
                <div className="EMP-table-in-all-emplyee-sheet">
                  <div className="EMP-attandance-sheet-head">
                    <h2>Attendance Sheet</h2>
                  </div>

                  <div className="calender-in-header row ">
                    <div className="col-4">
                      {" "}
                      <div className="EMP-flash-floating-sheet col">
                        <label htmlFor=""> Choose start date </label>
                        <input
                          type="date"
                          name=""
                          id=""
                          onChange={(e) =>
                            setSearchAttendance({
                              ...searchAttendance,
                              startDate: e?.target?.value,
                            })
                          }
                        />
                      </div>{" "}
                    </div>
                    <div className="col-4">
                      {" "}
                      <div className="flash-floating-sheet col">
                        <label htmlFor=""> Choose end date </label>
                        <input
                          type="date"
                          name=""
                          id=""
                          onChange={(e) =>
                            setSearchAttendance({
                              ...searchAttendance,
                              endDate: e?.target?.value,
                            })
                          }
                        />
                      </div>{" "}
                    </div>
                    <div className=" EMP-search-button-sheet col-4">
                      {" "}
                      <button onClick={() => handleAttendanceSearch()}>
                        Search
                      </button>{" "}
                    </div>
                  </div>
                  <div className="EMP-table-all-content-sheet">
                    <table className="EMP-table-main-class">
                      <thead className="EMP-thead-main-class">
                        <tr>
                          <th>Employee Name</th>
                          {monthResultAdm?.map((item, index) => (
                            <td>{new Date(item?.Date)?.getDate()}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="EMP-tbody-main-class">
                        <tr>
                          <td className="input-class-in-td">
                            {" "}
                            <img
                              src={tableimg}
                              alt=""
                              className="image-in-table-td"
                            />
                            <h6>{monthResultAdm?.[0]?.User?.UserName}</h6>
                          </td>
                          {monthResultAdm?.map((item, index) => (
                            <td>
                              {item?.InTime && item?.OutTime ? (
                                <IoIosCheckmarkCircleOutline className="EMP-green-tik-sheet" />
                              ) : new Date(item?.Date)?.getDay() === 0 ||
                                new Date(item?.Date)?.getDay() === 6 ? (
                                <FaStar className="EMP-yellow-star-sheet" />
                              ) : (
                                <IoIosCloseCircleOutline className="EMP-cross-circle-sheet" />
                              )}
                            </td>
                          ))}
                          {/* {leftDays?.map((item , index)=>(
                            <td> - </td>
                          ))} */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AttendanceSheet;