import React, { useEffect, useState } from 'react'
import "../../Dashboard/AdmDashboard/Dashboard.css"
import { BiHomeAlt } from "react-icons/bi";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa6";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaBriefcase } from "react-icons/fa";
import { HiUsers } from "react-icons/hi2";
import { FaGlobe } from "react-icons/fa";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import axios from "axios";
import { Apiurl, GetToken } from '../../../Utils/utils';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Loader from '../../loader/loader';
import { useNavigate } from 'react-router-dom';
const AdmDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [attendanceData, setAttendanceData] = useState([]);
  const [pendingLeavesData, setPendingLeavesData] = useState('');
  const LoginToken = GetToken();
const navigate=useNavigate()
  // retrieve attendance
  const AttendanceAll = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "authorization": `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/GetToday'sAttendance`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setAttendanceData(data?.Data);
      })
      .catch(() => {
        setLoading(false);
      })
  }
  // retrieve pending leaves
  const PendingLeaves = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "authorization": `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/PendingLeaves`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setPendingLeavesData(data?.Result);
      })
      .catch(() => {
        setLoading(false);
      })
  }
  // get percentage
  const todayPresentPercentage = (attendanceData?.Present / attendanceData?.Attendance?.length) * 100;
  const leaveTodayPercentage = (attendanceData?.Leave / attendanceData?.Attendance?.length) * 100;
  const lateleaveTodayPercentage = (attendanceData?.Lateleave / attendanceData?.Attendance?.length) * 100;
  useEffect(() => {
    AttendanceAll();
    PendingLeaves();
  }, [])
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className='d-flex'>
        <Sidebar showSidebar={showSidebar} />
        {showSidebar ? <>
          <section className='dashboard-main-container'>
            <div className='all-icons'>
              <div>
                <p className='DASHBOARD-MAIN-HEADING'>Dashboard </p>
              </div>
              <div className='dashboiard-inner-header'>
                <BiHomeAlt className='home-icon-header-inn' />
                <MdKeyboardArrowRight />
                <p>Home </p>
                <MdKeyboardArrowRight />
                <p>Dashboard </p>
              </div>
            </div>
            <div>
            </div>
            <div>
              <div className='dashboard-allcards-flex-container'>
                {/*FIRST-CARD/ */}
                <div className='dashboard-card' onClick={()=>navigate("/Adm/Attendance/TodaysAttendance",{state:"present"})}>
                  <div className='dashboard-img-icon'><FaBriefcase /></div>
                  <p className='dashboard-1st-card-heading'>
                    Today’s attendance <span>(no. of present)</span></p>
                  <p className='dashboard-number-heading'>{attendanceData?.Present ? attendanceData?.Present : '-'}</p>
                  <div>
                    <div className='progress-bar-outter'>
                      <div className={`inner-progressbar`} style={{ width: `${todayPresentPercentage}%` }}>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-card-bottem-content'>
                    <FaArrowUp />
                    <p className=''> {attendanceData?.Present} Total no of present</p>
                    {/* </div> */}
                  </div>
                  {/* <div className='dashboard-card-img'>
              </div> */}
                </div>
                {/*SECOND--CARD/ */}
                <div className='dashboard-SECOND-card' onClick={()=>navigate("/Adm/Attendance/TodaysAttendance",{state:"absent"})}>
                  <div className='dashboard-img-icon'><HiUsers />
                  </div>
                  <p className='dashboard-1st-card-heading'>
                    Total no of leaves (Today)
                  </p>
                  <p className='dashboard-number-heading'>{attendanceData?.Leave ? attendanceData?.Leave : '-'}
                  </p>
                  <div>
                    <div className='progress-bar-outter'>
                      <div className='inner-progressbar-second' style={{ width: `${leaveTodayPercentage}%` }}>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-card-bottem-content'>
                    <FaArrowUp />
                    <p className=''> {attendanceData?.Leave} Total no of leaves (Today)</p>
                  </div>
                </div>
                {/*SECOND- LAST-CARD/ */}
                <div className='dashboard-card-second-last' onClick={()=>navigate("/Adm/Attendance/TodaysAttendance",{state:"late"})}>
                  <div className='dashboard-img-icon'>   <FaGlobe />
                  </div>
                  <p className='dashboard-1st-card-heading'>
                    Late leaves (Today)
                  </p>
                  <p className='dashboard-number-heading'>{attendanceData?.Lateleave ? attendanceData?.Lateleave : '-'}
                  </p>
                  <div>
                    <div className='progress-bar-outter-second-last'>
                      <div className='inner-progressbar-second-last' style={{ width: `${lateleaveTodayPercentage}%` }}>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-card-bottem-content'>
                    <FaArrowUp />
                    <p className=''> {attendanceData?.Lateleave} Late leaves (Today)</p>
                  </div>
                  <div className='dashboard-card-img-second-last'>
                  </div>
                </div>
                {/* LAST-CARD/ */}
                <div className='dashboard-card-last'>
                  <div className='dashboard-img-icon'>   <FaRegMoneyBillAlt />
                  </div>                    <p className='dashboard-1st-card-heading'>
                    Total leaves pending for approval</p>
                  <p className='dashboard-number-heading'>{pendingLeavesData ? pendingLeavesData : '-'}</p>
                  <div>
                    <div className='progress-bar-outter-last'>
                      <div className='inner-progressbar-blue'>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-card-bottem-content'>
                    <FaArrowDown />
                    <p className=''> {pendingLeavesData} Total leaves pending for approval</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </> : <>
          <section className='dashboard-main-container-new'>
            <div className='all-icons'>
              <div>
                <p className='DASHBOARD-MAIN-HEADING'>Dashboard </p>
              </div>
              <div className='dashboiard-inner-header'>
                <BiHomeAlt className='home-icon-header-inn' />
                <MdKeyboardArrowRight />
                <p>Home </p>
                <MdKeyboardArrowRight />
                <p>Dashboard </p>
              </div>
            </div>
            <div>
            </div>
            <div>
              <div className='dashboard-allcards-flex-container'>
                {/*FIRST-CARD/ */}
                <div className='dashboard-card' onClick={()=>navigate("/Adm/Attendance/TodaysAttendance",{state:"present"})}>
                  <div className='dashboard-img-icon'><FaBriefcase /></div>
                  <p className='dashboard-1st-card-heading'>
                    Today’s attendance <span>(no. of present)</span></p>
                  <p className='dashboard-number-heading'>{attendanceData?.Present ? attendanceData?.Present : '-'}</p>
                  <div>
                    <div className='progress-bar-outter'>
                      <div className={`inner-progressbar`} style={{ width: `${todayPresentPercentage}%` }}>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-card-bottem-content'>
                    <FaArrowUp />
                    <p className=''> {attendanceData?.Present} Total no of present</p>
                    {/* </div> */}
                  </div>
                  {/* <div className='dashboard-card-img'>
              </div> */}
                </div>
                {/*SECOND--CARD/ */}
                <div className='dashboard-SECOND-card' onClick={()=>navigate("/Adm/Attendance/TodaysAttendance",{state:"absent"})}>
                  <div className='dashboard-img-icon'><HiUsers />
                  </div>
                  <p className='dashboard-1st-card-heading'>
                    Total no of leaves (Today)
                  </p>
                  <p className='dashboard-number-heading'>{attendanceData?.Leave ? attendanceData?.Leave : '-'}
                  </p>
                  <div>
                    <div className='progress-bar-outter'>
                      <div className='inner-progressbar-second' style={{ width: `${leaveTodayPercentage}%` }}>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-card-bottem-content'>
                    <FaArrowUp />
                    <p className=''> {attendanceData?.Leave} Total no of leaves (Today)</p>
                  </div>
                </div>
                {/*SECOND- LAST-CARD/ */}
                <div className='dashboard-card-second-last' onClick={()=>navigate("/Adm/Attendance/TodaysAttendance",{state:"late"})}>
                  <div className='dashboard-img-icon'>   <FaGlobe />
                  </div>
                  <p className='dashboard-1st-card-heading'>
                    Late leaves (Today)
                  </p>
                  <p className='dashboard-number-heading'>{attendanceData?.Lateleave ? attendanceData?.Lateleave : '-'}
                  </p>
                  <div>
                    <div className='progress-bar-outter-second-last'>
                      <div className='inner-progressbar-second-last' style={{ width: `${lateleaveTodayPercentage}%` }}>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-card-bottem-content'>
                    <FaArrowUp />
                    <p className=''> {attendanceData?.Lateleave} Late leaves (Today)</p>
                  </div>
                  <div className='dashboard-card-img-second-last'>
                  </div>
                </div>
                {/* LAST-CARD/ */}
                <div className='dashboard-card-last'>
                  <div className='dashboard-img-icon'>   <FaRegMoneyBillAlt />
                  </div>                    <p className='dashboard-1st-card-heading'>
                    Total leaves pending for approval</p>
                  <p className='dashboard-number-heading'>{pendingLeavesData ? pendingLeavesData : '-'}</p>
                  <div>
                    <div className='progress-bar-outter-last'>
                      <div className='inner-progressbar-blue'>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-card-bottem-content'>
                    <FaArrowDown />
                    <p className=''> {pendingLeavesData} Total leaves pending for approval</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
        }
      </div>
    </>
  )
}
export default AdmDashboard