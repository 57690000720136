export const Apiurl =()=>{
    // return 'http://198.12.253.79:2312/api'
    return 'https://attendanceapi.elitewebdemo.com/api'
}
// clear storage
export const RemoveUserSession = () => {
    localStorage.clear();
}
// set forgotemail
export const forgotemail = (forgotemail) => {
    localStorage.setItem('forgotemail', forgotemail);
}
// get forgotemail
export const GetForgotemail = () => {
    return localStorage.getItem('forgotemail') || null;
}
// set token
export const Token = (token) => {
    localStorage.setItem('token', token);
}
// get token
export const GetToken = () => {
    return localStorage.getItem('token') || null
}
//set Login role
export const SetLoginRole = (value) => {
    localStorage.setItem('LoginRole', value);
}
// get login role
 
export const GetLoginRole = () => {
    return localStorage.getItem('LoginRole') || null;
}