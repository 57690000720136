import React, { useEffect, useState } from "react";
import "../EMPAttendance/EMPattendance.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import { Apiurl, GetToken, RemoveUserSession } from "../../../Utils/utils";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/loader/loader";
import { useNavigate } from "react-router-dom";
import fullday from "../../../Assets/Images/Present.png";
import holiday from "../../../Assets/Images/SatSun.png";
import leave from "../../../Assets/Images/FullDayLeave.png";
import shortleave from "../../../Assets/Images/shortleaveicon.png";
import HalfDayLeave from "../../../Assets/Images/HalfDayLeave.png";
import Properholiday from "../../../Assets/Images/Holiday.png";

const EMPattendance = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeButton, setActiveButton] = useState();
  const [monthResult, setMonthResult] = useState();
  const [searchAttendance, setSearchAttendance] = useState({
    startDate: "",
    endDate: "",
    dayscount: "",
  });
  const handleClick = (index) => {
    setLoading(true);
    const data = {
      startDate: "",
      endDate: "",
      dayscount: index,
    };
    handleAttendanceSearch(data);
    setActiveButton(index);
  };
  const handleAttendanceSearch = (e) => {
    setLoading(true);
    const LoginToken = GetToken();
    const data = {
      startDate: e?.startDate || "",
      endDate: e?.endDate || "",
      dayscount: e?.dayscount || "",
    };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Attendence/GetAttendanceByDateRange`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        const newData = data?.Result?.reduce((result, currentItem) => {
          const date = new Date(currentItem.Date);
          const month = date.getMonth() + 1;
          result[month - 1] = result[month - 1] || [];
          result[month - 1].push(currentItem);
          return result;
        }, []);
        const finalData = newData?.filter((item) => item?.length > 0);

        finalData.sort((a, b) => {
          const monthA = new Date(a[0].Date).getMonth();
          const monthB = new Date(b[0].Date).getMonth();
          const dayA = new Date(a[0].Date).getDate();
          const dayB = new Date(b[0].Date).getDate();

          if (monthA === monthB) {
            return dayB - dayA;
          }

          const currentDate = new Date();
          const currentMonth = currentDate.getMonth();
          if (monthA === currentMonth) {
            return -1;
          } else if (monthB === currentMonth) {
            return 1;
          }

          return monthB - monthA;
        });

        setMonthResult(finalData);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        if (
          error?.message === "Network Error" &&
          error?.code === "ERR_NETWORK"
        ) {
          toast.error("Your Session has been expired");
          RemoveUserSession();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    if (searchAttendance?.startDate && searchAttendance?.endDate) {
      setActiveButton(1);
      const data = {
        startDate: searchAttendance?.startDate,
        endDate: searchAttendance?.endDate,
        dayscount: "",
      };
      handleAttendanceSearch(data);
    } else {
      handleAttendanceSearch();
    }
  }, [searchAttendance]);
  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div>
      {loading ? <Loader /> : null}
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <div
          className={
            showSidebar
              ? "EMP-main-container-of-all-employees-sheet"
              : "EMP-main-container-of-all-employees-page-new-sheet"
          }
        >
          <div className="EMP-inner-container-of-all-employees-page ">
            <div className="header-of-employees-page row">
              <div className="EMP-all-employee-sheet col">
                <h4>Attendance Sheet</h4>
              </div>
              <div className="EMP-all-employees-sheet col">
                <div className="EMP-home-icon-sheet">
                  {" "}
                  <BiHomeAlt className="EMP-home-icon-header-sheet" />{" "}
                </div>
                <div className="EMP-arrow-icon-sheet">
                  <MdKeyboardArrowRight />
                </div>
                <div className="EMP-employee-text-sheet">
                  <p>Attendance</p>
                </div>
                <div className="EMP-arrow-icon-sheet">
                  <MdKeyboardArrowRight />
                </div>
                <div className="EMP-all-employee-text-sheet">
                  <p>Attendance Sheet</p>
                </div>
              </div>
            </div>
          </div>
          <div className="EMP-body-part-outer-sheet">
            <div className="EMP-table-in-all-emplyee-sheet">
              <div className="EMP-attandance-sheet-head">
                <h2>Attendance Sheet</h2>
              </div>
              <div className="EMP-search-filter-all-button">
                <button
                  className={`EMP-search-Filter-sheet-unactive ${
                    activeButton === 7 ? "active" : ""
                  }`}
                  onClick={() => handleClick(7)}
                  style={{
                    backgroundColor: activeButton === 7 ? "#3f51b5" : "#ffffff",
                    color: activeButton === 7 ? "#ffffff" : "#171718",
                  }}
                >
                  Last 7 Days
                </button>
                <button
                  className={`EMP-search-Filter-sheet-unactive ${
                    activeButton === 30 ? "active" : ""
                  }`}
                  onClick={() => handleClick(30)}
                  style={{
                    backgroundColor:
                      activeButton === 30 ? "#3f51b5" : "#ffffff",
                    color: activeButton === 30 ? "#ffffff" : "#171718",
                  }}
                >
                  Last 30 Days
                </button>
                <button
                  className={`EMP-search-Filter-sheet-unactive ${
                    activeButton === 90 ? "active" : ""
                  }`}
                  onClick={() => handleClick(90)}
                  style={{
                    backgroundColor:
                      activeButton === 90 ? "#3f51b5" : "#ffffff",
                    color: activeButton === 90 ? "#ffffff" : "#171718",
                  }}
                >
                  Last 3 Months
                </button>
                <button
                  className={`EMP-search-Filter-sheet-unactive ${
                    activeButton === 180 ? "active" : ""
                  }`}
                  onClick={() => handleClick(180)}
                  style={{
                    backgroundColor:
                      activeButton === 180 ? "#3f51b5" : "#ffffff",
                    color: activeButton === 180 ? "#ffffff" : "#171718",
                  }}
                >
                  Last 6 Months
                </button>
                <button
                  className={`EMP-search-Filter-sheet-unactive ${
                    activeButton === 365 ? "active" : ""
                  }`}
                  onClick={() => handleClick(365)}
                  style={{
                    backgroundColor:
                      activeButton === 365 ? "#3f51b5" : "#ffffff",
                    color: activeButton === 365 ? "#ffffff" : "#171718",
                  }}
                >
                  Last 12 Months
                </button>
              </div>
              <div className="calender-in-header row ">
                <div className="col-4">
                  {" "}
                  <div className="EMP-flash-floating-sheet col">
                    <label htmlFor=""> Choose start date </label>
                    <input
                      type="date"
                      name=""
                      id=""
                      value={searchAttendance?.startDate}
                      onChange={(e) =>
                        setSearchAttendance({
                          ...searchAttendance,
                          startDate: e?.target?.value,
                        })
                      }
                    />
                  </div>{" "}
                </div>
                <div className="col-4">
                  {" "}
                  <div className="flash-floating-sheet col">
                    <label htmlFor=""> Choose end date </label>
                    <input
                      type="date"
                      name=""
                      id=""
                      value={searchAttendance?.endDate}
                      min={searchAttendance?.startDate}
                      onChange={(e) =>
                        setSearchAttendance({
                          ...searchAttendance,
                          endDate: e?.target?.value,
                        })
                      }
                    />
                  </div>{" "}
                </div>
                {activeButton ||
                (searchAttendance?.startDate && searchAttendance?.endDate) ? (
                  <div className=" EMP-search-button-sheet col-4">
                    {" "}
                    <button
                      onClick={() => {
                        setSearchAttendance({
                          startDate: "",
                          endDate: "",
                          dayscount: "",
                        });
                        setActiveButton();
                        handleAttendanceSearch();
                      }}
                    >
                      Reset
                    </button>{" "}
                  </div>
                ) : null}
              </div>
              <div className="EMP-table-all-content-sheet">
                {monthResult?.map((e, index) => {
                  return (
                    <>
                      {/* <div className="months-heading-for-table"> <p>{monthsArray[new Date(e?.[0]?.Date)?.getMonth()]}</p> </div> */}
                      <div className="months-heading-for-table">
                        <p>
                          {`${
                            monthsArray[new Date(e?.[0]?.Date)?.getMonth()]
                          } ${new Date(e?.[0]?.Date)?.getFullYear()}`}
                        </p>
                      </div>
                      <table className="EMP-table-main-class">
                        <thead className="EMP-thead-main-class">
                          <tr className=" ewfef row"></tr>
                          <tr className="">
                            {/* <th>Employee Name</th> */}
                            <th className="day-and-date-label">
                              <p>Date</p>
                              <p>Day</p>
                            </th>
                            {e?.map((item, index) => (
                              <td key={index} className="date-and-day-data">
                                {new Date(item?.Date)?.toLocaleDateString(
                                  "en-US",
                                  { weekday: "short", day: "numeric" }
                                )}
                              </td>
                            ))}
                            {/* {e?.map((item, index) => (
                              <td key={index}>
                                {new Date(item?.Date)?.getDate()}
                              </td>
                            ))} */}
                          </tr>
                        </thead>
                        <tbody className="EMP-tbody-main-class">
                          <tr>
                            <td className="input-class-in-td-main ">
                              {/* <img
                                src={tableimg}
                                alt=""
                                className="image-in-table-td"
                              /> */}
                              {/* <h6>{e?.[0]?.User?.UserName}</h6>  */}
                              <p className="attendance-label">Attendance</p>
                              <p className="in-time-label">In time</p>
                              <p className="outtime-label">Out time</p>
                            </td>
                            {e?.map((item, index) => (
                              <td key={index}>
                               

                                <p className="star-for-attendance">
                                  
                                {item?.IsHoliday ? (
                                    <img
                                      className="for-leave"
                                      src={Properholiday}
                                      alt=""
                                    /> ) :
                                    
                                  item?.InTime && !item?.OutTime ? 
                                    (item?.InTime?.split(":")?.[0] >="10" && item?.InTime?.split(":")?.[1] >="15") && (item?.InTime?.split(":")?.[0] <"12" )  ? (
                                      <img
                                        className="for-short-leave"
                                        src={shortleave}
                                        alt=""
                                      />
                                    ) : item?.InTime > "12:00:00" ? (
                                      <img
                                        className="for-half-day"
                                        src={HalfDayLeave}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="your-custom-class-for-moon-icon"
                                        src={HalfDayLeave}
                                        alt=""
                                      />
                                    )
                                   : item?.InTime && item?.OutTime ? (
                                    (item?.InTime?.split(":")?.[0] >="10" && item?.InTime?.split(":")?.[1] >="15") && (item?.InTime?.split(":")?.[0] <"12" )  ? (
                                      <img
                                        className="for-short-leave"
                                        src={shortleave}
                                        alt=""
                                      />
                                    ) : 
                                    <img
                                      className="for-full-day"
                                      src={fullday}
                                      alt=""
                                    />
                                  ) : new Date(item?.Date)?.getDay() === 0 ||
                                    new Date(item?.Date)?.getDay() === 6 ? (
                                    <img
                                      className="for-holiday"
                                      src={holiday}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="for-leave"
                                      src={leave}
                                      alt=""
                                    />
                                  )}
                                </p>
                                <p className="intime-for-attendance">
                                  {item?.InTime
                                    ? item?.InTime.substring(0, 5)
                                    : "-"}
                                </p>
                                <p className="out-for-attendance">
                                  {item?.InTime && item?.OutTime
                                    ? item?.OutTime.substring(0, 5)
                                    : "-"}
                                </p>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EMPattendance;
