import React, { useState } from 'react'
import { ModalHeader } from 'react-bootstrap';
import { RxCross2 } from "react-icons/rx";
// import "../New EmployeesModal/NewEmployees.css"
import "../../Components/Modals/NewEmployeesModal.css"
import ModalHeaderImage from "../../Assets/Images/NewEmployees-profile.jpg";
import FloatingLabelInput from "../../Components/floatingLabel/FloatingLabel";
const NewEmployeesModal = () => {
    const [username, setUsername] = useState('');
    const [errorusername, setErrorusername] = useState(false);
    const [Department,setDepartment]=useState('')
    const [errorDepartment, setErrorDepartment] = useState(false);
     const[Role,setRole]=useState('')
     const [errorRole,setErrorRole]=useState(false)
     const [ Degree,setDegree]=useState("")
     const [ErrorDegree,setErrorDegree]=useState(false)
 const [Mobile ,setMobile] =useState("")
 const [ErrorMobile ,setErrorMobile] =useState(false)
 const [Email,setemail] =useState('')
 const [ErrorEmail,setErroremail] =useState('')
 const [Date ,setDate]=useState("")
 const [errorDate,setErrordate]=useState(false)
    
    const validate = () => {
        if (!username) {
          setErrorusername(true);
          // setErrorusername("userdata");
          return false;
        }
        if (!Department) {
            setErrorusername(true);
            return false;
          }
          if (!Department) {
            setErrorusername(true);
            return false;
          }
          if (! Degree) {
            setErrorDegree(true);
            return false;
          }
    }    
    const handleSave=()=>{
        if (!validate())
        alert(123)
    }
    return (
        <>
            <div className='new-employees-main'>
                <div className='new-employees-outter'>
                    <div className='new-employees-modal-header'>
                        <div className='new-employe-img'>
                            <img className='modal-img' src={ModalHeaderImage} alt="" />
                            <p>New Employees</p>
                        </div>
                        <div>
                            <RxCross2 />
                        </div>
                    </div>
                    <div className='new-employees-input-main-container'>
                        <div className='new-employee-all-inputs'>
                            <div className='new-employee-all-inputs-innere'>
                                <FloatingLabelInput
                                    label="Name*"
                                    onChange={(e) => setUsername(e?.target?.value)}
                                    value={username}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        setErrorusername("userdata");
                                      } else {
                                        setErrorusername("false");
                                      }
                                    }}
                                  />
                                  {errorusername === "userdata" && !username ? (
                                    <span className="contact_error-login">
                                      Username is required.
                                    </span>
                                  ) : null}    
                                    </div>
                            <div className='new-employee-all-inputs-innere'>
                                <FloatingLabelInput
                                    label="department*"
                                    onChange={(e) => setDepartment(e?.target?.value)}
                                    value={Department}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        setErrorDepartment("userdata");
                                      } else {
                                        setErrorDepartment("false");
                                      }
                                    }}
                                  />
                                  {errorDepartment === "userdata" && !Department ? (
                                    <span className="contact_error-login">
                                      Department is required.
                                    </span>
                                  ) : null}    
                                         </div>
                        </div>
                        <div className='new-employee-all-inputs'>
                            <div className='new-employee-all-inputs-innere'>
                                <FloatingLabelInput
                                    label="role*"
                                    onChange={(e) => setRole(e?.target?.value)}
                                    value={Role}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        setErrorRole("userdata");
                                      } else {
                                        setErrorRole("false");
                                      }
                                    }}
                                  />
                                  {errorRole === "userdata" && !Role ? (
                                    <span className="contact_error-login">
                                      Role is required.
                                    </span>
                                  ) : null} 
                                           </div>
                            <div className='new-employee-all-inputs-innere'>
                                <FloatingLabelInput
                                    label="Degree*"
                                    
                                    onChange={(e) => setDegree(e?.target?.value)}
                                    value={ Degree}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        setErrorDegree("userdata");
                                      } else {
                                        setErrorDegree("false");
                                      }
                                    }}
                                  />
                                  {ErrorDegree === "userdata" && ! Degree ? (
                                    <span className="contact_error-login">
                                       Degree is required.
                                    </span>
                                  ) : null}          
                                 </div>
                        </div>
                        <div className='new-employee-all-inputs'>
                            <div className='new-employee-all-inputs-innere'>
                                <FloatingLabelInput
                                    label=" Mobile*"
                                    onChange={(e) => setMobile(e?.target?.value)}
                                    value={ Mobile}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        setErrorMobile("userdata");
                                      } else {
                                        setErrorMobile("false");
                                      }
                                    }}
                                  />
                                  {ErrorMobile === "userdata" && ! Mobile ? (
                                    <span className="contact_error-login">
                                       Mobile is required.
                                    </span>
                                  ) : null}         
                                  </div>
                            <div className='new-employee-all-inputs-innere'>
                                <FloatingLabelInput
                                    label="Email*"
                                    onChange={(e) => setemail(e?.target?.value)}
                                    value={ Email}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        setErroremail("userdata");
                                      } else {
                                        setErroremail("false");
                                      }
                                    }}
                                  />
                                  {ErrorEmail === "userdata" && ! Email ? (
                                    <span className="contact_error-login">
                                       Mobile is required.
                                    </span>
                                  ) : null}                                          </div>
                        </div>
                        <div className='last-input-modal'>
                            <div className='new-employee-all-inputs-innere'>
                                <FloatingLabelInput
                                    label="Joining date*"
                                    onChange={(e) => setDate(e?.target?.value)}
                                    value={ Date}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        setErrordate("userdata");
                                      } else {
                                        setErrordate("false");
                                      }
                                    }}
                                  />
                                  {errorDate === "userdata" && ! Date ? (
                                    <span className="contact_error-login">
                                       joining date is required.
                                    </span>
                                  ) : null}          
                                
                                   </div>
                        </div>
                    </div>
                    <div className='modal-buttons'>
                        <button className='modal-save-button' onClick={handleSave}>Save</button>
                        <button className='modal-cancel-button'>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NewEmployeesModal