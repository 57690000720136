import React, { useState } from "react";
import "../ForgotPassword/ForgotPassword.css";
import kuberimgpassword from "../../../Assets/Images/forgotpass.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Apiurl } from "../../../Utils/utils";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import Loader from "../../../Components/loader/loader";

const ForgotPassword = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailforgot, setEmailforgot] = useState();
  const [error, setError] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  function isValidEmail(emailforgot) {
    return emailRegex.test(emailforgot);
  }

  const validate = () => {
    if (!emailforgot) {
      setError(true);
      return false;
    }
    if (!isValidEmail(emailforgot)) {
      toast.error("Please enter valid email");
      return false;
    }
    return true;
  };
  const handleLoginNavigate = () => {
    navigate("/");
  };

  const handleLoginSubmit = (e) => {
    e?.preventDefault();
    if (!validate()) return;
    setLoading(true);

    const data = {};
    data.Email = emailforgot;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/Account/ForgotPassword`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          setEmailforgot('');
          setError(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div className="full-page-main-forgot">
        <div className="main-container-of-login-page-forgot row-12">
          <div className="login-page-image-forgot col-6">
            <img src={kuberimgpassword} alt="" />
          </div>
          <div className="login-page-form-forgot col-6">
            <div className="inner-container-of-login-form-forgot">
              <h2 className="resetpass-forgotpage"> Reset Password </h2>
              <div className="need-a-accoount-forgot">
                <p>Let Us Help You</p>{" "}
              </div>
              <span className="enter-your-registered-email-address">
                {" "}
                Enter your registered email address.{" "}
              </span>
              <div>
                <div className="email-and-password-inputs-forgot">
                  <div>
                    <div className="username-content-forgot">
                      <FloatingLabelInput
                        label="Email*"
                        type="text"
                        value={emailforgot}
                        onChange={(e) => setEmailforgot(e.target.value)}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setError(true);
                          } else {
                            setError("false");
                          }
                        }}
                      />
                      {error && !emailforgot ? (
                        <span className="contact_error-forgot">
                          Email is required.
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="login-button-forgot">
                  <button
                    style={{
                      backgroundColor: emailforgot
                        ? "#3f51b5"
                        : "rgba(0, 0, 0, .12)",
                      color: emailforgot ? "#fff" : "rgba(0, 0, 0, .38)",
                    }}
                    onClick={handleLoginSubmit}
                  >
                    Reset My Password
                  </button>
                </div>
                <div className="login-in-fogot-password">
                  <a href="" onClick={handleLoginNavigate}>
                    {" "}
                    Login?{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;  