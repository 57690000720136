import React, { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BiHomeAlt } from "react-icons/bi";
import "../../EmployeeScreens/EMPLeavesRequest/EMPLeavesRequest.css";
import Select from "react-select";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import { Apiurl, GetToken, RemoveUserSession } from "../../../Utils/utils";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/loader/loader";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const EMPLeavesRequest = () => {
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 5);
  const LoginToken = GetToken();

  const LeaveType = [
    { value: "Casual_Leave", label: "Casual Leave", id: 0 },
    { value: "Sick_Leave", label: "Sick Leave", id: 1 },
  ];
  const test = LeaveType?.filter((e) => e?.id === 0);
  const [profileData, setProfileData] = useState([]);
  const navigate = useNavigate();
  const [minLeaveToDate, setMinLeaveToDate] = useState(null);
  const [errorName, setErrorName] = useState(false);
  const [errorLeaveType, setErrorLeaveType] = useState(false);
  const [errorLeaveFrom, setErrorLeaveFrom] = useState(false);
  const [errorLeaveTo, setErrorLeaveTo] = useState(false);
  const [errorNoOfDays, setErrorNoOfDays] = useState(false);
  const [errorReason, setErrorReason] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [data, setdata] = useState("");
  const [applyLeaves, setApplyLeaves] = useState({
    Name: "",
    LeaveType: "",
    LeaveFrom: "",
    LeaveTo: "",
    NoOfDays: "",
    Reason: "",
  });
  const location = useLocation();
  const leaveRequestData = location.state?.leaveRequestData;
  useEffect(() => {
    if (leaveRequestData) {
      const leaveType = LeaveType?.find(
        (i) => i?.id === leaveRequestData?.LeaveType
      );
      setApplyLeaves({
        LeaveType: leaveType,
        LeaveFrom: leaveRequestData?.StartDate?.split("T")[0],
        LeaveTo: leaveRequestData?.EndDate?.split("T")[0],
        NoOfDays: leaveRequestData?.NumberofDays,
        Reason: leaveRequestData?.Reason,
      });
    }
  }, [leaveRequestData]);
  useEffect(() => {
    if (applyLeaves?.LeaveFrom && applyLeaves?.LeaveTo) {
      const leaveStartDate = moment(applyLeaves?.LeaveFrom);
      const leaveEndDate = moment(applyLeaves?.LeaveTo);
      const duration = moment.duration(leaveEndDate.diff(leaveStartDate));
      const days = duration.asDays() + 1;
      setApplyLeaves({ ...applyLeaves, NoOfDays: days });
    }
  }, [applyLeaves?.LeaveFrom, applyLeaves?.LeaveTo]);
  const validate = () => {
    if (!applyLeaves?.LeaveType) {
      setErrorLeaveType("userLeaveType");
      return false;
    }
    if (!applyLeaves?.LeaveFrom) {
      setErrorLeaveFrom("userLeaveFrom");
      return false;
    }
    if (!applyLeaves?.LeaveTo) {
      setErrorLeaveTo("userLeaveTo");
      return false;
    }
    if (!applyLeaves?.NoOfDays) {
      setErrorNoOfDays("userNoOfdays");
      return false;
    }
    if (!applyLeaves?.Reason) {
      setErrorReason("userReason");
      return false;
    }
    return true;
  };
  const handleLeaveSubmit = () => {
    if (!validate()) return;
    setLoading(true);
    const LoginToken = GetToken();
    const data = {};
    data.Id = leaveRequestData?.Id;
    data.StartDate = applyLeaves?.LeaveFrom;
    data.EndDate = applyLeaves?.LeaveTo;
    data.Reason = applyLeaves?.Reason;
    data.NumberofDays = applyLeaves?.NoOfDays;
    data.LeaveType = applyLeaves?.LeaveType?.id;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Leave/ApplyLeave`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {  
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          setApplyLeaves({
            // Name: "",
            LeaveType: "",
            LeaveFrom: "",
            LeaveTo: "",
            NoOfDays: "",
            Reason: "",
          });
          // setErrorName(false);
          setErrorLeaveType(false);
          setErrorLeaveFrom(false);
          setErrorLeaveTo(false);
          setErrorNoOfDays(false);
          setErrorReason(false);
          navigate("/Emp/EMPLeaves");
        }
    setLoading(false);

      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        if (
          error?.message === "Network Error" &&
          error?.code === "ERR_NETWORK"
        ) {
          toast.error("Your Session has been expired");
          RemoveUserSession();
          navigate("/");
        }
      });
  };

  const handleLeaveTypeChange = (selectedOption) => {
    if (applyLeaves?.NoOfDays > 6 && selectedOption?.value === "Sick_Leave") {
      toast.warning("Sick Leave is not allowed for more than 6 days.");
      return;
    }

    setSelectedLeaveType(selectedOption);
    setApplyLeaves({ ...applyLeaves, LeaveType: selectedOption });
  };
  const handleCancelFields = () => {
    setApplyLeaves({
      LeaveType: "",
      LeaveFrom: "",
      LeaveTo: "",
      NoOfDays: "",
      Reason: "",
    });
    setErrorName(false);
    setErrorLeaveType(false);
    setErrorLeaveFrom(false);
    setErrorLeaveTo(false);
    setErrorNoOfDays(false);
    setErrorReason(false);
  };
  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    // Call GetProfile API to fetch user data
    GetProfile();
    return () => clearTimeout(timeout);
  }, []);
  const GetProfile = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Profile/GetProfile`,
    };
    axios(option)
      .then((response) => {
        const data = JSON.parse(response?.data?.data);
        setProfileData(data);
      })
      .catch((error) => {
        if (
          error?.message === "Network Error" &&
          error?.code === "ERR_NETWORK"
        ) {
          toast.error("Your Session has been expired");
          RemoveUserSession();
          navigate("/");
        }
      });
  };
  
  return (
    <>
      {loading ? <Loader /> : null}
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <>
          <section
            className={
              showSidebar
                ? "EMP-addEmloyees-section"
                : "EMP-addEmloyees-section-new"
            }
          >
            <div className="EMP-addEmloyees-all-icons">
              <div>
                <p className="MAIN-HEADING">
                  {leaveRequestData ? "Edit Leaves" : "Apply Leaves"}
                </p>
              </div>
              <div className="dashboiard-inner-header">
                <BiHomeAlt className="EMP-home-icon-header-inn" />
                <MdKeyboardArrowRight />
                <p> Leaves Management </p>
                <MdKeyboardArrowRight />
                <p>{leaveRequestData ? "Edit Leaves" : "Apply Leaves"}</p>
              </div>
            </div>
            <div className="EMP-Addemployee-main-container">
              <div className="EMP-Addemployee-outter-container">
                <div className="EMP-Addemployee-inner-container">
                  <div className="EMP-AddEmployee-heading">
                    <p>{leaveRequestData ? "Edit Leaves" : "Apply Leaves"}</p>
                  </div>
                  <div className="EMP-Addemployee-inner-box row">
                    <div className="Addemployee-inputs col-md12">
                      <label htmlFor="">Leave From</label>
                      <input
                        value={applyLeaves.LeaveFrom}
                        type="date"
                        onChange={(e) => {
                          const selectedDate = e.target.value;
                          setApplyLeaves({
                            ...applyLeaves,
                            LeaveFrom: selectedDate,
                            LeaveTo:"",
                            NoOfDays:""
                          });
                          setMinLeaveToDate(selectedDate);
                        }}
                        min={currentDate.toISOString().split("T")[0]}
                      />
                      {errorLeaveFrom === "userLeaveFrom" &&
                      !applyLeaves?.LeaveFrom ? (
                        <span className="contact_error-dob">
                          Leave From is required.
                        </span>
                      ) : null}
                    </div>
                   
                    <div className="Addemployee-inputs col-md12">
                      <label htmlFor="">Leave To</label>
                      <input
                        type="date"
                        value={applyLeaves.LeaveTo}
                        onChange={(e) =>
                          setApplyLeaves({
                            ...applyLeaves,
                            LeaveTo: e?.target?.value,
                          })
                        }
                        min={
                          minLeaveToDate ||
                          currentDate.toISOString().split("T")[0]
                        }
                      />
                      {errorLeaveTo === "userLeaveTo" &&
                      !applyLeaves?.LeaveTo ? (
                        <span className="contact_error-dob">
                          Leave To is required.
                        </span>
                      ) : null}
                    </div>
                    {/* {
                        applyLeaves?.LeaveFrom === '' && applyLeaves?.LeaveTo === '' ? null : */}
                    <div className="Addemployee-inputs col-md12">
                      <label htmlFor="">No of Days</label>
                      <input
                        className="no-of-days-cursor"
                        type="number"
                        value={applyLeaves?.NoOfDays}
                        onChange={(e) =>
                          setApplyLeaves({
                            ...applyLeaves,
                            NoOfDays: e?.target?.value,
                          })
                        }
                        onBlur={(e) => {
                          if (!e?.target?.value) {
                            setErrorNoOfDays("userNoOfdays");
                          } else {
                            setErrorNoOfDays("false");
                          }
                        }}
                        disabled
                      />
                      {errorNoOfDays === "userNoOfdays" &&
                      !applyLeaves?.NoOfDays ? (
                        <span className="contact_error-dob">
                          No Of Days is required.
                        </span>
                      ) : null}
                    </div>
                  
                    <div className="Addemployee-inputs col-md12">
                      <div className="addemp-react-select-input">
                        <label htmlFor="">Leave Type</label>
                        <Select
                          classNamePrefix="EMP-leave-request-input"
                          placeholder="Select Leave Type"
                          value={applyLeaves?.LeaveType}
                          onChange={handleLeaveTypeChange}
                          options={LeaveType?.filter(
                            (option) =>
                              !(
                                applyLeaves?.NoOfDays > 6 &&
                                option.value === "Sick_Leave"
                              )
                          )}
                          getOptionLabel={(options) => options?.label}
                          getOptionValue={(options) => options?.id}
                          onBlur={(e) => {
                            if (!e?.target?.value) {
                              setErrorLeaveType("userLeaveType");
                            } else {
                              setErrorLeaveType("false");
                            }
                          }}
                        />
                        {errorLeaveType === "userLeaveType" &&
                        !applyLeaves?.LeaveType ? (
                          <span className="contact_error-Addemployee">
                            Leave Type is required.
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="Addemployee-inputs col-md12">
                      <label htmlFor="">Reason</label>
                      <textarea
                        className="reason-input"
                        name=""
                        id=""
                        cols="30"
                        rows="8"
                        // placeholder="Reason"
                        value={applyLeaves?.Reason}
                        onChange={(e) =>
                          setApplyLeaves({
                            ...applyLeaves,
                            Reason: e?.target?.value,
                          })
                        }
                        onBlur={(e) => {
                          if (!e?.target?.value) {
                            setErrorReason("userReason");
                          } else {
                            setErrorReason("false");
                          }
                        }}
                      />
                      {errorReason === "userReason" && !applyLeaves?.Reason ? (
                        <span className="contact_error-dob">
                          Reason is required.
                        </span>
                      ) : null}
                    </div>
                    <div className="Addemployee-buttons buttons-for-leave-apply">
                      <button
                        className="Addemployee-submit-button-apply-leave"
                        style={{
                          backgroundColor:
                            // applyLeaves?.Name &&
                            applyLeaves?.LeaveType &&
                            applyLeaves?.LeaveFrom &&
                            applyLeaves?.LeaveTo &&
                            applyLeaves?.NoOfDays &&
                            applyLeaves?.Reason
                              ? "#3f51b5"
                              : "rgba(0, 0, 0, .12)",
                          color:
                            // applyLeaves?.Name &&
                            applyLeaves?.LeaveType &&
                            applyLeaves?.LeaveFrom &&
                            applyLeaves?.LeaveTo &&
                            applyLeaves?.NoOfDays &&
                            applyLeaves?.Reason
                              ? "#fff"
                              : "rgba(0, 0, 0, .38)",
                        }}
                        onClick={() => handleLeaveSubmit()}
                      >
                        {leaveRequestData ? "Update" : "Submit"}
                      </button>
                      <button
                        className="Addemployee-Cancel-button-apply-leave"
                        style={{
                          backgroundColor:
                            !applyLeaves.LeaveType &&
                            !applyLeaves.LeaveFrom &&
                            !applyLeaves.LeaveTo &&
                            !applyLeaves.Reason
                              ? "rgba(0, 0, 0, 0.12)"
                              : "#F44336",
                          color:
                            // !applyLeaves.Name &&
                            !applyLeaves.LeaveType &&
                            !applyLeaves.LeaveFrom &&
                            // !applyLeaves.LeaveTo &&
                            !applyLeaves.NoOfDays &&
                            !applyLeaves.Reason
                              ? "rgba(0, 0, 0, 0.38)"
                              : "#ffffff",
                        }}
                        onClick={() => handleCancelFields()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </div>
    </>
  );
};
export default EMPLeavesRequest;

