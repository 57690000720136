import React, { useState } from "react";
import tableimg from "../../../Assets/Images/tableimage.jpg";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { GrRefresh } from "react-icons/gr";
import "../../../Pages/AdminScreens/AddHolidays/AddHolidays.css";
import excelimg from "../../../Assets/Images/excelsheetimg.png";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import { toast } from "react-toastify";
import { Apiurl, GetToken } from "../../../Utils/utils";
import axios from "axios";

const AddHolidays = () => {
  const [addHoliday, setAddHoliday] = useState({
    HolidayName: "",
    Date: "",
    Day: "",
  });
  const AnnouncementToken = GetToken();
  const [errorDesignation, setErrorDesignation] = useState(false);
  const [errorHolidayDay,setErrorHolidayDay] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);

  const validateFields = () => {
    if (!addHoliday?.HolidayName) {
      setErrorDesignation("holidayname");
      return false
    }
    if (!addHoliday?.Date) {
      toast.error("Please Select Holiday Date");
      return false
    }
    if (!addHoliday?.Day) {
      setErrorHolidayDay("holidayDay");
      return false;
    }
    return true;
  }
  const handleAddHolday = () => {
    if (!validateFields()) return
    const data = {};
    data.HolidayName = addHoliday?.HolidayName;
    data.FromDate = addHoliday?.FromDate;
    data.ToDate = addHoliday?.ToDate;
    data.Details = addHoliday?.Details;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${AnnouncementToken}`,
      },
      url: `${Apiurl()}/Holiday/SaveHoliday`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        if (e?.status === 200) {
          toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />

        {showSidebar ? (
          <>
            <div className="main-container-of-all-employees-Attendance-AddHoliday">
              <div className="inner-container-of-all-employees-page-AddHoliday ">
                <div className="header-of-employees-page-AddHoliday row">
                  <div className="all-employee-Attendance-AddHoliday col">
                    <h4>Add Holiday</h4>
                  </div>
                  <div className="all-employees-Attendance-AddHoliday col">
                    <div className="home-icon-Attendance-AddHoliday">
                      {" "}
                      <BiHomeAlt className="home-icon-header-Attendance-AddHoliday" />{" "}
                    </div>
                    <div className="arrow-icon-Attendance-AddHoliday">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-Attendance-AddHoliday">
                      <p>Holiday </p>
                    </div>
                    <div className="arrow-icon-Attendance-AddHoliday">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-Attendance-AddHoliday">
                      <p>Add Holiday</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body-part-outer-Attendance-AddHoliday">
                <div className="table-in-all-emplyee-Attendance-AddHoliday">
                  <div className="new-holiday-heading-add-AddHoliday padding-set">
                    <label htmlFor="" className="labels-add-holiday">Name</label>
                  </div>
                  <div>
                    {" "}
                    <div className="Addemployee-inputs-holidays ">
                      <FloatingLabelInput
                        label="Holiday Name*"
                        value={addHoliday?.HolidayName}
                        onChange={(e) =>
                          setAddHoliday({
                            ...addHoliday,
                            HolidayName: e?.target?.value,
                          })
                        }
                        onBlur={(e) => {
                          if (!e?.target?.value) {
                            setErrorDesignation("holidayname");
                          } else {
                            setErrorDesignation(false);
                          }
                        }}
                      />
                      {errorDesignation === "holidayname" &&
                        !addHoliday?.HolidayName ? (
                        <span className="contact_error-signup">
                          Holiday Name is required.
                        </span>
                      ) : null}
                    </div>
                    <div className="Addemployee-calender-holidays row">
                      <div className="holiday-date-from  col-6">
                        <label htmlFor="">Holiday Date</label>
                        <input
                          type="date"
                          value={addHoliday?.Date}
                          onChange={(e) =>
                            setAddHoliday({
                              ...addHoliday,
                              Date: e?.target?.value,
                            })
                          }
                        />
                      </div>
                      {/* <div className="col-6">
                        <label htmlFor="">Holiday Date To</label>
                        <input type="date"
                          onChange={(e) =>
                            setAddHoliday({
                              ...addHoliday,
                              ToDate: e?.target?.value,
                            })
                          }
                        />
                      </div> */}
                      <div className="Addemployee-inputs-holidays col-6">
                        <FloatingLabelInput
                          label="Day*"
                          value={addHoliday?.Day}
                          onChange={(e) =>
                            setAddHoliday({
                              ...addHoliday,
                              Day: e?.target?.value,
                            })
                          }
                          onBlur={(e) => {
                            if (!e?.target?.value) {
                              setErrorHolidayDay("holidayDay");
                            } else {
                              setErrorHolidayDay(false);
                            }
                          }}
                        />
                        {errorHolidayDay === "holidayDay" &&
                          !addHoliday?.Day ? (
                          <span className="contact_error-signup">
                            Holiday Day is required.
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className=" textarea-part-for-details">
                      <label htmlFor="">Details</label>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        className="Addemployee-textarea-holidays"
                        onChange={(e) =>
                          setAddHoliday({
                            ...addHoliday,
                            Details: e?.target?.value,
                          })
                        }
                      />
                    </div> */}
                  </div>
                  <div className="save-button-in-setting-annoucement">
                    <button
                      className="save-button-in-setting-annoucement-button-one-add-holiday"
                      onClick={() => handleAddHolday()}
                    >
                      Submit
                    </button>
                    <button className="save-button-in-setting-annoucement-button-two-add-holiday  ">
                      Cancel
                    </button>
                  </div>{" "}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="main-container-of-all-employees-page-new-Attendance-management">

            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddHolidays;
