import React, { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BiHomeAlt } from "react-icons/bi";
import "../AddEmployee/AddEmployee.css";
import Select from "react-select";
import { Apiurl, GetToken, Token, userId } from "../../../Utils/utils";
import axios from "axios";
import { toast } from "react-toastify";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Loader from '../../../Components/loader/loader';

const AddEmployee = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const comingData = location.state;
  const [addEmloyee, setAddEmloyee] = useState({
    Name: "",
    Address: "",
    Email: "",
    Phone: "",
    EmergencyContact: "",
    Gender: "",
    DateOfBirth: "",
    DateOfJoining: "",
    DateOfLeaving: "",
    Department: "",
    Designation: "",
    TeamLead: "",
    UserRole: "",
    UploardImage: "",
    Password: "",
    ImageExtension: "",
    ImageName: ""
  });
  const [departmentData, setDepartmentData] = useState([]);
  const [saveDesignationOfDepartment, setSaveDesignationOfDepartment] = useState('');
  const [teamLeadData, setTeamLeadData] = useState([]);
  const [isFinalTeamLeadData, setIsFinalTeamLeadData] = useState('');
  const [DesignationData, setDesignationData] = useState([]);
  const [userRoleData, setUserRoleData] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0];
  const [showSidebar, setShowSidebar] = useState(true);
  const fileInputRef = useRef(null);
  const [departmentId, setDepartmentId] = useState("");
  const [designationId, setDesignationId] = useState("");
  const handleDepartmentChange = (selectedOption) => {
    setDepartmentId(selectedOption?.value);
    setAddEmloyee({ ...addEmloyee, Department: selectedOption })
  };
  const handleDesignationData = (selectedOption) => {
    setAddEmloyee({ ...addEmloyee, Designation: selectedOption })
    setSaveDesignationOfDepartment(selectedOption)
    setDesignationId(selectedOption?.Id);
  };
  const [finalUserRole, setFinalUserRole] = useState();
  const [userRoleError, setUserRoleError] = useState();
  const handleTeamLeadData = (e) => {
    setIsFinalTeamLeadData(e);
    setAddEmloyee({ ...addEmloyee, TeamLead: e })
  }
  const handleUserRole = (e) => {
    setFinalUserRole(e)
    setAddEmloyee({ ...addEmloyee, UserRole: e })
  }
  const DateOfBirth = (e) => {
    const selectedDate = e.target.value;
    const today = new Date();
    const birthDate = new Date(selectedDate);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      (age < 18) ||
      (age === 18 && (monthDiff > 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())))
    ) {
      setAddEmloyee({
        ...addEmloyee,
        DateOfBirth: '',
      });
    } else {
      setAddEmloyee({
        ...addEmloyee,
        DateOfBirth: selectedDate,
      });
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        const exactBase64 = base64Image.split(",")[1];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        setAddEmloyee({
          ...addEmloyee,
          UploardImage: exactBase64,
          ImageExtension: fileExtension,
          ImageName: file
        });
      };
      reader.readAsDataURL(file);
    }
  };



  const Gender = [
    { value: "Male", label: "Male", id: 0 },
    { value: "Female", label: "Female", id: 1 },
    // { value: "Transgender", label: "Transgender", id: 2 },
  ];
  const ComingEditDob = moment(comingData?.DateOfBirth).format("YYYY-MM-DD");

  const ComingEditDateOfJoining = moment(comingData?.DateOfJoining).format(
    "YYYY-MM-DD"
  );
  const ComingEditDateOfLeaving = moment(comingData?.DateOfLeaving).format(
    "YYYY-MM-DD"
  );
  const ComingGender = Gender?.filter((i) => i?.id === comingData?.Gender);

  const ComingDepartment = departmentData?.filter(
    (e) => e?.value === comingData?.Department
  );
  const ComingDesignation = DesignationData?.filter(
    (e) => e?.value === comingData?.Department
  );
  const SUBDesignation = DesignationData?.filter(
    (e) => e.Id === comingData?.DesignationName
  );

  useEffect(() => {
    if (comingData) {
      setAddEmloyee({
        Name: comingData?.FirstName,
        Email: comingData?.Email,
        Address: comingData?.Address,
        Phone: comingData?.Mobile,
        EmergencyContact: comingData?.Emergencycontactno,
        Gender: ComingGender,
        DateOfBirth: ComingEditDob,
        DateOfJoining: ComingEditDateOfJoining,
        DateOfLeaving: ComingEditDateOfLeaving,
        Department: ComingDepartment,
        Designation: ComingDesignation,
        // Department: ComingDepartment?.length > 0 ? ComingDepartment[0] : null,
        // Designation: comingData?.Designations,
        DesignationName: SUBDesignation,
      });
    }
  }, [comingData]);

  const [errorName, setErrorName] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorEmergencyContact, setErrorEmergencyContact] = useState(false);
  const [errorDateOfBirth, setErrorDateOfBirth] = useState(false);
  const [errorDateOfJoining, setErrorDateOfJoining] = useState(false);
  const [errorDateOfLeaving, setErrorDateOfLeaving] = useState(false);
  const [errorGender, setErrorGender] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [designationDepartmentError, setDesignationDepartmentError] = useState(false);
  const [teamLeadDepartMentError, setTeamLeadDepartMentError] = useState(false);
  const [uploadImageError, setUploadImageError] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  function isValidEmail(Email) {
    return emailRegex.test(Email);
  }

  const validate = () => {
    if (!addEmloyee?.Name) {
      setErrorName("usernamedata");
      return false;
    }
    if (!addEmloyee?.Email) {
      setErrorEmail("userEmail");
      return false;
    }

    if (!isValidEmail(addEmloyee?.Email)) {
      toast.error("Please enter valid email");
      return false;
    }
    if (!addEmloyee?.Address) {
      setErrorAddress("userAddress");
      return false;
    }

    if (!addEmloyee?.Phone) {
      setErrorPhone("userPhone");
      return false;
    }
    if (addEmloyee?.Phone?.length < 10) {
      toast.error("Please enter correct number");
      return false;
    }

    if (!addEmloyee?.EmergencyContact) {
      setErrorEmergencyContact("userEmergencyContact");
      return false;
    }
    if (addEmloyee?.EmergencyContact?.length < 10) {
      toast.error("Please enter correct number");
      return false;
    }
    if (!addEmloyee?.Password) {
      setErrorPassword('password');
      return false
    }

    if (!addEmloyee?.Gender) {
      setErrorGender("userGender");
      return false;
    }
    if (!finalUserRole) {
      setUserRoleError("userRole");
      return false;
    }
    if (!addEmloyee?.DateOfBirth) {
      setErrorDateOfBirth("userDateOfBirth");
      return false;
    }
    if (!addEmloyee?.DateOfJoining) {
      setErrorDateOfJoining("userDateOfJoining");
      return false;
    }

    // if (!addEmloyee?.DateOfLeaving) {
    //   setErrorDateOfLeaving("userDateOfLeaving");
    //   return false;
    // }

    if (!departmentId) {
      setDepartmentError("department");
      return false;
    }
    if (!designationId) {
      setDesignationDepartmentError("userDepartmentDesignation");
      return false;
    }
    if (teamLeadData?.length && !isFinalTeamLeadData) {
      setTeamLeadDepartMentError("userDepartmentTeamLead");
      return false
    }
    if (!addEmloyee?.UploardImage) {
      setUploadImageError("useruploadImage");
      return false;
    }
    return true;
  };

  //  SaveEmployee Api
  const handleAddEmployee = (e) => {
    if (!validate()) return;
    e?.preventDefault();
    setLoading(true);
    const LoginToken = GetToken();

    const data = {};
    data.FirstName = addEmloyee?.Name;
    data.Designation = designationId;
    data.Address = addEmloyee?.Address;
    data.Email = addEmloyee?.Email;
    data.Mobile = addEmloyee?.Phone;
    data.Emergencycontactno = addEmloyee?.EmergencyContact;
    data.DateOfBirth = addEmloyee?.DateOfBirth;
    data.DateOfJoining = addEmloyee?.DateOfJoining;
    data.DateofLeaving = addEmloyee?.DateOfLeaving;
    data.Department = departmentId;
    data.Photo = addEmloyee?.UploardImage;
    data.Gender = addEmloyee?.Gender?.id;
    data.Position = addEmloyee?.UserRole?.Id;
    data.TeamLead = addEmloyee?.TeamLead?.userId;
    data.Password = addEmloyee?.Password;
    data.PhotoExtenssion = addEmloyee?.ImageExtension;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/SaveEmployee`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          setAddEmloyee({
            Name: "",
            Address: "",
            Email: "",
            Phone: "",
            EmergencyContact: "",
            Gender: "",
            DateOfBirth: "",
            DateOfJoining: "",
            DateOfLeaving: "",
            Department: "",
            Designation: "",
            TeamLead: "",
            UserRole: "",
            UploardImage: "",
            Password: "",
            ImageExtension: ""
          })
        }
      })

      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  // Get Departments API
  const getDepartment = () => {
    setLoading(true);
    const token = GetToken();
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${Apiurl()}/Admin/GetDepartments`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        const finalData = data?.map((item) => ({
          label: item?.Name,
          value: item?.Id,
        }));
        setDepartmentData(finalData);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDepartment();
  }, []);

  // Post GetDesignations api
  const handleDesignations = (e) => {
    // if (!validate()) return;
    e?.preventDefault();
    setLoading(true);
    const LoginToken = GetToken();

    const data = {};
    data.DepartmentId = departmentId;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/GetDesignations`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setDesignationData(data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (departmentId) {
      setSaveDesignationOfDepartment("")
      setDesignationId("")
      setTeamLeadData("")
      setAddEmloyee({ ...addEmloyee, Designation: "" ,TeamLead: "" })
      setIsFinalTeamLeadData("")
      handleDesignations();
    }
  }, [departmentId]);
  // team leads retrieve
  const GetTeamLeads = () => {
    setLoading(true);
    const LoginToken = GetToken();
    const data = {};
    data.Department = saveDesignationOfDepartment?.DepartmentId;
    data.Designation = saveDesignationOfDepartment?.Id;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/GetTeamleads`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setTeamLeadData(data);
      })
      .catch((err) => {
        setLoading(false);
      })
  }

  useEffect(() => {
    if (saveDesignationOfDepartment) {
      setAddEmloyee({ ...addEmloyee, TeamLead: "" });
      setIsFinalTeamLeadData("")
      GetTeamLeads();
    }
  }, [saveDesignationOfDepartment])

  // user role retrieve
  const GetUserRole = () => {
    setLoading(true);
    const LoginToken = GetToken();
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Admin/GetPositions`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setUserRoleData(data);
      })
      .catch((err) => {
        setLoading(false);
      })
  }

  useEffect(() => {
    GetUserRole();
  }, [])

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <section className="addEmloyees-section">
          <div className="addEmloyees-all-icons">
            <div>
              <p className="DASHBOARD-MAIN-HEADING">Add Employee </p>
            </div>
            <div className="dashboiard-inner-header">
              <BiHomeAlt className="home-icon-header-inn" />
              <MdKeyboardArrowRight />
              <p>Employees </p>
              <MdKeyboardArrowRight />
              <p>Add Employee </p>
            </div>
          </div>

          <div className="Addemployee-main-container">
            <div className="Addemployee-outter-container">
              <div className="Addemployee-inner-container">
                <div className="AddEmployee-heading">
                  <p>Add Employee</p>
                </div>
                <div className="Addemployee-inner-box row">
                  <div className="Addemployee-inputs col-md-6">
                    <FloatingLabelInput
                      label="Name*"
                      value={addEmloyee?.Name}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          Name: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorName("usernamedata");
                        } else {
                          setErrorName("false");
                        }
                      }}
                    />
                    {errorName === "usernamedata" && !addEmloyee?.Name ? (
                      <span className="contact_error-Addemployee">
                        Full Name is required.
                      </span>
                    ) : null}
                  </div>


                  <div className="Addemployee-inputs col-md-6">
                    <FloatingLabelInput
                      label="Email*"
                      value={addEmloyee?.Email}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          Email: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorEmail("userEmail");
                        } else {
                          setErrorEmail("false");
                        }
                      }}
                    />
                    {errorEmail === "userEmail" && !addEmloyee?.Email ? (
                      <span className="contact_error-Addemployee">
                        Email is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="Addemployee-inputs col-md-12">
                    <FloatingLabelInput
                      label="Address*"
                      value={addEmloyee?.Address}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          Address: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorAddress("userAddress");
                        } else {
                          setErrorAddress("false");
                        }
                      }}
                    />
                    {errorAddress === "userAddress" && !addEmloyee?.Address ? (
                      <span className="contact_error-Addemployee">
                        Address is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="Addemployee-inputs col-md-4">
                    <FloatingLabelInput
                      type="number"
                      label="Phone No*"
                      maxLength="10"
                      value={addEmloyee?.Phone}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          Phone: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorPhone("userPhone");
                        } else {
                          setErrorPhone("false");
                        }
                      }}
                    />
                    {errorPhone === "userPhone" && !addEmloyee?.Phone ? (
                      <span className="contact_error-Addemployee">
                        Phone Number is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="Addemployee-inputs col-md-4">
                    <FloatingLabelInput
                      type="number"
                      maxLength="5"
                      label="Emergency Contact No.*"
                      value={addEmloyee?.EmergencyContact}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          EmergencyContact: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorEmergencyContact("userEmergencyContact");
                        } else {
                          setErrorEmergencyContact("false");
                        }
                      }}
                    />
                    {errorEmergencyContact === "userEmergencyContact" &&
                      !addEmloyee?.EmergencyContact ? (
                      <span className="contact_error-Addemployee">
                        Emergency Contact Number is required.
                      </span>
                    ) : null}
                  </div>

                  <div className="Addemployee-inputs col-md-4">
                    <FloatingLabelInput
                      type="password"
                      label="Password*"
                      value={addEmloyee?.Password}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          Password: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorPassword("password");
                        } else {
                          setErrorPassword("false");
                        }
                      }}
                    />
                    {errorPassword === "password" &&
                      !addEmloyee?.Password ? (
                      <span className="contact_error-Addemployee">
                        Password is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="Addemployee-inputs-gender col-md-6">
                    <label htmlFor="" className="label-manaegement-section">Gender</label>
                    <Select
                      //   className="Department-dropdown"
                      classNamePrefix="genderSelect"
                      placeholder="Please Select Gender"
                      options={Gender}
                      value={addEmloyee?.Gender}
                      onChange={(selectedOption) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          Gender: selectedOption,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorGender("userGender");
                        } else {
                          setErrorGender("false");
                        }
                      }}
                    />
                    {errorGender === "userGender" && !addEmloyee?.Gender ? (
                      <span className="contact_error-Addemployee">
                        Gender is required.
                      </span>
                    ) : null}
                  </div>

                  <div className="Addemployee-inputs-gender col-md-6">
                    <label htmlFor="" className="label-manaegement-section">User Role</label>
                    <Select
                      classNamePrefix="prefix-department"
                      placeholder="Please Select User Role"
                      options={userRoleData}
                      getOptionLabel={(options) => options?.RoleName}
                      getOptionValue={(options) => options?.Id}
                      onChange={handleUserRole}
                      value={addEmloyee?.UserRole}
                    />
                    {userRoleError === "userRole" && !finalUserRole ? (
                      <span className="contact_error-Addemployee">
                        User Role is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="Addemployee-inputs-cal col-md-4   ">
                    <label htmlFor="">Date of Birth </label>
                    <input
                      type="date"
                      name="DateOfBirth"
                      id="DateOfBirth"
                      value={addEmloyee?.DateOfBirth}
                      onChange={DateOfBirth}
                      max={currentDate}
                    />
                    {errorDateOfBirth === "userDateOfBirth" &&
                      !addEmloyee?.DateOfBirth ? (
                      <span className="contact_error-dob">
                        Date Of Birth is required.
                      </span>
                    ) : null}
                  </div>
                  {/* <div className="Addemployee-inputs-cal col-md-4   ">
                    <label htmlFor="">Date of Birth </label>
                    <input
                      type="date"
                      name="DateOfBirth"
                      id="DateOfBirth"
                      value={addEmloyee?.DateOfBirth}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          DateOfBirth: e?.target?.value,
                        })
                      }
                      max={currentDate}
                    />

                    {errorDateOfBirth === "userDateOfBirth" &&
                      !addEmloyee?.DateOfBirth ? (
                      <span className="contact_error-dob">
                        Date Of Birth is required.
                      </span>
                    ) : null}
                  </div> */}

                  <div className="Addemployee-inputs-cal col-md-4">
                    <label htmlFor="">Date of Joining</label>
                    <input
                      type="date"
                      name=""
                      id=""
                      value={addEmloyee?.DateOfJoining}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          DateOfJoining: e?.target?.value,
                        })
                      }
                      max={currentDate}
                    />

                    {errorDateOfJoining === "userDateOfJoining" &&
                      !addEmloyee?.DateOfJoining ? (
                      <span className="contact_error-dob">
                        Date Of Joining is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="Addemployee-inputs-cal col-md-4">
                    <label htmlFor="">Date of Leaving</label>
                    <input
                      type="date"
                      value={addEmloyee?.DateOfLeaving}
                      onChange={(e) =>
                        setAddEmloyee({
                          ...addEmloyee,
                          DateOfLeaving: e?.target?.value,
                        })
                      }
                      max={currentDate}
                    />

                    {/* {errorDateOfLeaving === "userDateOfLeaving" &&
                      !addEmloyee?.DateOfLeaving ? (
                      <span className="contact_error-dob">
                        Date Of Leaving is required.
                      </span>
                    ) : null} */}
                  </div>

                  <div className="Addemployee-inputs-department col-md-4">
                    <label htmlFor="" className="label-manaegement-section">Department</label>
                    <Select
                      classNamePrefix="prefix-department"
                      placeholder="Please Select Department"
                      options={departmentData}
                      getOptionLabel={(options) => options?.label}
                      getOptionValue={(options) => options?.value}
                      value={addEmloyee?.Department}
                      onChange={handleDepartmentChange}
                    />
                    {departmentError === "department" && !departmentId ? (
                      <span className="validation-error-addemployee">
                        * Please Select department.
                      </span>
                    ) : null}
                  </div>


                  {DesignationData && DesignationData?.length ? (
                    <div className="Addemployee-inputs-department-optional col-md-4">
                      <label htmlFor="" className="label-manaegement-section">Sub Department</label>
                      <Select
                        classNamePrefix="subdepartment-dropdown"
                        placeholder="Please Select Sub Department"
                        options={DesignationData}
                        getOptionLabel={(options) => options?.DesignationName}
                        getOptionValue={(options) => options?.Id}
                        onChange={handleDesignationData}
                        value={addEmloyee?.Designation}

                      />

                      {designationDepartmentError ===
                        "userDepartmentDesignation" && !designationId ? (
                        <span className="contact_error-Addemployee">
                          * Please Select Sub Department.
                        </span>
                      ) : null}
                    </div>
                  ) : null}

                  {
                    teamLeadData?.length ?
                      <div className="Addemployee-inputs-department-optional col-md-4">
                        <label htmlFor="" className="label-manaegement-section">Team Lead</label>
                        <Select
                          classNamePrefix="subdepartment-dropdown"
                          placeholder="Please Select Team Lead"
                          options={teamLeadData}
                          getOptionLabel={(options) => options?.FirstName}
                          getOptionValue={(options) => options?.Id}
                          onChange={handleTeamLeadData}
                          value={addEmloyee?.TeamLead}

                        />

                        {teamLeadDepartMentError ===
                          "userDepartmentTeamLead" && !isFinalTeamLeadData ? (
                          <span className="contact_error-Addemployee">
                            * Please Select Team Lead.
                          </span>
                        ) : null}
                      </div> : null
                  }

                  <div>
                    <label htmlFor="" className="upload-image-add-employee">
                      Image
                    </label>
                    <div className="Addemployee-input-file col-md12">
                      <input
                        className="Addemployee-file-input"
                        id="fileInput"
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        // value={addEmloyee?.ImageName?.name}
                      />

                      {/* {errorDepartment && !departmentId ? (
                        <span className="contact_error-Addemployee">
                          * Please fill the required fields.
                        </span>
                      ) : null} */}
                    </div>
                    {uploadImageError === "useruploadImage" &&
                      !addEmloyee?.UploardImage ? (
                      <span className="contact_error-dob">
                        Image is required
                      </span>
                    ) : null}
                  </div>
                  <div className="Addemployee-buttons">
                    <button
                      className="Addemployee-submit-button"
                      onClick={() => handleAddEmployee()}
                    >
                      Submit
                    </button>
                    <button className="Addemployee-Cancel-button">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default AddEmployee;
