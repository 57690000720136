import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import "../../EmployeeScreens/EmployeeProfile/EMPProfile.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Apiurl, GetToken, RemoveUserSession } from "../../../Utils/utils";
import axios from "axios";
import moment from "moment";
import Loader from "../../../Components/loader/loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EMPProfile = () => {
  const navigate = useNavigate();
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorEmergencyContactNumber, setErrorEmergencyContactNumber] =
    useState(false);
    const [errorMobile , setErrorMobile]=useState();
  const [errorPhoto, setErrorPhoto] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);

  const [newImageUrl, setNewImageUrl] = useState("");
  const [photoExtension, setPhotoExtension] = useState("");
  const fileInputRef = useRef(null);
  const [showSidebar, setShowSidebar] = useState(true);
  const [ProfileData, setProfileData] = useState("");
  const [loading, setLoading] = useState(false);
  const LoginToken = GetToken();
  const [profileAlldata, setProfileAllData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Mobile: "",
    Gender: "",
    DateOfBirth: "",
    Department: "",
    Designation: "",
    Address: "",
    UrlLink: "",
    Emergencycontactno: "",
  });

  const validate = () => {
    if (!profileAlldata?.FirstName) {
      setErrorFirstName("userFirstName");
      return false;
    }
    if (!profileAlldata?.LastName) {
      setErrorLastName("userLastName");
      return false;
    }
    if (!profileAlldata?.Mobile) {
      setErrorMobile("userMobile");
      return false;
    }
    if (!profileAlldata?.Emergencycontactno) {
      setErrorEmergencyContactNumber("userEmergencyContactNumber");
      return false;
    }

    if (!profileAlldata?.Address) {
      setErrorAddress("userAddress");
      return false;
    }
    return true;
  };

  const Gender = [
    { value: "Male", label: "Male", id: 0 },
    { value: "Female", label: "Female", id: 1 },
    { value: "Transgender", label: "Transgender", id: 2 },
  ];
  useEffect(() => {
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      Mobile: "",
      Gender: "",
      DateOfBirth: "",
      Department: "",
    }));
  }, []);
  const GetProfile = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Profile/GetProfile`,
    };
    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.data?.data);
        setProfileData(data);
        const gender = Gender?.find((e) => e?.id === data?.Gender);
        setProfileAllData({
          ...profileAlldata,
          FirstName: data?.FirstName,
          LastName: data?.LastName,
          Email: data?.Email,
          Mobile: data?.Mobile?.slice(0, 10),
          DateOfBirth: data?.DateOfBirth,
          Gender: gender,
          Department: data?.Departments?.Name,
          Designation: data?.Designations?.DesignationName,
          Address: data?.Address,
          UrlLink: data?.UrlLink,
          Emergencycontactno: data?.Emergencycontactno,
        });
        setLoading(false);
      })
      .catch((error) => {
        if (
          error?.message === "Network Error" &&
          error?.code === "ERR_NETWORK"
        ) {
          toast.error("Your Session has been expired");
          RemoveUserSession();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    GetProfile();
  }, []);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        setProfileAllData({
          ...profileAlldata,
          UrlLink: base64Image,
        });
        setNewImageUrl(base64Image);
      };
      reader.readAsDataURL(file);
      // Extract file extension
      const photoExtension = file.type.split("/")[1];
      setPhotoExtension(photoExtension);
    }
  };
  const handleUpdateProfile = () => {
    if (!validate()) return;

    setLoading(true);
    const data = {};
    data.Id = ProfileData?.Id;
    data.FirstName = profileAlldata?.FirstName;
    data.LastName = profileAlldata?.LastName;
    data.Gender = profileAlldata?.Gender?.id;
    data.Mobile = profileAlldata?.Mobile;
    data.Address = profileAlldata?.Address;
    data.DateOfBirth = profileAlldata?.DateOfBirth;
    data.Photo = profileAlldata?.UrlLink?.split("base64,")?.[1]
      ? profileAlldata?.UrlLink?.split("base64,")?.[1]
      : "";
    data.UrlLink = ProfileData?.UrlLink;
    data.PhotoExtenssion = photoExtension ? photoExtension : null;
    data.Emergencycontactno = profileAlldata?.Emergencycontactno;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Profile/UpdateProfile`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          toast.success(e?.data?.message);
          window.location.reload();

        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <section
          className={
            showSidebar
              ? "EMP-profile-main-section"
              : "EMP-profile-main-section-new"
          }
        >
          <div className="EMP-profile-all-icons">
            <div>
              <p className="MAIN-HEADING">Profile </p>
            </div>
            <div className="dashboiard-inner-header">
              <BiHomeAlt className="EMP-home-icon-header-inn" />
              <MdKeyboardArrowRight />
              <p> Profile </p>
              <MdKeyboardArrowRight />
              <p> Profile </p>
            </div>
          </div>
          <div className="EMP-profile-main-container">
            <div className="EMP-profile-outter-container">
              <div class="EMP-profile-inner-container">
                <div className="EMP-profile-heading">
                  <p> Profile</p>
                </div>
                <div className="EMP-profile-inner-box row">
                  <div className="profile-inputs col-6">
                    <FloatingLabelInput
                      label="FirstName*"
                      value={profileAlldata.FirstName}
                      onChange={(e) =>
                        setProfileAllData({
                          ...profileAlldata,
                          FirstName: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorFirstName("userFirstName");
                        } else {
                          setErrorFirstName("false");
                        }
                      }}
                    />
                    {errorFirstName === "userFirstName" &&
                    !profileAlldata?.FirstName ? (
                      <span className="contact_error-dob">
                        First name is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="profile-inputs col-6">
                    <FloatingLabelInput
                      label="LastName*"
                      value={profileAlldata?.LastName}
                      onChange={(e) =>
                        setProfileAllData({
                          ...profileAlldata,
                          LastName: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorLastName("userLastName");
                        } else {
                          setErrorLastName("false");
                        }
                      }}
                    />
                    {errorLastName === "userLastName" &&
                    !profileAlldata?.LastName ? (
                      <span className="contact_error-dob">
                        Last name is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="profile-inputs-disabled col-6">
                    <FloatingLabelInput
                      label="Email*"
                      value={profileAlldata?.Email?.trim()}
                      onChange={(e) =>
                        setProfileAllData({
                          ...profileAlldata,
                          Email: e?.target?.value,
                        })
                      }
                      style={{ marginBottom: 0 }}
                      disabled
                    />
                  </div>
                  <div className="profile-inputs-disabled col-6">
                    <FloatingLabelInput
                      label="Gender*"
                      value={profileAlldata.Gender?.label}
                      onChange={(e) =>
                        setProfileAllData({
                          ...profileAlldata,
                          Gender: e?.target?.value,
                        })
                      }
                      disabled
                    />
                  </div>
                  <div className="profile-inputs col-6">
                    <FloatingLabelInput
                      label="Mobile*"
                      maxLength="10"
                      value={profileAlldata.Mobile}
                      onChange={(e) => {
                        const inputMobile = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 10);
                        setProfileAllData({
                          ...profileAlldata,
                          Mobile: inputMobile,
                        });
                      }}
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorMobile(
                            "userMobile"
                          );
                        } else {
                          setErrorMobile("false");
                        }
                      }}
                    />
                    {errorMobile ===
                      "userMobile" &&
                    !profileAlldata?.Mobile ? (
                      <span className="contact_error-dob">
                        Emergency contact no is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="profile-inputs col-6">
                    <FloatingLabelInput
                      label="Emergency Contact Number *"
                      maxLength="10"
                      value={profileAlldata?.Emergencycontactno}
                      onChange={(e) => {
                        const inputMobile = e?.target.value
                          .replace(/\D/g, "")
                          .slice(0, 10);
                        setProfileAllData({
                          ...profileAlldata,
                          Emergencycontactno: inputMobile,
                        });
                      }}
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorEmergencyContactNumber(
                            "userEmergencyContactNumber"
                          );
                        } else {
                          setErrorEmergencyContactNumber("false");
                        }
                      }}
                    />
                    {errorEmergencyContactNumber ===
                      "userEmergencyContactNumber" &&
                    !profileAlldata?.Emergencycontactno ? (
                      <span className="contact_error-dob">
                        Emergency contact no is required.
                      </span>
                    ) : null}
                  </div>
              

                  <div className="profile-inputs col-6">
                    <label htmlFor="">Date Of Birth</label>
                    <input
                      type="date"
                      label="DateOfBirth*"
                      value={moment(profileAlldata?.DateOfBirth).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) => {
                        const formattedDate = moment(e?.target?.value).format(
                          "DD MMMM YYYY"
                        );
                        setProfileAllData({
                          ...profileAlldata,
                          DateOfBirth: formattedDate,
                        });
                      }}
                      // disabled
                    />
                  </div>
                  <div className="profile-inputs-for-image col-6">
                    <label htmlFor="profileImage">Profile Image</label>
                    <div className="profile-inputs-for-image-inner-div">
                      <input
                        type="file"
                        id="profileImage"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                      />
                      <label htmlFor="profileImage" className="image-label">
                        {newImageUrl ? (
                          <img
                            className="image-of-profile"
                            src={newImageUrl}
                            alt="Profile Image"
                          />
                        ) : (
                          <img
                            className="image-of-profile"
                            src={profileAlldata?.UrlLink}
                            alt="Profile Image"
                          />
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="profile-inputs-disabled col-4 ">
                    <FloatingLabelInput
                      label="Department*"
                      value={profileAlldata.Department}
                      onChange={(e) =>
                        setProfileAllData({
                          ...profileAlldata,
                          Department: e?.target?.value,
                        })
                      }
                      disabled
                    />
                  </div>
                  <div className="profile-inputs-disabled col-4">
                    <FloatingLabelInput
                      label="Designation*"
                      value={profileAlldata.Designation}
                      onChange={(e) =>
                        setProfileAllData({
                          ...profileAlldata,
                          Designation: e?.target?.value,
                        })
                      }
                      disabled
                    />
                  </div>
                  <div className="profile-inputs-disabled col-4">
                    <FloatingLabelInput
                      label="Team Lead*"  
                      value={profileAlldata.Designation}
                      onChange={(e) =>
                        setProfileAllData({
                          ...profileAlldata,
                          Designation: e?.target?.value,
                        })
                      }
                      disabled
                    />
                  </div>
                  <div className="profile-text-area col-md12">
                    <label htmlFor="">Address</label>
                    <textarea
                      id=""
                      cols="30"
                      rows="8"
                      value={profileAlldata.Address}
                      onChange={(e) =>
                        setProfileAllData({
                          ...profileAlldata,
                          Address: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorAddress("userAddress");
                        } else {
                          setErrorAddress("false");
                        }
                      }}
                    />
                    {errorAddress === "userAddress" &&
                    !profileAlldata?.Address ? (
                      <span className="contact_error-dob">
                        Address is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="Addemployee-buttons">
                    <button
                      className="Profile-submit-button"
                      onClick={() => handleUpdateProfile()}
                    >
                      {" "}
                      {profileAlldata ? "Update" : "submit"}{" "}
                    </button>
                    <button className="Profile-Cancel-button"> Cancel </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EMPProfile;
