import React, { useEffect, useState } from "react";
import "../Header/Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Headerlogo from "../../../Assets/Images/logoEliteNew.png";
import { LuBell } from "react-icons/lu";
import { RxHamburgerMenu } from "react-icons/rx";
import Admin from "../../../Assets/Images/profileimg.png";
import { VscAccount } from "react-icons/vsc";
import { MdOutlineEmail } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { HiOutlineLogout } from "react-icons/hi";
import { Apiurl, GetToken } from "../../../Utils/utils";
import axios from "axios";
import { CgProfile } from "react-icons/cg";
const Header = ({ showSidebar, setShowSidebar }) => {
  const [profileData, setProfileData] = useState([]);
  const LoginToken = GetToken();
  const [showDropdownHeader, setshowDropdownHeader] = useState(false);
  const toggleDropdown = () => {
    setshowDropdownHeader(!showDropdownHeader);
  };
  const handleClick = () => {
    setShowSidebar(!showSidebar);
  };
  const GetProfile = () => {
    // setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${LoginToken}`,
      },
      url: `${Apiurl()}/Profile/GetProfile`,
    };
    axios(option)
      .then((e) => {
        // setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setProfileData(data);
      })
      .catch(() => {
        // setLoading(false);
      });
  };
  useEffect(() => {
    GetProfile();
  }, []);
  return (
    <>
      {showSidebar ? (
        <>
          <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container fluid className="header-main-fluid-container">
              <Navbar.Brand className="header-logo-div" href="#home">
                <img src={Headerlogo} alt="" />
              </Navbar.Brand>
              <div className="RxHamburgerMenu-icon">
                    <RxHamburgerMenu onClick={handleClick} />
                  </div>
              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse className="headerCollapse" id="responsive-navbar-nav"> */}
                <Nav className="me-auto">
                
                </Nav>
                <Nav>
                  <div className="header-right-side-content">
                    <div></div>
                    <div className="header-bell-icon">
                      <LuBell />
                    </div>
                    <div
                      className="header-profile-dropdown"
                      onClick={toggleDropdown}
                    >
                      <p>{profileData?.FirstName}</p>
                      {profileData?.UrlLink ? (
                 profileData?.UrlLink && <img className="image-of-header" src={profileData.UrlLink} alt="Profile Image" />
                      ) : (
                        <CgProfile className="profile-icon-header" />
                      )}
                    </div>
                  </div>
                  <div className={showDropdownHeader ? "d-block" : "d-none"}>
                    <NavDropdown
                      title=""
                      id="collapsible-nav-dropdown"
                      className="collapsible-drop-down"
                      drop="start"
                      show={showDropdownHeader}
                    >
                      <NavDropdown.Item
                        href="/Emp/Profile"
                        className="navbar-dropdown"
                      >
                        {" "}
                        <span>
                          <VscAccount className="navbar-icons" />{" "}
                        </span>{" "}
                        <span className="text-on-dropdown-header">Profile</span>{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="/ForgotPassword"
                        className="navbar-dropdown"
                      >
                        {" "}
                        <span>
                          <MdOutlineEmail className="navbar-icons" />{" "}
                        </span>
                        <span className="text-on-dropdown-header">
                          Update Password
                        </span>{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/" className="navbar-dropdown">
                        {" "}
                        <span>
                          <HiOutlineLogout className="navbar-icons" />
                        </span>{" "}
                        <span className="text-on-dropdown-header">Logout</span>{" "}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </Nav>
              {/* </Navbar.Collapse> */}
            </Container>
          </Navbar>
        </>
      ) : (
        <>
          {" "}
          <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container fluid className="header-main-fluid-container">
              <Navbar.Brand className="header-logo-div" href="#home">
                <img src={Headerlogo} alt="" />
              </Navbar.Brand>
                  <div className="RxHamburgerMenu-icon">
                    <RxHamburgerMenu onClick={handleClick} />
                  </div>
              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
              {/* <Navbar.Collapse className="remove-toggel-button" id="responsive-navbar-nav"> */}
                <Nav className="me-auto">
                </Nav>
                <Nav>
                  <div className="header-right-side-content">
                    <div></div>
                    <div className="header-bell-icon">
                      <LuBell />
                    </div>
                    <div
                      className="header-profile-dropdown"
                      onClick={toggleDropdown}
                    >
                      <p>{profileData?.FirstName}</p>
                      {profileData?.UrlLink ? (
                 profileData?.UrlLink && <img className="image-of-header" src={profileData.UrlLink} alt="Profile Image" />
                      ) : (
                        <CgProfile className="profile-icon-header" />
                      )}
                    </div>
                  </div>
                  <div className={showDropdownHeader ? "d-block" : "d-none"}>
                    <NavDropdown
                      title=""
                      id="collapsible-nav-dropdown"
                      className="collapsible-drop-down"
                      drop="start"
                      show={showDropdownHeader}
                    >
                      <NavDropdown.Item
                        href="/Emp/Profile"
                        className="navbar-dropdown"
                      >
                        {" "}
                        <span>
                          <VscAccount className="navbar-icons" />{" "}
                        </span>{" "}
                        <span className="text-on-dropdown-header">Profile</span>{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="/ForgotPassword"
                        className="navbar-dropdown"
                      >
                        {" "}
                        <span>
                          <MdOutlineEmail className="navbar-icons" />{" "}
                        </span>
                        <span className="text-on-dropdown-header">
                          Update Password
                        </span>{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/" className="navbar-dropdown">
                        {" "}
                        <span>
                          <HiOutlineLogout className="navbar-icons" />
                        </span>{" "}
                        <span className="text-on-dropdown-header">Logout</span>{" "}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </Nav>
              {/* </Navbar.Collapse> */}
            </Container>
          </Navbar>
        </>
      )}
    </>
  );
};
export default Header;