import React, { useState } from "react";
import "../AddAnnouncement/AddAnnouncement.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import { Apiurl, GetToken } from "../../../Utils/utils";
import axios from "axios";
import { toast } from "react-toastify";
const AddAnnouncement = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [errorusername, setErrorusername] = useState(false);
  const [errorAnnouncementTitle, setErrorAnnouncementTitle] = useState(false);
  const [AnnouncementTitle, setAnnouncementTitle] = useState("");
  const [AnnouncementDate, setAnnouncementDate] = useState("");
  const [AnnouncementDescription, setAnnouncementDescription] = useState("");
  const [AnnouncementTitleError, setAnnouncementTitleError] = useState(false);
  const [AnnouncementDateError, setAnnouncementDateError] = useState(false);
  const [AnnouncementDescriptionError, setAnnouncementDescriptionError] =
    useState(false);
  const AnnouncementToken = GetToken();
  const validate = () => {
    if (!AnnouncementTitle) {
      setErrorAnnouncementTitle("userAnnouncementTitle");
      return false;
    }
    if (!AnnouncementDate) {
      setAnnouncementDateError("AnnouncementDateError");
      return false;
    }
    if (!AnnouncementDescription) {
      setAnnouncementDescriptionError("AnnouncementDescription");
      return false;
    }
    return true;
  };
  
  const SaveAnnouncement = () => {
    if (!validate()) return
    const data = {};
    data.AnnouncementTitle = AnnouncementTitle;
    data.Description = AnnouncementDescription;
    data.FlashTillDate = AnnouncementDate;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${AnnouncementToken}`,
      },
      url: `${Apiurl()}/Admin/SaveAnnouncement`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        if (e?.status === 200) {
          toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        <>
          {" "}
          {showSidebar ? (
            <>
              <div className="main-container-of-all-employees-page-annoucement">
                <div className="inner-container-of-all-employees-page ">
                  <div className="header-of-employees-page row">
                    <div className="all-employee-text-annoucement col">
                      <h4>Add Announcement</h4>
                    </div>
                    <div className="all-employees-directory-annoucement col">
                      <div className="home-icon-header-annoucement">
                        {" "}
                        <BiHomeAlt className="home-icon-header-annoucement" />{" "}
                      </div>
                      <div className="arrow-icon-header-annoucement">
                        <MdKeyboardArrowRight />
                      </div>
                      <div className="employee-text-header-annoucement">
                        <p>Dashboard</p>
                      </div>
                      <div className="arrow-icon-header-profile">
                        <MdKeyboardArrowRight />
                      </div>
                      <div className="all-employee-text-header-annoucement">
                        <p>Add Announcement</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div eventKey="profile" title="Settings">
                  <div className="main-container-for-security-annoucement">
                    <div>
                      <div className="username-password-annoucement">
                        <div className="row">
                          <div className="username-content-annoucement col">
                            <FloatingLabelInput
                              className="abcd"
                              label="Announcement Title"
                              type="text"
                              value={AnnouncementTitle}
                              onChange={(e) => setAnnouncementTitle(e.target.value)}
                              onBlur={(e) => {
                                if (!e?.target?.value) {
                                  setErrorAnnouncementTitle("userAnnouncementTitle");
                                } else {
                                  setErrorAnnouncementTitle(false);
                                }
                              }}
                            />
                            {errorAnnouncementTitle === "userAnnouncementTitle" && !AnnouncementTitle ? (
                              <span className="AnnouncementTitle-error">
                                Announcement Title is required.
                              </span>
                            ) : null}
                          </div>
                          <div className="flash-floating-annoucement col">
                            <label htmlFor=""> Flash Till Date </label>
                            <input
                              type="date"
                              name=""
                              id=""
                              value={AnnouncementDate}
                              onChange={(e) => setAnnouncementDate(e.target.value)}
                              onBlur={(e) => {
                                if (!e?.target?.value) {
                                  setAnnouncementDateError("userAnnouncementDate");
                                } else {
                                  setAnnouncementDateError(false);
                                }
                              }}
                            />
                            {AnnouncementDateError === "userAnnouncementDate" && !AnnouncementDate ? (
                              <span className="AnnouncementTitle-error">
                                  Flash Till Date is required.
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="password-floating-annoucement">
                          <div className="password-floating-annoucement">
                            <textarea
                              name=""
                              id=""
                              cols="30"
                              rows="8"
                              placeholder="Description"
                              value={AnnouncementDescription}
                              onChange={(e) => setAnnouncementDescription(e.target.value)}
                              onBlur={(e) => {
                                if (!e?.target?.value) {
                                  setAnnouncementDescriptionError("userAnnouncementDescription");
                                } else {
                                  setAnnouncementDescriptionError(false);
                                }
                              }}
                            ></textarea>
                            {AnnouncementDescriptionError === "userAnnouncementDescription" && !AnnouncementDescription ? (
                              <span className="AnnouncementTitle-er">
                                Description is required.
                              </span>
                            ) : null}
                          </div>
                          <div className="save-button-in-setting-annoucement">
                            <button
                              className="save-button-in-setting-annoucement-button-one"
                              onClick={SaveAnnouncement}
                            >
                              Submit
                            </button>
                            <button className="save-button-in-setting-annoucement-button-two">
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="main-container-of-all-employees-page-annoucement-new">
              <div className="inner-container-of-all-employees-page ">
                  <div className="header-of-employees-page row">
                    <div className="all-employee-text-annoucement col">
                      <h4>Add Announcement</h4>
                    </div>
                    <div className="all-employees-directory-annoucement col">
                      <div className="home-icon-header-annoucement">
                        {" "}
                        <BiHomeAlt className="home-icon-header-annoucement" />{" "}
                      </div>
                      <div className="arrow-icon-header-annoucement">
                        <MdKeyboardArrowRight />
                      </div>
                      <div className="employee-text-header-annoucement">
                        <p>Dashboard</p>
                      </div>
                      <div className="arrow-icon-header-profile">
                        <MdKeyboardArrowRight />
                      </div>
                      <div className="all-employee-text-header-annoucement">
                        <p>Add Announcement</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div eventKey="profile" title="Settings">
                  <div className="main-container-for-security-annoucement">
                    <div>
                      <div className="username-password-annoucement">
                        <div className="row">
                          <div className="username-content-annoucement col">
                            <FloatingLabelInput
                              className="abcd"
                              label="Announcement Title"
                              type="text"
                              value={AnnouncementTitle}
                              onChange={(e) => setAnnouncementTitle(e.target.value)}
                              onBlur={(e) => {
                                if (!e?.target?.value) {
                                  setErrorAnnouncementTitle("userAnnouncementTitle");
                                } else {
                                  setErrorAnnouncementTitle(false);
                                }
                              }}
                            />
                            {errorAnnouncementTitle === "userAnnouncementTitle" && !AnnouncementTitle ? (
                              <span className="AnnouncementTitle-error">
                                Announcement Title is required.
                              </span>
                            ) : null}
                          </div>
                          <div className="flash-floating-annoucement col">
                            <label htmlFor=""> Flash Till Date </label>
                            <input
                              type="date"
                              name=""
                              id=""
                              value={AnnouncementDate}
                              onChange={(e) => setAnnouncementDate(e.target.value)}
                              onBlur={(e) => {
                                if (!e?.target?.value) {
                                  setAnnouncementDateError("userAnnouncementDate");
                                } else {
                                  setAnnouncementDateError(false);
                                }
                              }}
                            />
                            {AnnouncementDateError === "userAnnouncementDate" && !AnnouncementDate ? (
                              <span className="AnnouncementTitle-error">
                                  Flash Till Date is required.
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="password-floating-annoucement">
                          <div className="password-floating-annoucement">
                            <textarea
                              name=""
                              id=""
                              cols="30"
                              rows="8"
                              placeholder="Description"
                              value={AnnouncementDescription}
                              onChange={(e) => setAnnouncementDescription(e.target.value)}
                              onBlur={(e) => {
                                if (!e?.target?.value) {
                                  setAnnouncementDescriptionError("userAnnouncementDescription");
                                } else {
                                  setAnnouncementDescriptionError(false);
                                }
                              }}
                            ></textarea>
                            {AnnouncementDescriptionError === "userAnnouncementDescription" && !AnnouncementDescription ? (
                              <span className="AnnouncementTitle-er">
                                Description is required.
                              </span>
                            ) : null}
                          </div>
                          <div className="save-button-in-setting-annoucement">
                            <button
                              className="save-button-in-setting-annoucement-button-one"
                              onClick={SaveAnnouncement}
                            >
                              Submit
                            </button>
                            <button className="save-button-in-setting-annoucement-button-two">
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};
export default AddAnnouncement;
