import React, { useEffect, useState } from 'react'
import { Apiurl, forgotemail } from '../../../Utils/utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../Components/loader/loader';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

const VerifyPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [urlEmail, setUrlEmail] = useState('');
  const [urlToken, setUrlToken] = useState('');
  const url = window?.location?.href;

  useEffect(() => {
    if (url) {
      const searchData = queryString.parse(window.location.search);
      const email = searchData.E;
      const token = searchData.T?.replace?.(/ /g, '+');
      setUrlEmail(email);
      setUrlToken(token);
    }
  }, [url]);

  const verifyPassword = () => {
    setLoading(true);

    const data = {};
    data.email = urlEmail;
    data.token = urlToken;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/Account/VerifyToken`,
      data: { data: JSON.stringify(data) },
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          forgotemail(e?.data?.data?.email);
          navigate('/ConfirmPassword')
          // toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.success === false) {
          toast.error("Session Expired");
          navigate('/ForgotPassword')
        }
        setLoading(false);

      });
  }

  useEffect(() => {
    if (urlEmail && urlToken) {
      verifyPassword();
    }
  }, [urlEmail, urlToken])
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div>
        Loading....
      </div>
    </>
  )
}

export default VerifyPassword