import React, { useState } from "react";
import "../SignupPage/SignUp.css";
import kuberimgsignup from "../../../Assets/Images/kubersignupimage.png";
import { FaGoogle } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import FloatingLabelInput from "../../../Components/floatingLabel/FloatingLabel";
import { Apiurl, Token } from "../../../Utils/utils";
import Loader from "../../../Components/loader/loader";

const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newPasswordEye, setNewPasswordEye] = useState(false);
  const [changePassword, setChangePassword] = useState({
    newPassword: '',
    conPassword: '',
  });
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [userName, setUserName] = useState('');
  const [email, setemail] = useState('');
  const [errorUserName, setErrorUserName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmpass, setErrorConfirmpass] = useState(false);

  const handleClickNewPassword = () => {
    setNewPasswordEye(!newPasswordEye);
  };

  const handleClickConfirmPassword = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const handleLoginNavigate = () => {
    navigate("/");
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  function isValidEmail(email) {
    return emailRegex.test(email);
  }
  const validate = () => {
    if (!userName) {
      setErrorUserName("usernamedata");
      // setError(true);
      return false;
    }

    if (!email) {
      setErrorEmail("useremaildata");
      // setError(true);
      return false;
    }

    if (!isValidEmail(email)) {
      toast.error("Please enter valid email");
      return false;
    }

    if (!changePassword?.newPassword) {
      setErrorPassword("passworddata");
      return false;
    }
    if (changePassword?.newPassword?.length < 8) {
      toast.error("Please Enter Password More Than 8 Characters");
      return false;
    }

    if (
      !changePassword?.newPassword?.match(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      toast.error(
        "The password must include lowercase letters, uppercase letters, numbers and special characters @$!%*#.?&^"
      );
      return false;
    }
    if (!changePassword?.conPassword) {
      setErrorConfirmpass("confirmpassdata");
      return false;
    }
    if (changePassword?.newPassword != changePassword?.conPassword) {
      toast.error("Password Don't Match");
      return false;
    }
    return true;
  };

  const handleRegisterSubmit = (e) => {
    if (!validate()) return;
    e?.preventDefault();
setLoading(true);
    const data = {
      UserName: userName,
      Email: email,
      Password: changePassword?.newPassword,
    };

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Account/RegisterUser`,
    };
    axios(option)
      .then((e) => {
        if (e?.data?.success) {  
          toast.success(e?.data?.message);
          Token(e?.data?.data);
          setUserName('');
          setemail('');
          setChangePassword({ newPassword: '', conPassword: '' });
          setLoading(false)
        }
      })
      .catch((err) => {
        console.error("Login Error:", err);
        toast.error(
          err?.response?.data?.message || "An error occurred during login."
        );
      });
  };
  return (
    <>
        {loading ? <Loader /> : null}
      <div className="full-page-main-signup">
        <div className="main-container-of-signup-page row-12">
          <div className="sign-page-image col-6">
            <img src={kuberimgsignup} alt="" />
          </div>
          <div className="signup-page-form col-6">
            <div className="inner-container-of-signup-form">
              <h2>Sign Up </h2>
              <div className="need-a-accoount-signup-new">
                <p>Enter details to create your account</p>
              </div>

              <div>
                <div className="email-and-password-inputs-signup">
                  <div className="username-content-signup">
                    <FloatingLabelInput
                      label="Username*"
                      type="text"
                      onChange={(e) => setUserName(e?.target?.value)}
                      value={userName}
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorUserName("usernamedata");
                        } else {
                          setErrorUserName("false");
                        }
                      }}
                    />
                    {errorUserName === "usernamedata" && !userName ? (
                      <span className="contact_error-signup">
                        Username is required.
                      </span>
                    ) : null}
                  </div>
                  <div className="email-content-signup">
                    <FloatingLabelInput
                      label="Email*"
                      type="text"
                      onChange={(e) => setemail(e?.target?.value)}
                      value={email}
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorEmail("useremaildata");
                        } else {
                          setErrorEmail("false");
                        }
                      }}
                    />
                    {errorEmail === "useremaildata" && !email ? (
                      <span className="contact_error-signup">
                        Please enter a valid email address.
                      </span>
                    ) : null}
                  </div>
                  <div className="password-floating-signup">
                    <FloatingLabelInput
                      label="Password*"
                      type={newPasswordEye ? "text" : "password"}
                      className="login-password-input"
                      onChange={(e) =>
                        setChangePassword({
                          ...changePassword,
                          newPassword: e?.target?.value,
                        })
                      }
                      value={changePassword?.newPassword}
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorPassword("passworddata");
                        } else {
                          setErrorPassword("false");
                        }
                      }}
                    />
                    {errorPassword === "passworddata" &&
                    !changePassword?.newPassword ? (
                      <span className="contact_error-signup">
                        Password is required.
                      </span>
                    ) : null}
                    {newPasswordEye ? (
                      <AiOutlineEye
                        className="eye-password-signup"
                        onClick={() => handleClickNewPassword()}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="eye-password-signup"
                        onClick={() => handleClickNewPassword()}
                      />
                    )}
                  </div>

                  <div className="password-floating-signup">
                    <FloatingLabelInput
                      label="Confirm Password*"
                      type={confirmPasswordEye ? "text" : "password"}
                      className="login-password-input"
                      value={changePassword?.conPassword}
                      onChange={(e) =>
                        setChangePassword({
                          ...changePassword,
                          conPassword: e?.target?.value,
                        })
                      }
                      onBlur={(e) => {
                        if (!e?.target?.value) {
                          setErrorConfirmpass("confirmpassdata");
                        } else {
                          setErrorConfirmpass("false");
                        }
                      }}
                    />
                    {errorConfirmpass === "confirmpassdata" &&
                    !changePassword?.conPassword ? (
                      <span className="contact_error-signup">
                        Confirm Password is required.
                      </span>
                    ) : null}
                    {confirmPasswordEye ? (
                      <AiOutlineEye
                        className="eye-password-signup"
                        onClick={() => handleClickConfirmPassword()}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="eye-password-signup"
                        onClick={() => handleClickConfirmPassword()}
                      />
                    )}
                  </div>
                </div>
                <div className="remember-me-and-forgot-password-signup">
                  <div className="remember-me-input-label-signup">
                    <label htmlFor="">Already Registered?</label>
                  </div>

                  <div className="forgot-password-signup">
                    <a href="" onClick={handleLoginNavigate}>
                      Login
                    </a>
                  </div>
                </div>
                <div className="login-button-signup">
                  <button
                    className="register-button-signup"
                    onClick={(e) => handleRegisterSubmit()}
                    style={{
                      background:
                        userName &&
                        email &&
                        changePassword?.newPassword &&
                        changePassword?.conPassword
                          ? "#3f51b5"
                          : "rgba(0, 0, 0, .12)",
                      color:
                        userName &&
                        email &&
                        changePassword?.newPassword &&
                        changePassword?.conPassword
                          ? "#fff"
                          : "rgba(0, 0, 0, .38)",
                    }}
                  >
                    Register
                  </button>
                </div>
                <div className="or-with-lines-signup">
                  <div className="blank-div-one-signup"></div>
                  <div className="or-align-center-signup"> OR </div>
                  <div className="blank-div-two-signup"></div>
                </div>

                <div className="four-button-footer-part-signup">
                  <button>
                    <FaGoogle />
                  </button>{" "}
                  <button>
                    <FaFacebookF />
                  </button>{" "}
                  <button>
                    <FaTwitter />
                  </button>{" "}
                  <button>
                    <FaLinkedinIn />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;