import React, { useEffect, useState } from "react";
import "../../../Pages/AdminScreens/TodaysAttendance/TodaysAttendance.css";
import { BiHomeAlt } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { GrRefresh } from "react-icons/gr";
import excelimg from "../../../Assets/Images/excelsheetimg.png";
import tableimg from "../../../Assets/Images/tableimage.jpg";
import Header from "../../../Components/Dashboard/Header/Header";
import Sidebar from "../../../Components/Dashboard/Sidebar/Sidebar";
import { Apiurl, GetToken } from "../../../Utils/utils";
import axios from "axios";
import moment from "moment";
import Loader from '../../../Components/loader/loader';
const TodaysAttendance = () => {
  const [loading,setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [AttendanceData, setAttendanceData] = useState([]);
  const [AttendanceDataDuplicate, setAttendanceDataDuplicate] = useState([]);
  const GetTodaysAttendance = () => {
    setLoading(true);
    const token = GetToken();
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${Apiurl()}/Admin/GetToday'sAttendance`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.data?.data);
        setAttendanceData(data?.Data?.Attendance);
        setAttendanceDataDuplicate(data?.Data?.Attendance);
        // setDepartmentData(data);
      })
      .catch((err) => { 
        setLoading(false);
      });
  }
  useEffect(() => {
    GetTodaysAttendance();
  }, [])
  const HandleSearch = (e) => {
    const searchTerm = e?.target?.value?.toLowerCase();
    if (searchTerm) {
      const filteredData = AttendanceData.filter(item =>
        item?.User?.UserName?.toLowerCase()?.includes(searchTerm)
      );
      setAttendanceData(filteredData)
    }
    else {
      setAttendanceData(AttendanceDataDuplicate)
    }
  };
  return (
    <>  
    {
      loading ? <Loader /> : null
    }
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="d-flex">
        <Sidebar showSidebar={showSidebar} />
        {showSidebar ? (
          <>
            <div className="main-container-of-all-employees-Attendance">
              <div className="inner-container-of-all-employees-page ">
                <div className="header-of-employees-page row">
                  <div className="all-employee-Attendance col">
                    <h4>Today Attendance</h4>
                  </div>
                  <div className="all-employees-Attendance col">
                    <div className="home-icon-Attendance">
                      {" "}
                      <BiHomeAlt className="home-icon-header-Attendance" />{" "}
                    </div>
                    <div className="arrow-icon-Attendance">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-Attendance">
                      <p>Attendance</p>
                    </div>
                    <div className="arrow-icon-Attendance">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-Attendance">
                      <p>Today's Attendance</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="body-part-outer-Attendance">
                <div className="inner-body-container-of-employee-Attendance row-12">
                  <div className=" first-side-in-body-Attendance col-6">
                    <div className="employee-text-in-Attendance">
                      <h2>Attendance</h2>
                    </div>
                    <div className="input-for-search-in-employee-Attendance">
                      <MdSearch className="search-icon-in-Attendance" />
                      <input type="text" placeholder="Search" onChange={(e) => HandleSearch(e)} />
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-Attendance">
                  <table className="table-main-Attendance">
                    <thead className="thead-main-Attendance">
                      <tr className="row-12">
                        <th className="image-class col-2 ">Image</th>
                        <th className="image-class col-2 ">Name</th>
                        <th className="image-class col-2 ">In Time</th>
                        <th className="image-class col-2 ">Out Time</th>
                        <th className="image-class col-2 ">Work Duration</th>
                        <th className="image-class col-2 ">Status</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-Attendance">
                      {
                        AttendanceData?.sort((a, b) => {
                          const nameA = a.User?.UserName.toUpperCase();
                          const nameB = b.User?.UserName.toUpperCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        })?.map((item, index) => {
                          const formattedInTime = moment(item?.InTime, 'HH:mm:ss').format('hh:mm A');
                          const formattedOutTime = moment(item?.OutTime, 'HH:mm:ss').format('hh:mm A');
                          const workDuration = moment(item?.WorkDuration, 'HH:mm:ss').format('hh:mm A');
                          return (
                            <tr key={index}>
                              <td>
                                {" "}
                                <img
                                  src={tableimg}
                                  alt=""
                                  className="image-in-table-Attendance"
                                />
                              </td>
                              <td>{item?.User?.UserName}</td>
                              {/* <td>{formattedInTime !== null && formattedInTime !== undefined ? formattedInTime :'-'}</td> */}
                              <td>{formattedInTime && formattedInTime !== 'Invalid date' ? formattedInTime : '-'}</td>
                              <td>{formattedOutTime && formattedOutTime !== 'Invalid date' ? formattedOutTime : '-'}</td>
                              <td>{workDuration === '12:00 AM' ? '-' : workDuration}</td>
                              {
                                formattedInTime && formattedInTime !== 'Invalid date' ?
                                  <td>
                                    <span className="present-spna-in-todaysattendence-table">
                                      {" "}
                                      present
                                    </span>
                                  </td> :
                                  <td>
                                    <span className="absent-spna-in-todaysattendence-table">
                                      {" "}
                                      absent
                                    </span>
                                  </td>
                              }
                            </tr>
                          )
                        })
                      }
                      {/* <tr>
                        <td>
                          {" "}
                          <img
                            src={tableimg}
                            alt=""
                            className="image-in-table-td"
                          />
                        </td>
                        <td> Sarah Smith</td>
                        <td> Sarah Smith</td>
                        <td> 10:30</td>
                        <td> 19:30</td>
                        <td>
                          {" "}
                          <span className="absent-spna-in-todaysattendence-table">
                            {" "}
                            absent
                          </span>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="main-container-of-all-employees-page-new-Attendance">
            <div className="inner-container-of-all-employees-page ">
                <div className="header-of-employees-page row">
                  <div className="all-employee-Attendance col">
                    <h4>Today Attendance</h4>
                  </div>
                  <div className="all-employees-Attendance col">
                    <div className="home-icon-Attendance">
                      {" "}
                      <BiHomeAlt className="home-icon-header-Attendance" />{" "}
                    </div>
                    <div className="arrow-icon-Attendance">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="employee-text-Attendance">
                      <p>Attendance</p>
                    </div>
                    <div className="arrow-icon-Attendance">
                      <MdKeyboardArrowRight />
                    </div>
                    <div className="all-employee-text-Attendance">
                      <p>Today's Attendance</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="body-part-outer-Attendance">
                <div className="inner-body-container-of-employee-Attendance row-12">
                  <div className=" first-side-in-body-Attendance col-6">
                    <div className="employee-text-in-Attendance">
                      <h2>Attendance</h2>
                    </div>
                    <div className="input-for-search-in-employee-Attendance">
                      <MdSearch className="search-icon-in-Attendance" />
                      <input type="text" placeholder="Search" onChange={(e) => HandleSearch(e)} />
                    </div>
                  </div>
                </div>
                <div className="table-in-all-emplyee-Attendance">
                  <table className="table-main-Attendance">
                    <thead className="thead-main-Attendance">
                      <tr className="row-12">
                        <th className="image-class col-2 ">Image</th>
                        <th className="image-class col-2 ">Name</th>
                        <th className="image-class col-2 ">In Time</th>
                        <th className="image-class col-2 ">Out Time</th>
                        <th className="image-class col-2 ">Work Duration</th>
                        <th className="image-class col-2 ">Status</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-main-Attendance">
                      {
                        AttendanceData?.sort((a, b) => {
                          const nameA = a.User?.UserName.toUpperCase();
                          const nameB = b.User?.UserName.toUpperCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        })?.map((item, index) => {
                          const formattedInTime = moment(item?.InTime, 'HH:mm:ss').format('hh:mm A');
                          const formattedOutTime = moment(item?.OutTime, 'HH:mm:ss').format('hh:mm A');
                          const workDuration = moment(item?.WorkDuration, 'HH:mm:ss').format('hh:mm A');
                          return (
                            <tr key={index}>
                              <td>
                                {" "}
                                <img
                                  src={tableimg}
                                  alt=""
                                  className="image-in-table-Attendance"
                                />
                              </td>
                              <td>{item?.User?.UserName}</td>
                              {/* <td>{formattedInTime !== null && formattedInTime !== undefined ? formattedInTime :'-'}</td> */}
                              <td>{formattedInTime && formattedInTime !== 'Invalid date' ? formattedInTime : '-'}</td>
                              <td>{formattedOutTime && formattedOutTime !== 'Invalid date' ? formattedOutTime : '-'}</td>
                              <td>{workDuration === '12:00 AM' ? '-' : workDuration}</td>
                              {
                                formattedInTime && formattedInTime !== 'Invalid date' ?
                                  <td>
                                    <span className="present-spna-in-todaysattendence-table">
                                      {" "}
                                      present
                                    </span>
                                  </td> :
                                  <td>
                                    <span className="absent-spna-in-todaysattendence-table">
                                      {" "}
                                      absent
                                    </span>
                                  </td>
                              }
                            </tr>
                          )
                        })
                      }
                      {/* <tr>
                        <td>
                          {" "}
                          <img
                            src={tableimg}
                            alt=""
                            className="image-in-table-td"
                          />
                        </td>
                        <td> Sarah Smith</td>
                        <td> Sarah Smith</td>
                        <td> 10:30</td>
                        <td> 19:30</td>
                        <td>
                          {" "}
                          <span className="absent-spna-in-todaysattendence-table">
                            {" "}
                            absent
                          </span>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default TodaysAttendance;